/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 * !
 * Copyright 2021 National Association of Insurance Commissioners
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes,extraRequire}
 * tslint:disable
 */
var styles = ["a[aria-expanded=false][_ngcontent-%COMP%]   .fa-caret-up[_ngcontent-%COMP%], a[aria-expanded=true][_ngcontent-%COMP%]   .fa-caret-down[_ngcontent-%COMP%]{display:none}a.disabled[_ngcontent-%COMP%]{cursor:not-allowed}.borderless[_ngcontent-%COMP%]   th[_ngcontent-%COMP%]{border:none}.icon-green[_ngcontent-%COMP%]{color:green}.company-detail[_ngcontent-%COMP%]   div[_ngcontent-%COMP%], table[_ngcontent-%COMP%]{font-size:1rem}.card-header-clickable[_ngcontent-%COMP%]{cursor:pointer}.card-header-clickable[_ngcontent-%COMP%]:hover{background-color:#d3e6f7}.company-profile[_ngcontent-%COMP%] > .card[_ngcontent-%COMP%]{border:0}.company-profile[_ngcontent-%COMP%] > .card[_ngcontent-%COMP%] > .card-header[_ngcontent-%COMP%]{background:none}.company-profile[_ngcontent-%COMP%] > .card[_ngcontent-%COMP%]   .company-detail.card[_ngcontent-%COMP%]{border:0}.non-sellable-statement[_ngcontent-%COMP%]{background:#939597}.c-35[_ngcontent-%COMP%]{width:35%}.c-30[_ngcontent-%COMP%]{width:30%}.c-15[_ngcontent-%COMP%]{width:15%}.c-10[_ngcontent-%COMP%]{width:10%}"];
export { styles as styles };

