/**
 * NAIC InsData Order Service
 * NAIC InsData Order service
 *
 * OpenAPI spec version: 1.0.0
 * Contact: InsDataStaff@naic.org
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { StatementType } from './statementType';


/**
 * CoCode list item for user.
 */
export interface CoCodeListItem { 
    cocode: number;
    companyName: string;
    locked: boolean;
    status: CoCodeListItem.StatusEnum;
    statementTypes?: Array<StatementType>;
    downloadDate?: Date;
}
export namespace CoCodeListItem {
    export type StatusEnum = 'pending' | 'available' | 'nofiling' | 'locked';
    export const StatusEnum = {
        Pending: 'pending' as StatusEnum,
        Available: 'available' as StatusEnum,
        Nofiling: 'nofiling' as StatusEnum,
        Locked: 'locked' as StatusEnum
    };
}
