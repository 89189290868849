/*!
 * Copyright 2021 National Association of Insurance Commissioners
 */


import {of as observableOf, Observable} from 'rxjs';

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {mergeMap} from 'rxjs/operators';
import {StatementTypes} from '../model/company/statementTypes';
import {InsdataCompanySearchService} from './insdata-company-search.service';
import {InsdataCustomerService} from './insdata-customer.service';

@Injectable()
export class InsdataStatementTypeResolver implements Resolve<StatementTypes> {

  constructor( private readonly companyService: InsdataCompanySearchService,
              private readonly customerService: InsdataCustomerService ) {
  }

  resolve( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Observable<StatementTypes> | Promise<StatementTypes> | StatementTypes {

    return this.customerService.isCurrentUserStandard().pipe( mergeMap( ( result ) => {
      if ( result ) {
        return observableOf( undefined );
      }
      return this.companyService.fetchStatementTypes();
    } ) );
  }

}
