/*!
 * Copyright 2021 National Association of Insurance Commissioners
 */

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {of} from 'rxjs/internal/observable/of';
import {UserDetail} from '../model/customer/userDetail';
import {InsdataCustomerService} from './insdata-customer.service';
import {InsdataMessageService} from './insdata-message.service';

@Injectable()
export class InsdataCocodeYearResolver implements Resolve<number[]> {

  constructor(
    private readonly insdataCustomerService: InsdataCustomerService,
    private readonly insdataMessageService: InsdataMessageService
  ) {
  }

  resolve( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Observable<number[]> | number[] {
    const orderYears: Array<number> = [];
    const fullDate = new Date();
    const month = fullDate.getMonth();
    const year = fullDate.getFullYear();
    let currOrderYear;
    if ( month >= 2 ) {
      currOrderYear = year;
    } else {
      currOrderYear = year - 1;
    }
    this.insdataCustomerService.getCurrentUserDetail().subscribe( ( userDetail: UserDetail ) => {
      if ( userDetail && userDetail.contracts ) {
        for ( const contract of userDetail.contracts ) {
          if ( contract.filingYear <= currOrderYear ) {
            orderYears.push( contract.filingYear );
          }
        }
        orderYears.sort( function ( a, b ) {
          return a - b;
        } );
      }
      return orderYears;
    }, () => {
      this.insdataMessageService.showErrorMessage( 'Unable to resolve \'cocode limit years.\'' );
    } );

    return of( orderYears );
  }

}
