/*!
 * Copyright 2021 National Association of Insurance Commissioners
 */

import {HttpClient} from '@angular/common/http';
import {
  ChangeDetectorRef, Component, ComponentFactoryResolver, ComponentRef, OnDestroy, OnInit,
  ViewChild
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {Location} from '@angular/common';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {CompaniesStatementsOrderBy} from '../../../model/company/companiesStatementsOrderBy';
import * as models from '../../../model/company/models';
import {InsdataCompanySearchService} from '../../../service/insdata-company-search.service';
import { InsdataCustomerService } from '../../../service/insdata-customer.service';
import {CompanySearchDetailComponent} from '../company-search-detail/company-search-detail.component';
import {CompanySearchResultHostDirective} from '../company-search-result-host.directive';
import {CompanySearchResultsComponent} from '../company-search-results/company-search-results.component';

@Component( {
  selector: 'app-company-search',
  templateUrl: './company-search.component.html',
  styleUrls: ['./company-search.component.scss'],
} )
export class CompanySearchComponent implements OnInit, OnDestroy {
  @ViewChild( CompanySearchResultHostDirective, {static: true} ) resultHost: CompanySearchResultHostDirective;
  companySearchCriteria: models.CompaniesStatementsSearchCriteria;

  companiesStatementsResults: models.CompaniesStatementsSearchResults;
  companySearchResultsComponentRef: ComponentRef<CompanySearchResultsComponent>;
  companySearchDetailComponentRef: ComponentRef<CompanySearchDetailComponent>;

  loading = false;
  hasSearched: boolean;

  allStatementTypes: models.StatementTypes;
  allStates: models.States;
  companyHomeUrl = '/home/companySearch';
  isUserStandard: Observable<boolean>;

  defaultStatementType: models.StatementType = {
    value: '',
  } as models.StatementType;

  private readonly ngUnsubscribe: Subject<any> = new Subject();
  private readonly defaultCompanySearchCriteria: models.CompaniesStatementsSearchCriteria = {
    textSearchFlag: models.TextSearchFlag.Contains,
    pageSearchCriteria: {
      maxItems: 10,
      startItem: 0,
    } as models.PageSearchCriteria,
    orderBy: CompaniesStatementsOrderBy.CompanyNameAsc,
  };

  constructor(
    private readonly http: HttpClient,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly companySearchService: InsdataCompanySearchService,
    private readonly customerService: InsdataCustomerService,
    private readonly location: Location,
    private readonly changeDetector: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    // When Back Button is pressed and Company Search Detail is displayed, close Company Details
    this.resetCompanySearch();
    this.setDefaultSearch();
    this.isUserStandard = this.customerService.isCurrentUserStandard();
    this.location.subscribe( ( popEvent ) => {
      if ( popEvent.url.indexOf( '/home/companySearch' ) >= 0 && this.companySearchDetailComponentRef ) {
        this.companySearchDetailComponentRef.destroy();
        this.companySearchDetailComponentRef = null;
        this.showCompanySearchResults();
      }
    } );
    if ( sessionStorage.getItem( 'companies' ) != null ) {
      this.companiesStatementsResults = JSON.parse( sessionStorage.getItem( 'companies' ) );
      this.showCompanySearchResults();
    }
    if ( sessionStorage.getItem( 'companySearchCriteria' ) != null ) {
      this.companySearchCriteria = JSON.parse( sessionStorage.getItem( 'companySearchCriteria' ) );
    }

    this.activatedRoute.data.subscribe( ( data: { statementTypes: models.StatementTypes, states: models.States } ) => {
      if ( data ) {
        this.allStatementTypes = data.statementTypes;
        this.allStates = data.states;
      }
    } );

    const element = document.querySelector( '#GBL_Common_Header' );
    element.scrollIntoView();
  }

  searchCompanies() {

    this.destroyCompanyResultComponent();
    this.destroyCompanyDetailComponent();
    this.router.navigate( [this.companyHomeUrl] ).then(
      // Do nothing
    );
    this.companySearchCriteria.companyName = this.companySearchCriteria.companyName ? this.companySearchCriteria.companyName.trim() : null;
    this.companySearchCriteria.pageSearchCriteria.startItem = 0;
    this.searchCompany( this.companySearchCriteria );
  }

  resetForm(): void {
    this.resetCompanySearch();
    this.router.navigate( [this.companyHomeUrl] );
    this.hasSearched = false;
  }

  showCompanyDataDetail( company: models.CompanyData ) {
    // Add State in order to use Backbutton to close Details Section
    window.history.pushState( {}, 'companySearchDetail', this.router.url );
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory( CompanySearchDetailComponent );
    const viewContainerRef = this.resultHost.viewContainerRef;
    viewContainerRef.clear();
    this.companySearchDetailComponentRef = viewContainerRef.createComponent( componentFactory );
    ( this.companySearchDetailComponentRef.instance ).company = company;
    ( this.companySearchDetailComponentRef.instance ).close
      .pipe( takeUntil( this.ngUnsubscribe ) ).subscribe( () => {
      this.showCompanySearchResults();
      this.destroyCompanyDetailComponent();
      window.history.back();
    } );
     this.changeDetector.detectChanges();
  }

  showCompanySearchResults() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory( CompanySearchResultsComponent );
    const viewContainerRef = this.resultHost.viewContainerRef;
    viewContainerRef.clear();
    this.companySearchResultsComponentRef = viewContainerRef.createComponent( componentFactory );
    ( this.companySearchResultsComponentRef.instance ).companiesStatementsSearchResults = this.companiesStatementsResults;
    ( this.companySearchResultsComponentRef.instance ).selectedCompany.pipe( takeUntil( this.ngUnsubscribe ) )
      .subscribe( ( selectedCompany: models.CompanyData ) => this.showCompanyDataDetail( selectedCompany ) );
    ( this.companySearchResultsComponentRef.instance ).newPage.pipe( takeUntil( this.ngUnsubscribe ) )
      .subscribe( ( newPage ) => {
        this.companySearchCriteria.pageSearchCriteria.startItem = ( newPage - 1 ) * this.companySearchCriteria.pageSearchCriteria.maxItems;
        this.searchCompany( this.companySearchCriteria );
      } );
    ( this.companySearchResultsComponentRef.instance ).newSort.pipe( takeUntil( this.ngUnsubscribe ) ).subscribe( ( newSort ) => {
      this.companySearchCriteria.orderBy = newSort;
      this.searchCompany( this.companySearchCriteria );
    } );
    this.changeDetector.detectChanges();
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.resetCompanySearch();
  }
  resetCompanySearch() {
    // After a form reset setting the value on the ngModel alone isn't enough, check radio option call here
    this.companySearchCriteria = {...this.defaultCompanySearchCriteria};
    this.companySearchCriteria.pageSearchCriteria.startItem = 0;
    this.companiesStatementsResults = null;
    sessionStorage.removeItem( 'companySearchCriteria' );
    sessionStorage.removeItem( 'companies' );
    this.resultHost.viewContainerRef.clear();
    this.loading = false;
    this.destroyCompanyResultComponent();
    this.destroyCompanyDetailComponent();
    this.setDefaultSearch();
  }

  setDefaultSearch() {
    this.companySearchCriteria.statementType = undefined;
    this.companySearchCriteria.stateOfDomicile = undefined;
    this.companySearchCriteria.licensedInState = undefined;
  }

  searchCompany( companySearchCriteria: models.CompaniesStatementsSearchCriteria ) {
    this.hasSearched = true;
    this.loading = true;
    this.resultHost.viewContainerRef.clear();
    this.companySearchService.searchNaicCompaniesStatements( companySearchCriteria ).subscribe( ( result: models.CompaniesStatementsSearchResults ) => {
      this.loading = false;
      this.companiesStatementsResults = result;
      sessionStorage.removeItem( 'companies' );
      sessionStorage.setItem( 'companies', JSON.stringify( this.companiesStatementsResults ) );
      sessionStorage.removeItem( 'companySearchCriteria' );
      sessionStorage.setItem( 'companySearchCriteria', JSON.stringify( this.companySearchCriteria ) );

      if ( this.companiesStatementsResults.companiesStatements.length === 1 ) {
        this.showCompanyDataDetail( this.companiesStatementsResults.companiesStatements[0].companyData );
      } else {
        this.showCompanySearchResults();
      }
    } );
  }

  showingResultsOrDetails(): boolean {
    const resultsComponent = this.companySearchResultsComponentRef ? this.companySearchResultsComponentRef.instance : null;
    const detailsComponent = this.companySearchDetailComponentRef ? this.companySearchDetailComponentRef.instance : null;

    if ( ( this.resultHost.viewContainerRef || [] ).length ) {
      if ( resultsComponent && ( resultsComponent.companyResults || [] ).length ) {
        return true;
      }
      if ( detailsComponent && ( detailsComponent.company ) ) {
        return true;
      }
    }

    return false;
  }

  onlyNumbersAllowed( event: any ): boolean {
    return ( event.charCode === 8 || event.charCode === 0 ) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  formIsValid(): boolean {
    // Here CompanyNameFieldMinLength is 3 and and companyCocodeFieldMinLength is 5. Add them as variables when used in at least one other place.
    return ( this.companySearchCriteria.companyName || '' ).length >= 3 || ( this.companySearchCriteria.companyCoCode || '' ).toString().length === 5;
  }

  formOnKeyDownFunction( event ) {
    if ( event.keyCode === 13 ) {
      this.searchCompanies();
    }
  }

  private destroyCompanyResultComponent() {
    if ( this.companySearchResultsComponentRef ) {
      this.companySearchResultsComponentRef.destroy();
      this.companySearchResultsComponentRef = null;
    }
  }

  private destroyCompanyDetailComponent() {
    if ( this.companySearchDetailComponentRef ) {
      this.companySearchDetailComponentRef.destroy();
      this.companySearchDetailComponentRef = null;
    }
  }


}
