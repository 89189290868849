/*!
 * Copyright 2021 National Association of Insurance Commissioners
 */


import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {throwError as observableThrowError, Observable} from 'rxjs';
import {of} from 'rxjs/internal/observable/of';
import {catchError} from 'rxjs/operators';

/**
 * Solution from https://github.com/angular/angular/issues/18680
 */

@Injectable()
export class EmptyResponseBodyErrorInterceptor implements HttpInterceptor {
  intercept( req: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {
    return next.handle( req )
      .pipe( catchError( ( err: HttpErrorResponse ) => {
        if ( err.status >= 200 && err.status < 300 ) {
          const res = new HttpResponse( {
            body: null,
            headers: err.headers,
            status: err.status,
            statusText: err.statusText,
            url: err.url,
          } );

          return of( res );
        } else {
          return observableThrowError( err );
        }
      } ) );
  }
}
