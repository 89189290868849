/*!
 * Copyright 2021 National Association of Insurance Commissioners
 */

import {Component, OnInit} from '@angular/core';
import {BannerInput} from '../../model/customer/bannerInput';
import {InsdataCustomerService} from '../../service/insdata-customer.service';

@Component( {
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
} )
export class BannerComponent implements OnInit {
  bannerInfo: BannerInput;

  constructor( private readonly customerService: InsdataCustomerService ) {
  }

  ngOnInit() {
    if ( !this.bannerInfo ) {
      this.customerService.bannerInfo$.subscribe( ( data ) => {
        this.bannerInfo = data;
      } );
    }
  }

}
