/*
 * Copyright 2024 National Association of Insurance Commissioners
 */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { timer, Observable, Subject } from 'rxjs';
import { retry, share, switchMap, take, takeUntil } from 'rxjs/operators';
import { ProductLinesService } from '../../../api/productLines.service';
import { PubCartService } from '../../../api/pubCart.service';
import { User } from '../../../model/customer/user';
import { ProductLine } from '../../../model/publications/productLine';
import { PubCart } from '../../../model/publications/pubCart';
import { PubCartItem } from '../../../model/publications/pubCartItem';
import { Publication } from '../../../model/publications/publication';
import { InsdataCustomerService } from '../../../service/insdata-customer.service';
import { InsdataLoadingSpinnerService } from '../../../service/insdata-loading-spinner.service';
import { InsdataMessageService } from '../../../service/insdata-message.service';
import { PubsService } from '../pubs.service';


@Component( {
  selector: 'app-publication-list',
  templateUrl: './publication-list.component.html',
  styleUrls: [ './publication-list.component.scss' ],
} )
export class PublicationListComponent implements OnInit, OnDestroy {
  user: User;
  productLineList: ProductLine[] = [];

  accordionOpenStatusMap: Map<string, boolean> = new Map<string, boolean>();
  publicationMoreDataCollapsed: Map<string, boolean> = new Map<string, boolean>();

  publicationFormControlMap: Map<string, FormControl> = new Map<string, FormControl>();

  private _fetchAllProductLines$: Observable<ProductLine[]>;
  get fetchAllProductLines$(): Observable<ProductLine[]> {
    return this._fetchAllProductLines$;
  }

  set fetchAllProductLines$( value: Observable<ProductLine[]> ) {
    this._fetchAllProductLines$ = value;
  }

  private stopPolling = new Subject();

  constructor(
    private readonly messageService: InsdataMessageService,
    private readonly insdataCustomerService: InsdataCustomerService,
    private readonly productLinesService: ProductLinesService,
    private readonly pubsService: PubsService,
    private readonly spinnerService: InsdataLoadingSpinnerService,
    private readonly pubCartService: PubCartService
  ) {
  }

  addToCart( publication: Publication ): void {
    this.spinnerService.showLoadingSpinner();
    const publicationCartIndex: number = this.pubsService.isPublicationInCart( publication );

    if ( publicationCartIndex === -1 ) {
      this.addPublicationToCart( publication );
    } else {
      this.updatePublicationCart( publicationCartIndex, publication );
    }
  }

  getAllPublications(): void {
    this.spinnerService.showLoadingSpinner();
    this.fetchAllProductLines$
      .subscribe(
        ( productLines: ProductLine[] ) => {
          this.productLineList = [];
          productLines.forEach( ( productLine: ProductLine ) => {
            if ( productLine.publications.length > 0 ) {
              this.productLineList.push( productLine );
              this.setPublicationFormControlMap( productLine );
            }
          } );
          this.spinnerService.hideLoadingSpinner();
        },
        () => {
          this.spinnerService.hideLoadingSpinner();
          this.messageService.showErrorMessage( 'Error getting product lines all product lines and publications', false, 5000 );
        }
      );
  }

  getCurrentUser(): void {
    this.insdataCustomerService
      .getCurrentUser()
      .pipe( take( 1 ) )
      .subscribe(
        ( data ) => {
          this.user = data;
        },
        () => {
          console.error( 'Something went wrong fetching user details using "getCurrentUser()" call.' );
        }
      );
  }

  getProductLineSkus( productLine: ProductLine ): string {
    const skuArray: string[] = [];
    productLine.publications.forEach( ( publication: Publication ) => {
      skuArray.push( publication.sku );
    } );
    return skuArray.length === 0 ? 'No SKUs' : skuArray.join( ', ' );
  }

  isAccordionOpen( productLine: ProductLine ): boolean {
    if ( this.accordionOpenStatusMap.has( productLine.productLineId ) ) {
      return this.accordionOpenStatusMap.get( productLine.productLineId );
    }
    return false;
  }

  isDisabled( publicationId: string ): boolean {
    const quantityControl: FormControl = this.publicationFormControlMap.get( publicationId );
    return quantityControl.invalid || quantityControl.value === '0' || quantityControl.value === 0;
  }

  isMoreDataCollapsed( publication: Publication ): boolean {
    if ( this.publicationMoreDataCollapsed.has( publication.publicationId ) ) {
      return this.publicationMoreDataCollapsed.get( publication.publicationId );
    } else {
      this.publicationMoreDataCollapsed.set( publication.publicationId, true );
    }
    return true;
  }

  ngOnDestroy(): void {
    this.stopPolling.next();
  }

  ngOnInit(): void {
    this.pubsService.activeComponent = 'publications';
    this.getCurrentUser();

    this.fetchAllProductLines$ = timer( 0, 60000 ).pipe(
      switchMap( () => this.productLinesService.fetchProductLinesWithActivePublications() ),
      retry( 3 ),
      share(),
      takeUntil( this.stopPolling )
    );

    this.getAllPublications();
  }

  recordMoreDataCollapseChange( publication: Publication ): void {
    this.publicationMoreDataCollapsed.set( publication.publicationId, !this.publicationMoreDataCollapsed.get( publication.publicationId ) );
  }

  recordOpenChange( event: boolean, productLine: ProductLine ): void {
    // Event will be true when open and false when closed
    this.accordionOpenStatusMap.set( productLine.productLineId, event );
  }

  setPublicationFormControlMap( productLine: ProductLine ): void {
    productLine.publications.forEach( ( publication: Publication ) => {
      if ( !this.publicationFormControlMap.has( publication.publicationId ) ) {
        this.publicationFormControlMap.set( publication.publicationId, new FormControl( 0, [ Validators.pattern( '^[0-9]*$' ), Validators.required ] ) );
      }
    } );
  }

  private addPublicationToCart( publication: Publication ): void {
    this.pubCartService.addPublicationToCart( this.user.userId, publication.publicationId, this.publicationFormControlMap.get( publication.publicationId ).value )
      .pipe( take( 1 ) )
      .subscribe( ( pubCart: PubCart ) => {
        this.spinnerService.hideLoadingSpinner();
        this.pubsService.pubCart$.next( pubCart );
        this.publicationFormControlMap.get( publication.publicationId ).setValue( 0 );
      }, () => {
        this.spinnerService.hideLoadingSpinner();
        this.messageService.showErrorMessage( 'A problem was encountered while trying to add the publication', false, 5000 );
      } );
  }

  private updatePublicationCart( pubCartItemIndex: number, publication: Publication ): void {
    const pubCartItemForUpdate: PubCartItem = this.pubsService.pubCart$.value.items[ pubCartItemIndex ];
    const quantityToAdd: number = +this.publicationFormControlMap.get( publication.publicationId ).value;
    this.pubCartService.updatePublicationCartItemInCart( this.user.userId, pubCartItemForUpdate.cartItemId, pubCartItemForUpdate.quantity + quantityToAdd )
      .pipe( take( 1 ) )
      .subscribe( ( pubCart: PubCart ): void => {
        this.spinnerService.hideLoadingSpinner();
        this.pubsService.pubCart$.next( pubCart );
        this.publicationFormControlMap.get( publication.publicationId ).setValue( 0 );
      }, () => {
        this.spinnerService.hideLoadingSpinner();
        this.messageService.showErrorMessage( 'A problem was encountered while trying to update the quantity of the cart item', false, 5000 );
      } );
  }

}
