/*
 * Copyright 2024 National Association of Insurance Commissioners
 */

import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { PubCartService } from '../../api/pubCart.service';
import { User } from '../../model/customer/user';
import { PubCart } from '../../model/publications/pubCart';
import { PubCartItem } from '../../model/publications/pubCartItem';
import { InsdataCustomerService } from '../../service/insdata-customer.service';
import { InsdataMessageService } from '../../service/insdata-message.service';
import { PubsService } from './pubs.service';

@Component( {
  selector: 'app-publications',
  templateUrl: './publications.component.html',
  styleUrls: [ './publications.component.scss' ],
} )
export class PublicationsComponent implements OnInit {
  get activeComponent(): string {
    return this.pubsService.activeComponent;
  }

  pubCartItemCount = 0;
  user: User;

  constructor(
    private readonly pubsService: PubsService,
    private readonly customerService: InsdataCustomerService,
    private readonly pubCartService: PubCartService,
    private readonly messageService: InsdataMessageService
  ) {
  }

  ngOnInit() {
    this.getCurrentUser();
    this.pubsService.pubCart$.subscribe( ( cart: PubCart ) => {
      this.pubCartItemCount = 0;
      if ( cart && cart.items ) {
        cart.items.forEach( ( item: PubCartItem ) => {
          this.pubCartItemCount = +this.pubCartItemCount + +item.quantity;
        } );
      }
    } );
    this.getCart();
  }

  getCurrentUser(): void {
    this.customerService
      .getCurrentUser()
      .pipe( take( 1 ) )
      .subscribe(
        ( data ) => {
          this.user = data;
        },
        () => {
          console.error( 'Something went wrong fetching user details using "getCurrentUser()" call.' );
        }
      );
  }

  getCart(): void {
    this.pubCartService.fetchPubCartForUser( this.user.userId )
      .pipe( take( 1 ) )
      .subscribe( ( pubCart: PubCart ) => {
        this.pubsService.pubCart$.next( pubCart );
      }, ( error: HttpErrorResponse ) => {
        if ( error.status === 404 ) {
          this.pubsService.handleCart404();
        } else {
          this.messageService.showErrorMessage( 'A problem was encountered while fetching your cart', false, 5000 );
        }
      } );
  }
}
