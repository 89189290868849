/*!
 * Copyright 2021 National Association of Insurance Commissioners
 */


import {of as observableOf, Observable} from 'rxjs';

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {mergeMap} from 'rxjs/operators';
import {States} from '../model/company/states';
import {InsdataCompanySearchService} from './insdata-company-search.service';
import {InsdataCustomerService} from './insdata-customer.service';


@Injectable()
export class InsdataStatesResolver implements Resolve<States> {
  constructor( private readonly companyService: InsdataCompanySearchService,
              private readonly customerService: InsdataCustomerService ) {
  }

  resolve( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Observable<States> | Promise<States> | States {
    return this.customerService.isCurrentUserStandard().pipe( mergeMap( ( result ) => {
      if ( result ) {
        return observableOf( undefined );
      }
      return this.companyService.fetchStates();
    } ) );
  }
}
