/**
 * NAIC InsData Company Service API
 * NAIC InsData Company Service API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: fstier2@naic.internal
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Download Link Criteria
 */
export interface DownloadLinkCriteria { 
    /**
     * User id to whom the order belongs to.
     */
    userId: string;
    /**
     * Order id to which the file belongs to.
     */
    orderId: string;
    /**
     * type of file to download either manifest or order file.
     */
    downloadFileType: DownloadLinkCriteria.DownloadFileTypeEnum;
}
export namespace DownloadLinkCriteria {
    export type DownloadFileTypeEnum = 'ORDER_FILE' | 'MANIFEST_FILE';
    export const DownloadFileTypeEnum = {
        ORDERFILE: 'ORDER_FILE' as DownloadFileTypeEnum,
        MANIFESTFILE: 'MANIFEST_FILE' as DownloadFileTypeEnum
    };
}
