<div class="row justify-content-center mb-3">
  <div class="col-xl-8 col-lg-10 col-md-10 col-sm-12">
    <h2 id="bulkOrderPageHeading">Create Bulk Order</h2>
    <div class="tabs mb-3 row">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a [class]="'nav-link ' + ((currentTab === tabs.orderType) ? 'active' : '')"
             (click)="setCurrentTab(tabs.orderType)">Order Type</a>
        </li>
        <li class="nav-item">
          <a [class]="'nav-link ' + ((currentTab === tabs.dateRange) ? 'active' : '')"
             (click)="setCurrentTab(tabs.dateRange)">Date Range</a>
        </li>
      </ul>
    </div>

    <p *ngIf="fetchingBulkOrders">Checking existing bulk orders... <span class="fa fa-spinner fa-spin fa-lg"></span></p>
    <div *ngIf="!fetchingBulkOrders && !pendingBulkOrderExists && !bulkOrderSubmitted">
      <div class="form-group mb-3">
        <h5 style="white-space: nowrap" class="mt-3">Select Order Year <span class="ttip"><span
          class="fa fa-question-circle" title="Order year starts on March 1st every year."
          style="color:#1e73be;"></span></span>
        </h5>
        <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4">
          <select id="orderYearForBulkOrdering" [class]="'form-control  mb-3'"
                  [(ngModel)]="selectedYearToCreateBulkOrder" name="yearSelectForCreatingBulkOrder"
                  (change)="updateMinAndMaxDates()">
            <option *ngFor="let year of availableYears" [value]="year">{{year}}</option>
          </select>
        </div>
      </div>

      <div *ngIf="currentTab === tabs.orderType">
        <div class="form-group mb-3">
          <div>
            <input class="mb-2" type="radio" id="allFiles" value="allFiles"
                   [(ngModel)]="selectedBulkOrderType"/><label
            class="role-label" for="allFiles">All Files</label>
          </div>
          <div>
            <input class="mb-2" type="radio" id="changesSinceLast" value="changesSinceLast"
                   [(ngModel)]="selectedBulkOrderType" [disabled]="isDisabled()" /><label class="role-label" for="changesSinceLast">Changes
            Only</label>
          </div>
        </div>
      </div>

      <div *ngIf="currentTab === tabs.dateRange">
        <div class="alert alert-secondary col-md-4 mb-3" role="alert">
          The PDF files which are a part of the order created using the date range method might also be present when the
          user uses ‘Changes Only’ order type.
        </div>
        <h5 style="white-space: nowrap" class="mt-3">Date Range</h5>

        <div class="text-danger row" *ngIf="this.fromDate && !this.isFromDateValid"><small>The Date must be within the
          proper date range</small>
        </div>
        <div class="form-row">
          <div class="input-group col-md-4 mb-lg-3">
            <input class="form-control date-input" [class.is-invalid]="this.fromDate && !this.isFromDateValid"
                   type="text" #dp1="bsDatepicker" bsDatepicker [bsConfig]="bsConfig"
                   triggers="undefined" id="from-date-input" placeholder="From Date" [bsValue]="fromDate"
                   [(ngModel)]="fromDate" (blur)="validateDate(); updateMinMax()" (ngModelChange)="validateDate(); updateMinMax()"
                   [readOnly]="!selectedYearToCreateBulkOrder" [minDate]="minFromDate" [maxDate]="maxFromDate">
            <div class="input-group-btn date-input">
              <button type="button" class="btn" (click)="dp1.toggle();"
                      id="from-date-input-button" [disabled]="!selectedYearToCreateBulkOrder"><span
                class=" fa fa-calendar"></span></button>
            </div>
          </div>
        </div>

        <div class="text-danger row" *ngIf="this.toDate && !this.isToDateValid"><small>The Date must be within the
          proper date range</small>
        </div>
        <div class="form-row">
          <div class="input-group col-md-4 mb-lg-3">
            <input class="form-control date-input" [class.is-invalid]="this.toDate && !this.isToDateValid" type="text"
                   #dp2="bsDatepicker" bsDatepicker [bsConfig]="bsConfig"
                   triggers="undefined" id="to-date-input" placeholder="To Date" [bsValue]="toDate" [(ngModel)]="toDate"
                   (blur)="validateDate()" [readOnly]="!selectedYearToCreateBulkOrder || fromDate == null || !this.isFromDateValid"
                   [minDate]="minToDate" [maxDate]="maxToDate" (ngModelChange)="validateDate()" >
            <div class="input-group-btn date-input">
              <button type="button" class="btn" (click)="dp2.toggle();"
                      id="to-date-input-button"
                      [disabled]="!selectedYearToCreateBulkOrder || fromDate == null || !this.isFromDateValid"><span
                class=" fa fa-calendar"></span></button>
            </div>
          </div>
        </div>

      </div>

      <button class="btn btn-primary mb-3" id="createBulkOrderBtn" (click)="createBulkOrder()"
              [disabled]="!isValidForCreateBulkOrder()">Create Bulk Order
      </button>
    </div>

    <div class="row" id="bulkOrderHistory"
         *ngIf="(!fetchingBulkOrders && pendingBulkOrderExists) || bulkOrderSubmitted">
      <div class="col">
        <p style="line-height: 2em" id="pendingOrderExistText">Your previous order is being processed. A new order can
          only be initiated after the previous order is completed.</p>
      </div>
    </div>

  </div>

  <div bsModal #bulkOrderProcessingModal="bs-modal" class="modal" id="bulkOrderProcessingModal" tabindex="-1"
       role="dialog"
       [config]="{backdrop: 'static', keyboard: false}">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title"><strong>Placing Order</strong></h5>
        </div>
        <div class="modal-body">
          <span class="fa fa-spinner fa-spin fa-2x padded"></span>
          Your bulk order is being placed. <br><br>
          <div class="alert alert-warning">
            Please do not refresh or leave the page until the order is completely processed.
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
