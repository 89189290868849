<div id="company-search-results" *ngIf="(companyResults || []).length">
  <p class="page-indicator float-right font-weight-bold" id="tst-company-search-page-indicator">{{calcStartNumber()}} - {{calcEndNumber()}} of {{totalResult}}</p>
  <h3>Results</h3>
  <table id="company-results-table" class="table table-striped table-hover" aria-describedby="company search results table">
    <thead>
      <tr>
        <th class="clickable" scope="col"
            (click)="sort(sortField !== companiesStatementsOrderBy.CompanyNameAsc ? companiesStatementsOrderBy.CompanyNameAsc : companiesStatementsOrderBy.CompanyNameDesc)">
          Company Name
          <span class="fa fa-caret-up clickable" *ngIf="sortField === companiesStatementsOrderBy.CompanyNameAsc"></span>
          <span class="fa fa-caret-down" *ngIf="sortField === companiesStatementsOrderBy.CompanyNameDesc"></span>
        </th>
        <th class="clickable" scope="col"
            (click)="sort(sortField !== companiesStatementsOrderBy.CompanyCoCodeAsc ? companiesStatementsOrderBy.CompanyCoCodeAsc : companiesStatementsOrderBy.CompanyCoCodeDesc)">
          CoCode
          <span class="fa fa-caret-up clickable"
                *ngIf="sortField === companiesStatementsOrderBy.CompanyCoCodeAsc"></span>
          <span class="fa fa-caret-down" *ngIf="sortField === companiesStatementsOrderBy.CompanyCoCodeDesc"></span>
        </th>
        <th scope="col">Statement Types</th>
        <th class="clickable" scope="col"
            (click)="sort(sortField !== companiesStatementsOrderBy.StateOfDomicileAsc ? companiesStatementsOrderBy.StateOfDomicileAsc : companiesStatementsOrderBy.StateOfDomicileDesc)">
          State of Domicile
          <span class="fa fa-caret-up clickable"
                *ngIf="sortField === companiesStatementsOrderBy.StateOfDomicileAsc"></span>
          <span class="fa fa-caret-down" *ngIf="sortField === companiesStatementsOrderBy.StateOfDomicileDesc"></span>
        </th>
        <th scope="col">Address</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let companyResult of getCurrentRows()" (click)="showCompanyDataDetail(companyResult.companyData)" class="clickable">
        <td>{{companyResult.companyData.companyName}}</td>
        <td>{{companyResult.companyData.companyCoCode}}</td>
        <td>
          <div *ngFor="let compStatementType of companyResult.companyData.statementTypes">
             {{compStatementType.value}} <br/>
          </div>
        </td>
        <td>{{companyResult.companyData.stateOfDomicile}}</td>
        <td>
          {{companyResult.companyData.streetAddress1}}<br/>
          {{companyResult.companyData.streetAddress2}}<br/>
          {{companyResult.companyData.city}}, {{companyResult.companyData.state}} {{companyResult.companyData.postalCode}}
        </td>
      </tr>
    </tbody>
  </table>
  <div class="text-center">
    <pagination *ngIf="resultsHavePagination()" class="pagination-wrapper" id="tst-cs-pagination"
      [totalPages]="calcTotalPages()"
      [currentPage]="currentPage"
      [pageLinks]="buildPageLinks()"
      (onPaginate)="navigateFromPagination($event)"></pagination>
  </div>
</div>
