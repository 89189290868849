/*
 * Copyright 2024 National Association of Insurance Commissioners
 */
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { take } from 'rxjs/operators';
import { PubCartService } from '../../../api/pubCart.service';
import { User } from '../../../model/customer/user';
import { PubCart } from '../../../model/publications/pubCart';
import { PubCartItem } from '../../../model/publications/pubCartItem';
import { InsdataCustomerService } from '../../../service/insdata-customer.service';
import { InsdataLoadingSpinnerService } from '../../../service/insdata-loading-spinner.service';
import { InsdataMessageService } from '../../../service/insdata-message.service';
import { PubsService } from '../pubs.service';

@Component( {
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: [ './cart.component.scss' ],
} )
export class CartComponent implements OnInit {
  get totalQuantity(): number {
    let totalQuantity = 0;
    this.cart.items.forEach( ( item: PubCartItem ) => {
      totalQuantity = totalQuantity + +item.quantity;
    } );
    return totalQuantity;
  }


  get totalPrice(): number {
    let totalPrice = 0;
    this.cart.items.forEach( ( item: PubCartItem ) => {
      if ( +item.quantity >= 0 ) {
        totalPrice = totalPrice + ( +item.quantity * item.publication.price );
      }
    } );
    return totalPrice;
  }

  @ViewChild( 'cartForm' ) cartForm: FormGroup;
  cart: PubCart;
  modalRef: BsModalRef;
  user: User;
  formEdited = false;

  constructor(
    private readonly pubsService: PubsService,
    private readonly customerService: InsdataCustomerService,
    private readonly pubCartService: PubCartService,
    private readonly modalService: BsModalService,
    private readonly messageService: InsdataMessageService,
    private readonly spinnerService: InsdataLoadingSpinnerService
  ) {
  }

  ngOnInit() {
    this.pubsService.activeComponent = 'pubCart';
    this.getCurrentUser();
    this.pubsService.pubCart$.subscribe( ( cart: PubCart ) => {
      this.cart = cart;
    } );
  }

  getCurrentUser(): void {
    this.customerService
      .getCurrentUser()
      .pipe( take( 1 ) )
      .subscribe(
        ( data ) => {
          this.user = data;
        },
        () => {
          console.error( 'Something went wrong fetching user details using "getCurrentUser()" call.' );
        }
      );
  }

  emptyCart( template: TemplateRef<any> ): void {
    this.modalRef = this.modalService.show( template, { class: 'modal-sm' } );
  }

  cancelEmpty(): void {
    this.modalRef.hide();
  }

  confirmEmpty(): void {
    this.pubCartService.emptyPubCartForUser( this.user.userId )
      .pipe( take( 1 ) )
      .subscribe( ( pubCart: PubCart ) => {
        this.pubsService.pubCart$.next( pubCart );
        this.modalRef.hide();
      }, () => {
        this.modalRef.hide();
        this.messageService.showErrorMessage( 'A problem was encountered while emptying your cart', false, 5000 );
      } );
  }

  removeLineItem( cartItemId: string ): void {
    this.pubCartService.removePublicationCartItemFromCart( this.user.userId, cartItemId )
      .pipe( take( 1 ) )
      .subscribe( ( pubCart: PubCart ) => {
        this.pubsService.pubCart$.next( pubCart );
      }, () => {
        this.messageService.showErrorMessage( 'A problem was encountered while removing the line item', false, 5000 );
      } );
  }

  updateCart(): void {
    this.spinnerService.showLoadingSpinner();
    this.pubCartService.updatePubCartForUser( this.user.userId, this.cart )
      .pipe( take( 1 ) )
      .subscribe( ( cart: PubCart ) => {
        this.pubsService.pubCart$.next( cart );
        this.formEdited = false;
        this.spinnerService.hideLoadingSpinner();
      }, () => {
        this.spinnerService.hideLoadingSpinner();
        this.messageService.showErrorMessage( 'A Problem was encountered while updating your cart', false, 5000 );
      } );
  }

  getItemPrice( price: number, quantity: number ): number {
    let itemPrice = 0;
    if ( quantity >= 0 ) {
      itemPrice = price * quantity;
    }
    return itemPrice;
  }
}
