/**
 * NAIC InsData Company Service API
 * NAIC InsData Company Service API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: fstier2@naic.internal
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * What attribute to order results by.
 */
export type CompaniesStatementsOrderBy = 'companyName_asc' | 'companyName_desc' | 'companyCoCode_asc' | 'companyCoCode_desc' | 'stateOfDomicile_asc' | 'stateOfDomicile_desc';

export const CompaniesStatementsOrderBy = {
    CompanyNameAsc: 'companyName_asc' as CompaniesStatementsOrderBy,
    CompanyNameDesc: 'companyName_desc' as CompaniesStatementsOrderBy,
    CompanyCoCodeAsc: 'companyCoCode_asc' as CompaniesStatementsOrderBy,
    CompanyCoCodeDesc: 'companyCoCode_desc' as CompaniesStatementsOrderBy,
    StateOfDomicileAsc: 'stateOfDomicile_asc' as CompaniesStatementsOrderBy,
    StateOfDomicileDesc: 'stateOfDomicile_desc' as CompaniesStatementsOrderBy
};
