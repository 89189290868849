/*!
 * Copyright 2021 National Association of Insurance Commissioners
 */

import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateChild, RouterStateSnapshot} from '@angular/router';
import { Observable } from 'rxjs';
import {map} from 'rxjs/operators';
import {OidcAuthService} from '../service/oidc-auth.service';

@Injectable()
export class AuthGuard implements CanActivateChild {
  constructor( private readonly oidcAuthService: OidcAuthService ) {
  }

  canActivateChild( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Observable<boolean> | Promise<boolean> | boolean {
    return this.oidcAuthService.isLoggedInObs().pipe( map( ( isLoggedIn ) => {
      return isLoggedIn;
    } ) );
  }
}
