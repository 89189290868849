/*!
 * Copyright 2021 National Association of Insurance Commissioners
 */

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component( {
  // tslint:disable-next-line:component-selector
	selector: 'pagination',
	templateUrl: './pagination.component.html',
} )
export class PaginationComponent {
	@Input() id: string;
	@Input() totalPages: number;
 @Input() currentPage: number;
	@Input() pageLinks: any[][];
	@Output() onPaginate = new EventEmitter<number>();

	canPaginate( newPage: number ): boolean {
		return newPage >= 1 && newPage <= this.totalPages && newPage !== this.currentPage;
	}

	paginate( newPage: number ): void {
		if ( !this.canPaginate( newPage ) ) {
			return;
		}

		this.currentPage = newPage;
		this.onPaginate.emit( newPage );
	}
}
