/**
 * NAIC InsData Order Service
 * NAIC InsData Order service
 *
 * OpenAPI spec version: 1.0.0
 * Contact: InsDataStaff@naic.org
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs/Observable';
import { ProductLine } from '../model/publications/productLine';


import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';


@Injectable( { providedIn: 'root' } )
export class ProductLinesService {

  protected basePath = 'https://localhost:8451';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor( protected httpClient: HttpClient, @Optional() @Inject( BASE_PATH ) basePath: string, @Optional() configuration: Configuration ) {
    if ( basePath ) {
      this.basePath = basePath;
    }
    if ( configuration ) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm( consumes: string[] ): boolean {
    const form = 'multipart/form-data';
    for ( const consume of consumes ) {
      if ( form === consume ) {
        return true;
      }
    }
    return false;
  }



  /**
   * Fetch All Product Lines
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public fetchAllProductLines(observe?: 'body', reportProgress?: boolean): Observable<Array<ProductLine>>;
  public fetchAllProductLines(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ProductLine>>>;
  public fetchAllProductLines(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ProductLine>>>;
  public fetchAllProductLines(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    let headers = this.defaultHeaders;

    // authentication (OAuth2) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<Array<ProductLine>>(`${this.basePath}/orders/publications/productLines`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Fetch Product Lines With Active Publications
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public fetchProductLinesWithActivePublications(observe?: 'body', reportProgress?: boolean): Observable<Array<ProductLine>>;
  public fetchProductLinesWithActivePublications(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ProductLine>>>;
  public fetchProductLinesWithActivePublications(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ProductLine>>>;
  public fetchProductLinesWithActivePublications(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    let headers = this.defaultHeaders;

    // authentication (OAuth2) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<Array<ProductLine>>(`${this.basePath}/orders/publications/productLines/activePublications`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Fetch Product Lines With Inactive Publications
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public fetchProductLinesWithInactivePublications(observe?: 'body', reportProgress?: boolean): Observable<Array<ProductLine>>;
  public fetchProductLinesWithInactivePublications(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ProductLine>>>;
  public fetchProductLinesWithInactivePublications(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ProductLine>>>;
  public fetchProductLinesWithInactivePublications(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    let headers = this.defaultHeaders;

    // authentication (OAuth2) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<Array<ProductLine>>(`${this.basePath}/orders/publications/productLines/inactivePublications`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }


}
