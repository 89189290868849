/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 * !
 * Copyright 2021 National Association of Insurance Commissioners
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes,extraRequire}
 * tslint:disable
 */
var styles = ["label.company-search-label[_ngcontent-%COMP%]{font-size:16px}.no-spin[_ngcontent-%COMP%]::-webkit-inner-spin-button, .no-spin[_ngcontent-%COMP%]::-webkit-outer-spin-button{-webkit-appearance:none!important;margin:0!important;-moz-appearance:textfield!important}#company-search-page[_ngcontent-%COMP%]   .control-label[_ngcontent-%COMP%]{font-weight:700}#company-search-page[_ngcontent-%COMP%]   .control-label[_ngcontent-%COMP%]:after{content:\":\"}#company-search-page[_ngcontent-%COMP%]   .company-search-form[_ngcontent-%COMP%]{background:#f8f9fa}#company-search-page[_ngcontent-%COMP%]   .company-search-results[_ngcontent-%COMP%]   table[_ngcontent-%COMP%]   td[_ngcontent-%COMP%]{max-width:100px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}#company-search-page[_ngcontent-%COMP%]   .company-profile[_ngcontent-%COMP%]   label[_ngcontent-%COMP%]{font-weight:700;min-width:5rem}#company-search-page[_ngcontent-%COMP%]   .company-profile[_ngcontent-%COMP%]   label[_ngcontent-%COMP%]:after{content:\":\"}#company-results[_ngcontent-%COMP%]   table[_ngcontent-%COMP%]   thead[_ngcontent-%COMP%]   th.co-code-cell[_ngcontent-%COMP%]{max-width:80px}#company-results[_ngcontent-%COMP%]   table[_ngcontent-%COMP%]   thead[_ngcontent-%COMP%]   th.home-office-cell[_ngcontent-%COMP%]{max-width:70px}"];
export { styles as styles };

