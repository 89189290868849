<div id="account-page" class="row">
  <p *ngIf="loading">Loading Account... <span class="fa fa-spinner fa-spin fa-lg"></span></p>
  <section *ngIf="!loading" class="col-md-8 col-lg-6 account-form py-2 m-auto">
    <h3>Account Profile</h3>
    <form (submit)="updateAccount()">
      <div *ngFor="let field of _.keys(accountForm)" class="form-group d-sm-flex row">
        <label class="sr-only" [id]="'tst-account-update-label-' + field">{{displayField(field)}}</label>
        <div class="col">
          <input [(ngModel)]="accountForm[field].text"
            type="text"
            [name]="'account-update-' + field"
            [id]="'account-update-' + field"
            class="form-control"
            [placeholder]="getPlaceholder(field)"
            [disabled]="accountForm[field].disabled">
        </div>
      </div>
      <div class="row">
        <div class="col text-center">
          <input type="submit" value="Update Account" class="btn btn-primary mr-2" id="tst-account-update-save-btn" [disabled]="!formIsValid()">
          <input type="button" value="Reset" class="btn btn-secondary" (click)="resetForm()" id="tst-account-update-reset-btn">
        </div>
      </div>
    </form>
  </section>
</div>
