/*!
 * Copyright 2021 National Association of Insurance Commissioners
 */

import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {EndUserAgreement} from '../../model/customer/endUserAgreement';
import {InactivityService} from '../../service/inactivity.service';
import {InsdataCustomerService} from '../../service/insdata-customer.service';
import {InsdataMessageService} from '../../service/insdata-message.service';
import {OidcAuthService} from '../../service/oidc-auth.service';

declare var dtrum;

@Component( {
  selector: 'app-agreement',
  templateUrl: './agreement.component.html',
  styleUrls: ['./agreement.component.scss'],
} )
export class AgreementComponent implements OnInit {
  endUserAgreement: EndUserAgreement;
  loading = false;
  agreeBtnDisabled = false;
  userExists = false;
  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    public readonly oidcAuthService: OidcAuthService,
    private readonly customerService: InsdataCustomerService,
    private readonly insdataMessageService: InsdataMessageService,
    private readonly inactivityService: InactivityService
  ) {}

  ngOnInit() {
    this.route.data.subscribe( ( data: { endUserAgreement: EndUserAgreement } ) => {
      this.endUserAgreement = data.endUserAgreement;
    } );
    this.inactivityService.startTimer();
    // Check if user exists
    this.customerService.getCurrentUser().subscribe( ( user ) => {
      this.oidcAuthService.userInfo.insdataUserId = user.userId;
      if ( 'dtrum' in window ) {
        dtrum.identifyUser( user.userName );
        this.userExists = true;
        this.agreeBtnDisabled = false;
      }
    } );
  }

  agree() {
    this.agreeBtnDisabled = true;
    if ( !this.userExists ) {
      this.registerUser();
    } else {
      this.router.navigate( ['/home/companySearch'] );
    }

  }

  cancel() {
    this.oidcAuthService.startSignoutMainWindow();
  }

  registerUser() {
    this.customerService.registerUser( { preferredUserId: this.oidcAuthService.userInfo.preferredUsername } ).subscribe( ( user ) => {

      this.oidcAuthService.userInfo.insdataUserId = user.userId;
      if ( 'dtrum' in window ) {
        dtrum.identifyUser( user.userName );
      }
      this.router.navigate( ['/home/companySearch'] );
    }, () => {
      this.agreeBtnDisabled = false;
      this.insdataMessageService.showErrorMessage( 'An Error Occurred' );
    }, () => {
      this.agreeBtnDisabled = false;
    } );
  }


}
