/*!
 * Copyright 2021 National Association of Insurance Commissioners
 */
import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';

@Directive( {
  selector: '[appNoPoBox]',
  providers: [ { provide: NG_VALIDATORS, useExisting: NoPoBoxDirective, multi: true } ],
} )
export class NoPoBoxDirective implements Validator {
  validate( control: AbstractControl ): ValidationErrors | null {
    return control.value ? noPoBoxValidator()( control ) : null;
  }
}

export function noPoBoxValidator(): ValidatorFn {
  return ( control: AbstractControl ): { [ key: string ]: any } | null => {
    const value = control.value.replace( /\W/g, '' ).toUpperCase();
    const poBox = /POBOX/i.test( value );
    return poBox ? { poBox: { value: control.value } } : null;
  };
}
