/**
 * NAIC InsData Order Service
 * NAIC InsData Order service
 *
 * OpenAPI spec version: 1.0.0
 * Contact: InsDataStaff@naic.org
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Entity status.
 */
export type EntityStatus = 'active' | 'inactive' | 'pending' | 'failed' | 'expired' | 'no_changes' | 'cancelled';

export const EntityStatus = {
    Active: 'active' as EntityStatus,
    Inactive: 'inactive' as EntityStatus,
    Pending: 'pending' as EntityStatus,
    Failed: 'failed' as EntityStatus,
    Expired: 'expired' as EntityStatus,
    NoChanges: 'no_changes' as EntityStatus,
    Cancelled: 'cancelled' as EntityStatus
};
