/*!
 * Copyright 2021 National Association of Insurance Commissioners
 */

import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CompanySearchComponent} from './company-search/company-search.component';

@NgModule( {
  imports: [RouterModule.forChild( [
    {path: '', component: CompanySearchComponent},
  ] )],
  exports: [RouterModule],
} )
export class CompanyRoutingModule {
}
