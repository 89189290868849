<!--
  ~ Copyright 2024 National Association of Insurance Commissioners
  -->

<div class="container-fluid">
  <div class="row justify-content-center mb-4">
    <div class="col-10">
      <a [routerLink]="['./publicationList']" class="font-24"
         routerLinkActive="active"
         [class.component-selected]="activeComponent === 'publications'">Publications</a>
      <span class="font-24">&nbsp;|&nbsp;</span>
      <a [routerLink]="['./purchased']" class="font-24" routerLinkActive="active"
         [class.component-selected]="activeComponent === 'purchased'">Purchases</a>
    </div>
    <div class="col-2 float-right">
      <button type="button" class="btn btn-clear-wrapper" [routerLink]="['publicationsCart']"
              id="publicationsCartButton" title="View Publications Cart">
        <span class="fa fa-shopping-cart"></span>&nbsp;&nbsp;Publications Cart
        ({{ pubCartItemCount }})
      </button>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-12">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
