<!--
  ~ Copyright 2024 National Association of Insurance Commissioners
  -->

<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h4><b>Your Purchases</b></h4>
        </div>
        <div class="card-body">
          <div *ngIf="purchases.length > 0; else noPurchases" class="row mb-2">
            <div class="col-2 align-self-center">
              <span><b>Order #</b></span>
            </div>
            <div class="col-2 align-self-center">
              <span><b>Order Date</b></span>
            </div>
            <div class="col-3 align-self-center">
              <span><b>Transaction #</b></span>
            </div>
            <div class="col-2 align-self-center">
              <span><b>Status</b></span>
            </div>
            <div class="col-3 align-self-center">
              <span><b>Order Total</b></span>
            </div>
          </div>
          <div class="row mb-2" *ngFor="let purchase of purchases">
            <div class="col-2 align-self-center">
              <span class="pseudo-link" (click)="selectedPurchase = purchase; receiptModal.show()"
                    tooltip="More details">{{ purchase.orderId | slice:-6 }}</span>
            </div>
            <div class="col-2 align-self-center">
              <span>{{ purchase.purchaseDate | date: 'MM/dd/yyyy' }}</span>
            </div>
            <div class="col-3 align-self-center">
              <span>{{ purchase.transactionId }}</span>
            </div>
            <div class="col-2 align-self-center">
              <span *ngIf="isDownloadActive(purchase); else purchaseExpired"
                    class="pseudo-link"
                    id="purchased-download-span"
                    (click)="initiateDownload(purchase.orderId)"
              >
                Download
              </span>
              <ng-template #purchaseExpired>
                <span>Expired</span>
              </ng-template>
            </div>
            <div class="col-3 align-self-center">
              <span>{{ purchase.total | currency }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Download Modal -->
<ng-template #downloadModal>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content border-0">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 text-center mb-3">
            <span class="fa fa-spinner fa-spin fa-5x"></span>
          </div>
        </div>
        <div class="row text-center">
          <span>Your publication files are being generated.</span>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #deactivateModal>
  <div class="modal-content border-0">
    <div class="modal-body">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 text-center">
            <span>Are you sure you want to leave? File generation will stop.</span>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-primary" (click)="deactivateAction(true)">Leave</button>
      <button class="btn btn-default" (click)="deactivateAction(false)">Cancel</button>
    </div>
  </div>
</ng-template>

<!-- Receipt Modal -->
<div bsModal #receiptModal="bs-modal" class="modal fade" tabindex="-1"
     role="dialog" (onHidden)="selectedPurchase = undefined">
  <div *ngIf="selectedPurchase" class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Order Details</h4>
        <button type="button" class="close pull-right" (click)="receiptModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div id="receipt-details" class="row">
          <div class="col-6">
            <div class="row">
              <div class="col">
                <span class="receipt-detail-label">Customer Name:</span>
                &nbsp;
                <span>{{ user.firstName }}&nbsp;{{ user.lastName }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <span class="receipt-detail-label">Company Name:</span>
                &nbsp;
                <span>{{ selectedPurchase.companyName }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <span class="receipt-detail-label">Phone Number:</span>
                &nbsp;
                <span>{{ getFormattedPhoneNumber() }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <span class="receipt-detail-label">Order Number:</span>
                &nbsp;
                <span>{{ selectedPurchase.orderId | slice:-6 }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <span class="receipt-detail-label">Order Date:</span>
                &nbsp;
                <span>{{ selectedPurchase.purchaseDate | date: 'MM/dd/yyyy' }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <span class="receipt-detail-label"># of Publications:</span>
                &nbsp;
                <span>{{ calculateOrderTotalQuantity(selectedPurchase) }}</span>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="row">
              <div class="col">
                <span class="receipt-detail-label">Mailing Address:</span>
                <br *ngIf="getAddress().length > 0">
                <span [innerHTML]="getAddress()"></span>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <span class="receipt-detail-label">Transaction #:</span>
                &nbsp;
                <span>{{ selectedPurchase.transactionId }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <span class="receipt-detail-label">Card Number:</span>
                &nbsp;
                <span>{{ selectedPurchase.creditCardMask }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <span class="receipt-detail-label">Order Total:</span>
                &nbsp;
                <span>{{ selectedPurchase.total | currency }}</span>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-2">
            <span class="receipt-detail-label">SKU</span>
          </div>
          <div class="col-4">
            <span class="receipt-detail-label">Publication</span>
          </div>
          <div class="col-2">
            <span class="receipt-detail-label">Quantity</span>
          </div>
          <div class="col-2">
            <span class="receipt-detail-label">Unit Price</span>
          </div>
          <div class="col-2">
            <span class="receipt-detail-label">Price</span>
          </div>
        </div>
        <div id="receipt-items" class="row" *ngFor="let item of selectedPurchase?.publicationOrderItems">
          <div class="col-2">
            <span>{{ item.sku }}</span>
          </div>
          <div class="col-4">
            <span>{{ item.publicationName }}</span>
          </div>
          <div class="col-2">
            <span>{{ item.quantity }}</span>
          </div>
          <div class="col-2">
            <span>{{ item.unitPrice | currency }}</span>
          </div>
          <div class="col-2">
            <span>{{ item.subTotal | currency }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #noPurchases>
  <div class="row">
    <div class="col-12">
      <span>You have not made any purchases yet.</span>
    </div>
  </div>
</ng-template>


