/*!
 * Copyright 2021 National Association of Insurance Commissioners
 */

import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {InsdataLoadingSpinnerService} from '../../service/insdata-loading-spinner.service';

@Component( {
  selector: 'app-insdata-loading-spinner',
  templateUrl: './insdata-loading-spinner.component.html',
  styleUrls: ['./insdata-loading-spinner.component.scss'],
} )
export class InsdataLoadingSpinnerComponent implements OnInit, OnDestroy {

  @ViewChild( 'loadingSpinnerModal', {static: false} ) loadingSpinnerModal: ModalDirective;

  private readonly ngUnsubscribe: Subject<any> = new Subject<any>();

  constructor( private readonly insdataLoadingSpinnerService: InsdataLoadingSpinnerService ) {
  }

  ngOnInit() {
    this.insdataLoadingSpinnerService.getloadingSpinnerSubject().pipe( takeUntil( this.ngUnsubscribe ) ).subscribe( ( showLoadingSpinner ) => {
      if ( showLoadingSpinner ) {
        this.loadingSpinnerModal.show();
      } else {
        this.loadingSpinnerModal.hide();
      }
    } );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
