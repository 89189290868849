/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 * !
 * Copyright 2021 National Association of Insurance Commissioners
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes,extraRequire}
 * tslint:disable
 */
var styles = ["#wrapper[_ngcontent-%COMP%]{margin-top:10px;margin-bottom:10px}.full-page[_ngcontent-%COMP%]{height:100%;position:absolute;width:100%;display:flex;flex-direction:column;justify-content:space-between;overflow-y:auto}@media (-ms-high-contrast:none),screen and (-ms-high-contrast:active){.full-page[_ngcontent-%COMP%]{display:block}}@media (min-width:1400px){#main.container[_ngcontent-%COMP%]{max-width:1340px}}@media (min-width:1600px){#main.container[_ngcontent-%COMP%]{max-width:none}}"];
export { styles as styles };

