/*!
 * Copyright 2021 National Association of Insurance Commissioners
 */

import {HttpHeaders, HttpParams} from '@angular/common/http';

export class RequestOptions {
  headers?: HttpHeaders;
  observe?: 'body';
  params?: HttpParams | {
    [param: string]: string | string[];
  };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;

  constructor( headers?: { headers: HttpHeaders } ) {
    this.headers = headers.headers;
  }
}
