<!--
  ~  Copyright 2021 National Association of Insurance Commissioners
  ~
  -->

<div *ngFor="let productLine of productLineList" class="row mb-3 clearfix">
  <div class="col">

    <accordion [isAnimated]="true" class="rounded-lg">
      <accordion-group [isOpen]="isAccordionOpen(productLine)" #accordionGroupRef class="shadow"
                       (isOpenChange)="recordOpenChange($event, productLine)">
        <div accordion-heading>
          <h4>
            {{ productLine.name }}
            <span *ngIf="accordionGroupRef.isOpen"
                  class="fa fa-caret-up float-right publication-expand-link"></span>
            <span *ngIf="!accordionGroupRef.isOpen"
                  class="fa fa-caret-down float-right publication-expand-link"
                  title="See More"></span>
          </h4>
          <h6 class="ml-1">{{ getProductLineSkus(productLine) }}</h6>
        </div>
        <div id="{{ productLine.name | trim }}AccordianContent">
          <div class="container">
            <div id="{{ productLine.name | trim }}Description" class="row">
              <div [innerHTML]="productLine.description"></div>
            </div>
            <div id="{{ productLine.name | trim}}Publications" class="row">
              <div class="container">
                <div class="row">
                  <div class="col">
                  </div>
                </div>
                <!-- Publications -->
                <div *ngFor="let publication of productLine.publications" class="row mb-2">
                  <hr class="publication-divider">
                  <div class="col-12">
                    <div class="container">
                      <div class="row">
                        <div class="col-2 align-self-center">
                          <button type="button" class="btn btn-link"
                                  tooltip="More Information"
                                  (click)="recordMoreDataCollapseChange(publication)">
                            <span class="publication-expand-link">{{ publication.sku }}</span>
                          </button>
                        </div>
                        <div class="col-4 align-self-center">
                          <span>{{ publication.intendedProductYear }} {{ publication.productName }}</span>
                        </div>
                        <div class="col-2 align-self-center">
                          <span class="icon">{{ publication.price | currency }}</span>
                        </div>
                        <div class="col-1 align-self-center">
                          <input type="text"
                                 [formControl]="publicationFormControlMap.get(publication.publicationId)"
                                 class="form-control form-control-sm border border-dark rounded"
                                 maxlength="4"
                                 size="4"
                          >
                          <small class="text-danger" style="font-size: smaller"
                                 *ngIf="publicationFormControlMap.get(publication.publicationId).dirty &&
                                                              publicationFormControlMap.get(publication.publicationId).errors?.pattern">
                            Digits only
                          </small>

                        </div>
                        <div class="col-2 align-self-center text-right"
                             aria-label="Publication Buttons">
                          <button type="button" class="btn btn-clear-wrapper"
                                  id="{{publication.publicationId}}CartButton"
                                  aria-describedby="add publication to cart"
                                  (click)="addToCart(publication)"
                                  [disabled]="isDisabled(publication.publicationId)"
                          >
                            <span class="fa fa-cart-plus" tooltip="Add To Cart"></span>
                          </button>
                        </div>
                      </div>
                      <div class="row collapse mt-2 ml-1"
                           [collapse]="isMoreDataCollapsed(publication)"
                           id="{{ publication.publicationId }}MoreData">
                        <div class="col-10">
                          <div [innerHTML]="publication.description"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </accordion-group>
    </accordion>
  </div>
</div>
