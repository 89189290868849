/**
 * NAIC InsData Customer Service API
 * NAIC InsData Customer Service API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: InsDataStaff@naic.org
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * User billing flag
 */
export type BillingOption = 'chargeAsPriced' | 'doNotCharge';

export const BillingOption = {
    ChargeAsPriced: 'chargeAsPriced' as BillingOption,
    DoNotCharge: 'doNotCharge' as BillingOption
};
