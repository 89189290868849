/*!
 * Copyright 2021 National Association of Insurance Commissioners
 */

import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class InsdataLoadingSpinnerService {

  private readonly showLoadingSpinnerSubject: Subject<boolean> = new Subject<boolean>();

  showLoadingSpinner() {
    this.showLoadingSpinnerSubject.next( true );
  }

  hideLoadingSpinner() {
    this.showLoadingSpinnerSubject.next( false );
  }

  getloadingSpinnerSubject() {
    return this.showLoadingSpinnerSubject;
  }

}
