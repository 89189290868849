/*!
 * Copyright 2021 National Association of Insurance Commissioners
 */

import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {OidcAuthService} from '../../service/oidc-auth.service';

@Component( {
  selector: 'app-landing',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
} )
export class HomeComponent {

  constructor( private readonly oidcAuthService: OidcAuthService,
              private readonly router: Router ) {
  }


  isOnAgreement(): boolean {
    return ( this.router.url === '/home/agreement' );
  }

}
