/*!
 * Copyright 2021 National Association of Insurance Commissioners
 */

import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import {BannerInput} from './model/customer/bannerInput';
import {InsdataCustomerService} from './service/insdata-customer.service';
import {InsdataLoadingSpinnerService} from './service/insdata-loading-spinner.service';
import {OidcAuthService} from './service/oidc-auth.service';

declare var dT_;

@Component( {
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
} )
export class AppComponent implements OnInit {
  loggedInNow = false;
  bannerInfo: BannerInput;

  constructor(
    private readonly oidcAuthService: OidcAuthService,
    private readonly router: Router,
    private readonly http: HttpClient,
    private readonly insdataLoadingSpinnerService: InsdataLoadingSpinnerService,
    public readonly customerService: InsdataCustomerService
  ) {
    this.router.events.subscribe( ( e ) => {
      this.navigationInterceptor( e );
    } );
    if ( typeof dT_ !== 'undefined' && dT_.initAngularNg ) {
      dT_.initAngularNg( http, HttpHeaders );
    }
  }

  ngOnInit() {
    this.customerService.bannerInfo$.subscribe( ( data ) => {
      this.bannerInfo = data;
    } );
    this.oidcAuthService.isLoggedInObs().subscribe( ( isLoggedIn ) => {
      if ( isLoggedIn ) {
        this.router.navigate( ['/home/agreement'] );
        this.loggedInNow = true;
      }
    } );
  }

  /**
   * The purpose of this function is to prevent the insdata-messages to show up twice.  This function is being used
   * inside the insdata-message tag of this app.component.html template.  There is another one under the header.
   * This function asks if you're anywhere but the bootstrapped app component (the one you're seeing right now).  If you're anywhere else
   * then the insdata-message tag in the app.component.html will not render.
   * @returns {boolean}
   */
  // TODO in the future, refactor so instead of home component to be the host component, make it app.component or make home component the bootstrap component
  isInApp(): boolean {
    return ( this.router.url !== '/' );
  }

  navigationInterceptor( event ): void {
    if ( event instanceof NavigationStart ) {
      this.insdataLoadingSpinnerService.showLoadingSpinner();
    }
    if ( event instanceof NavigationEnd ) {
      this.insdataLoadingSpinnerService.hideLoadingSpinner();
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if ( event instanceof NavigationCancel ) {
      this.insdataLoadingSpinnerService.hideLoadingSpinner();
    }
    if ( event instanceof NavigationError ) {
      this.insdataLoadingSpinnerService.hideLoadingSpinner();
    }
  }
}
