<div class="full-page">
  <div>
    <app-header></app-header>
    <div class="row">
      <div class="col-12">
        <app-banner></app-banner>
      </div>
    </div>
    <app-insdata-message></app-insdata-message>
    <div id="wrapper">
      <div  id="main" class="container-fluid">
        <div id="app-component-router-outlet">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
