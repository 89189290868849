/**
 * NAIC InsData Order Service
 * NAIC InsData Order service
 *
 * OpenAPI spec version: 1.0.0
 * Contact: InsDataStaff@naic.org
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Abstract Order Input Data.
 */
export interface OrderInput { 
    orderType: OrderInput.OrderTypeEnum;
}
export namespace OrderInput {
    export type OrderTypeEnum = 'CartOrderInput' | 'BulkOrderInput';
    export const OrderTypeEnum = {
        CartOrderInput: 'CartOrderInput' as OrderTypeEnum,
        BulkOrderInput: 'BulkOrderInput' as OrderTypeEnum
    };
}
