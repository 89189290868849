/*
 * Copyright 2024 National Association of Insurance Commissioners
 */
/* tslint:disable:comment-format */

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PubCart } from '../../model/publications/pubCart';
import { PubCartItem } from '../../model/publications/pubCartItem';
import { Publication } from '../../model/publications/publication';

@Injectable( {
  providedIn: 'root',
} )
export class PubsService {
  activeComponent = 'publications';
  pubCart$: BehaviorSubject<PubCart> = new BehaviorSubject<PubCart>( null );

  handleCart404(): void {
    this.pubCart$.next( null );
  }

  isPublicationInCart( publication: Publication ): number {
    const currentCart: PubCart = this.pubCart$.value;
    if ( currentCart === null ) {
      return -1;
    }
    return currentCart?.items.findIndex( ( item: PubCartItem ) => item.publication.sku === publication.sku );
  }
}
