/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 * !
 * Copyright 2021 National Association of Insurance Commissioners
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes,extraRequire}
 * tslint:disable
 */
var styles = [".card-agreement[_ngcontent-%COMP%]{border-color:#e9e9e9}.loading-modal[_ngcontent-%COMP%]{display:table;position:relative;margin:0 auto;top:calc(50% - 24px)}.loading-modal-content[_ngcontent-%COMP%]{background-color:transparent;border:none}"];
export { styles as styles };

