/*!
 * Copyright 2021 National Association of Insurance Commissioners
 */

import { InjectionToken } from '@angular/core';

export const BASE_PATH = new InjectionToken<string>( 'basePath' );
export const COLLECTION_FORMATS = {
  'csv': ',',
  'tsv': '   ',
  'ssv': ' ',
  'pipes': '|',
};
