/*!
 * Copyright 2021 National Association of Insurance Commissioners
 */

import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {OidcAuthService} from './oidc-auth.service';

@Injectable()
export class InactivityService {

  private time: any = null;

  constructor( private readonly oidcAuthService: OidcAuthService ) { }

  startTimer() {
    this.resetTimer();

    window.onload = this.resetTimer.bind( this );
    document.onmousemove = this.resetTimer.bind( this );
    document.onkeydown = this.resetTimer.bind( this );
  }

  private resetTimer() {
    if ( this.time ) {
        clearTimeout( this.time );
        this.time = null;
    }
    // Need to bind to "this" otherwise when the function is called it doesn't know the context since we're passing in the function.
    this.time = setTimeout( this.alertUser.bind( this ), environment.sessionTimeOut );
  }

  private alertUser() {
    alert( 'Your session has expired.  Please log in again' );
    clearTimeout( this.time );
    this.time = null;
    this.oidcAuthService.startSignoutMainWindow();

  }

}
