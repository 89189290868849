/*!
 * Copyright 2021 National Association of Insurance Commissioners
 */

import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import * as _ from 'lodash';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {Cart} from '../../model/cart/cart';
import {ProductDataKey} from '../../model/common/productDataKey';
import * as models from '../../model/company/models';
import {User} from '../../model/customer/user';
import * as orderModels from '../../model/order/models';
import {InsdataCompanySearchService} from '../../service/insdata-company-search.service';
import {InsdataCustomerService} from '../../service/insdata-customer.service';
import {InsdataMessageService} from '../../service/insdata-message.service';
import {InsdataOrdersService} from '../../service/insdata-orders.service';
import {InsdataShoppingCartService} from '../../service/insdata-shopping-cart.service';
import {InsdataDateConverter} from '../../utils/insdata-date-converter';

interface CodeDisplayObj {
  code: string;
  display: string;
}

@Component( {
  selector: 'app-view-orders',
  templateUrl: './view-orders.component.html',
  styleUrls: ['./view-orders.component.scss'],
} )
export class ViewOrdersComponent implements OnInit, OnDestroy {
  @ViewChild( 'viewDetailsModal', {static: false} ) viewDetailsModal: ModalDirective;
  @ViewChild( 'loadingOrderDetailModal', {static: false} ) loadingOrderDetailModal: ModalDirective;
  @ViewChild( 'loadingDownloadPage', {static: false} ) loadingDownloadPage: ModalDirective;
  @ViewChild( 'errorOccurredModal', {static: false} ) errorOccurredModal: ModalDirective;

  cart: Cart;
  orders: orderModels.OrderBaseData[] = [];
  truncatedUuidLength = 6;
  showTab = 'BULK';
  orderHeaders: CodeDisplayObj[] = [
    {code: 'orderId', display: 'Order #'},
    {code: 'purchaseDate', display: 'Order Date'},
    {code: 'transactionId', display: 'Transaction #'},
    {code: 'orderStatus', display: 'Status'},
    {code: 'totalUsd', display: 'Order Total'},
    {code: 'viewInvoice', display: 'Invoice'},
  ];
  bulkOrderHeaders: CodeDisplayObj[] = [
    {code: 'orderId', display: 'Order Number'},
    {code: 'purchaseDate', display: 'Order Creation Date'},
    {code: 'orderStatus', display: 'Status'},
    {code: 'orderDetails', display: 'Order Details'},
  ];
  orderStatuses: CodeDisplayObj[] = [
    {code: orderModels.EntityStatus.Active, display: 'Download Bulk Orders PDF'},
    {code: orderModels.EntityStatus.Failed, display: 'Transaction Failed'},
    {code: orderModels.EntityStatus.NoChanges, display: 'No Changes Available'},
    {code: orderModels.EntityStatus.Pending, display: 'In Process'},
    {code: orderModels.EntityStatus.Expired, display: 'Expired'},
    {code: orderModels.EntityStatus.Inactive, display: 'Inactive'},
    {code: orderModels.EntityStatus.Cancelled, display: 'Cancelled'},
  ];
  sortOrder = 'desc';
  sortFields: string[] = ['purchaseDate', 'orderId'];
  activeOrderDetail: orderModels.OrderDetail; // Order that the user clicks to view the details of
  activeOrderItemDetails: Array<orderModels.OrderItemDetail>;
  convertSecondsToMilliseconds = 1000;
  loadingOrders = false;
  entityStatus = orderModels.EntityStatus; // For Component Context
  helpText = 'Files within a purchase are subject to change at any time. See HELP for more details.';
  userRole;
  resultsHavePagination = true;
  rowsPerPage = 10;
  currentPage = 1;
  paginationVariance = 3; // Pagination shows, at most, this many pages away from current page
  params: any = {};
  totalResult: number;
  ordersSearchResults: orderModels.OrdersSearchResult;
  user: User;
  selectedBulkOrderLinks: Array<string>;
  selectedBulkOrderId: string;
  shouldShowDownloadPage = false;
  visitedIndexes: Array<number> = [];
  readonly downloadPageMessage = 'Please, Initiate the downloads within 10 minutes. If the download links do not work, please Login again.';
  private readonly ngUnsubscribe: Subject<any> = new Subject();

  constructor( private readonly shoppingCartService: InsdataShoppingCartService,
              private readonly insdataOrderService: InsdataOrdersService,
              private readonly insdataDateConverter: InsdataDateConverter,
              private readonly insdataMessageService: InsdataMessageService,
              private readonly insdataCompanyService: InsdataCompanySearchService,
              private readonly insdataCustomerService: InsdataCustomerService,
              private readonly router: Router,
              private readonly activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.cart = this.shoppingCartService.cart;
    this.getUserInfo();
    this.insdataCustomerService.getCurrentUserRole().subscribe( ( data ) => {
      this.userRole = data;
      if ( this.userRole === 'ROLE_SPECIAL' || this.userRole === 'ROLE_ALL_ACCESS' ) {
        this.fetchAndDisplayBulkOrders();
      } else {
        this.fetchAndDisplayCartOrders();
      }
      this.activatedRoute.params.pipe( takeUntil( this.ngUnsubscribe ) ).subscribe( ( params: any ) => {
        this.params = params;
        this.currentPage = Number( this.params.page ) || 1;
      } );
    }, () => {
      this.insdataMessageService.showErrorMessage( 'Error obtaining user role.' );
    } );
  }

  getUserInfo() {
    this.insdataCustomerService.getCurrentUser().subscribe( ( data ) => {
      this.user = data;
    }, () => {
      this.insdataMessageService.showErrorMessage( 'Error getting user information.', false, 10000 );
    } );
  }

  fetchOrders( orderType, start?: number, limit?: number ) {
    this.loadingOrders = true;
    this.insdataOrderService.fetchOrders( orderType, start, limit ).pipe( takeUntil( this.ngUnsubscribe ) ).subscribe( ( ordersObj: orderModels.OrdersSearchResult ) => {
      if ( ordersObj && ordersObj.orders ) {
        this.ordersSearchResults = ordersObj;
        this.totalResult = this.ordersSearchResults.pageSearchResults.numItemsMatched;
        this.orders = ordersObj.orders.map( ( order: any ) => {
          return {
            ...order,
            purchaseDate: this.insdataDateConverter.convert( order.purchaseDate ),
          };
        } );
      }
      this.loadingOrders = false;
    }, () => {
      this.loadingOrders = false;
      this.insdataMessageService.showErrorMessage( 'An error occurred when trying to load orders' );
    } );
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getCurrentRows(): orderModels.OrderBaseData[] {
    return _.orderBy( this.orders, this.sortFields.map( ( field: string ) => field ), this.sortOrder );
  }

  sort( desiredSortField: string ): void {
    if ( this.sortFields[0] === desiredSortField ) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
      return;
    }
    this.sortFields = _.uniq( [desiredSortField].concat( this.sortFields ) );
  }

  downloadZip( orderId: string ) {
    this.insdataMessageService.showWarningMessage( 'Downloading your zip...' );
    const orderFileCriteria: models.DownloadLinkCriteria = {
      userId: this.user.userId, orderId: orderId, downloadFileType: 'ORDER_FILE',
    };

    this.insdataOrderService.fetchOrderDetail( orderId ).subscribe( ( data ) => {
      if ( data.orderData.orderType === 'BULK' ) {
          this.showDownloadPage( orderFileCriteria );
      } else if ( data.orderData.orderType === 'CART' ) {
        this.insdataCompanyService.downloadOrderFiles( this.user.userId, orderId ).pipe( take( 1 ) ).subscribe( ( link ) => {
          this.downloadIndividualOrder( link );
        }, () => {
          console.error( 'Something went wrong fetching file links using \'/downloadOrderFiles\' call.' );
          this.insdataMessageService.showErrorMessage( 'An error occurred when downloading your file.' );
        } );
      }
    }, () => {
      console.error( 'Something went wrong fetching order details using \'/orders/{userId}/{orderId}\' api call.' );
    } );
  }

  downloadIndividualOrder( link ) {
    const anchor = document.createElement( 'a' );
    anchor.href = link;
    anchor.click();
    this.insdataMessageService.clearMessage();
  }

  viewOrderDetails( order: orderModels.OrderBaseData ) {
    this.insdataMessageService.clearMessage();
    this.loadingOrderDetailModal.show();
    this.insdataOrderService.fetchOrderDetail( order.orderId ).pipe( takeUntil( this.ngUnsubscribe ) ).subscribe( ( orderDetail: orderModels.OrderDetail ) => {
      this.activeOrderDetail = orderDetail;
      this.activeOrderDetail.orderData.purchaseDate = this.insdataDateConverter.convert( this.activeOrderDetail.orderData.purchaseDate );
      this.activeOrderItemDetails = orderDetail.orderItemDetails;
      this.loadingOrderDetailModal.hide();
      this.viewDetailsModal.show();
    }, () => {
      this.loadingOrderDetailModal.hide();
      this.errorOccurredModal.show();
    } );
  }

  getFormattedStatementFilingPeriod( productDataKey: ProductDataKey ): string {
    const statementPages = ' Statement Pages';
    if ( this.isQuarterly( productDataKey.submissionFilingPeriod ) ) {
      return productDataKey.submissionFilingPeriod + statementPages;
    }
    if ( productDataKey.isKey ) {
      return `Key ${productDataKey.submissionFilingPeriod}${statementPages}`;
    } else {
      return `Non-Key ${productDataKey.submissionFilingPeriod}${statementPages}`;
    }
  }

  isQuarterly( filingPeriod: string ): boolean {
    return filingPeriod && filingPeriod.indexOf( 'Quarter' ) >= 0;
  }

  getOrderStatusLabel( status: orderModels.EntityStatus ): string {
    const label = _.result( _.find( this.orderStatuses, function ( obj ) {
      return obj.code === status;
    } ), 'display' );
    if ( !label ) {
      return 'Transaction Failed';
    }
    return label;
  }

  fetchAndDisplayBulkOrders() {
    this.showTab = 'BULK';
    this.resetOrders();
    this.fetchOrders( 'BULK', ( this.currentPage - 1 ) * this.rowsPerPage, this.rowsPerPage );
    this.totalResult = this.ordersSearchResults ? this.ordersSearchResults.pageSearchResults.numItemsMatched : 0;

  }

  fetchAndDisplayCartOrders() {
    this.showTab = 'CART';
    this.resetOrders();
    this.fetchOrders( 'CART', ( this.currentPage - 1 ) * this.rowsPerPage, this.rowsPerPage );
    this.totalResult = this.ordersSearchResults ? this.ordersSearchResults.pageSearchResults.numItemsMatched : 0;
  }

  calcTotalPages() {
    if ( !this.ordersSearchResults.pageSearchResults.numItemsMatched ) {
      console.error( 'no orders available!' );  //  S
    }
    return Math.ceil( this.ordersSearchResults.pageSearchResults.numItemsMatched / this.rowsPerPage );
  }

  buildPageLinks(): any[][] {
    const paginationPageNumbers: number[] = _.range(
      _.max( [1, this.currentPage - this.paginationVariance] ),
      _.min( [this.calcTotalPages(), this.currentPage + this.paginationVariance] ) + 1
    );
    return paginationPageNumbers.map( ( thisPage: number ): any[] => ['/home/viewOrders', {
      ...this.params,
      page: thisPage,
    }] );
  }

  navigateFromPagination( newPage: number ): void {
    if ( !newPage ) {
      return; // Do nothing if user clicks on current page
    }
    this.currentPage = newPage;
    if ( this.showTab === 'BULK' ) {
      this.fetchAndDisplayBulkOrders();
    } else if ( this.showTab === 'CART' ) {
      this.fetchAndDisplayCartOrders();
    }

    this.router.navigate( ['/home/viewOrders', {...this.params, page: newPage}] ).then(
      // Do Nothing.
    );	// Change URL
  }

  calcStartNumber(): number {
    return ( this.rowsPerPage * ( this.currentPage - 1 ) ) + 1;
  }

  calcEndNumber(): number {
    return this.currentPage === this.calcTotalPages() ? this.ordersSearchResults.pageSearchResults.numItemsMatched : this.calcStartNumber() + ( this.rowsPerPage - 1 );
  }

  resetOrders() {
    this.orders = [];
  }

  downloadFileUsingLink( value: models.DownloadLinkCriteria ) {
    this.insdataCompanyService.downloadOrderLink( value ).pipe( takeUntil( this.ngUnsubscribe ) ).subscribe( ( links ) => {
      const anchor = document.createElement( 'a' );
      anchor.href = links[0];
      anchor.click();
      this.insdataMessageService.clearMessage();
    }, () => {
      this.insdataMessageService.showErrorMessage( 'An error occurred when trying to download your bulk order file.', false, 10000 );
    } );
  }

  downloadManifestFile( row: orderModels.OrderBaseData ) {
    this.insdataMessageService.showWarningMessage( 'Downloading manifest file...' );
    const manifestFileCriteria: models.DownloadLinkCriteria = {
      userId: this.user.userId, downloadFileType: 'MANIFEST_FILE', orderId: row.orderId,
    };
    this.downloadFileUsingLink( manifestFileCriteria );
  }

  goToDownloadPage( orderId: string ) {
    this.loadingDownloadPage.show();
    this.selectedBulkOrderId = orderId;
    const orderFileCriteria: models.DownloadLinkCriteria = {
      userId: this.user.userId, orderId: orderId, downloadFileType: 'ORDER_FILE',
    };
    this.showDownloadPage( orderFileCriteria );
  }

  showDownloadPage( orderFileCriteria: models.DownloadLinkCriteria ) {
    this.insdataCompanyService.downloadOrderLink( orderFileCriteria ).pipe( takeUntil( this.ngUnsubscribe ) ).subscribe( ( links ) => {
      this.selectedBulkOrderLinks = [...links].sort( ( a, b ) => {
        const filename1 = this.getFileName( a );
        const filename2 = this.getFileName( b );

        const number1 = filename1.substring( filename1.indexOf( '_' ) + 1, filename1.indexOf( '.' ) - 1 );
        const number2 = filename2.substring( filename2.indexOf( '_' ) + 1, filename2.indexOf( '.' ) - 1 );

        return +number1 - +number2;
      } );
      this.shouldShowDownloadPage = true;
      this.loadingDownloadPage.hide();
      this.insdataMessageService.clearMessage();
    }, () => {
      this.loadingDownloadPage.hide();
      this.insdataMessageService.showErrorMessage( 'An error occurred when loading the download page.', false, 10000 );
    } );
  }

  goBackToMainOrdersPage() {
    this.selectedBulkOrderId = null;
    this.selectedBulkOrderLinks = null;
    this.shouldShowDownloadPage = false;
    this.router.navigate( ['home/viewOrders'] ).then (
      // Do nothing
    );
  }

  getFileName( link: string ): string {
    const orderId = this.selectedBulkOrderId.slice( -this.truncatedUuidLength );
    return link.substring( link.indexOf( orderId ), link.indexOf( 'zip' ) + 3 );
  }

  downloadBulkOrder( index: number ) {
    if ( ( this.visitedIndexes.indexOf( index ) <= -1 ) ) {
      this.visitedIndexes.push( index );
    }
    const anchor = document.createElement( 'a' );
    anchor.href = this.selectedBulkOrderLinks[index];
    anchor.click();
  }

  hasBeenVisited( index: number ): boolean {
    return this.visitedIndexes.indexOf( index ) > -1;
  }
}

