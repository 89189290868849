/**
 * NAIC InsData Order Service
 * NAIC InsData Order service
 *
 * OpenAPI spec version: 1.0.0
 * Contact: InsDataStaff@naic.org
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CoCodeListItem } from './coCodeListItem';


/**
 * Company Code list for user and filing year.
 */
export interface CoCodeList { 
    userId: string;
    /**
     * Filing Year
     */
    filingYear: number;
    /**
     * Company code list status.
     */
    status: CoCodeList.StatusEnum;
    /**
     * Date cocode list was uploaded.
     */
    importedDate: Date;
    /**
     * Is a list load in progress for this cocode list user and year?
     */
    loadInProgress: boolean;
    cocodeSet?: Array<CoCodeListItem>;
}
export namespace CoCodeList {
    export type StatusEnum = 'active' | 'pending';
    export const StatusEnum = {
        Active: 'active' as StatusEnum,
        Pending: 'pending' as StatusEnum
    };
}
