/*!
 * Copyright 2021 National Association of Insurance Commissioners
 */

import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {InsdataMessageService} from '../../service/insdata-message.service';

@Component( {
  selector: 'app-insdata-message',
  templateUrl: './insdata-message.component.html',
  styleUrls: ['./insdata-message.component.scss'],
} )
export class InsdataMessageComponent implements OnInit, OnDestroy {
  insdataMessage: {
    text: string,
    type: string
  };
  private readonly ngUnsubscribe: Subject<any> = new Subject();

  constructor( private readonly insdataMessageService: InsdataMessageService ) {
  }

  ngOnInit() {
    this.insdataMessageService.getMessageSubject().pipe( takeUntil( this.ngUnsubscribe ) ).subscribe( ( message ) => {
      this.insdataMessage = message;
    } );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  clearMessage() {
    this.insdataMessage = null;
  }
}
