/*!
 * Copyright 2021 National Association of Insurance Commissioners
 */

import {Directive, ViewContainerRef} from '@angular/core';

@Directive( {
  selector: '[appCompanySearchResultHost]',
} )
export class CompanySearchResultHostDirective {
  constructor( public viewContainerRef: ViewContainerRef ) { }

}
