/*!
 * Copyright 2021 National Association of Insurance Commissioners
 */

import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import * as _ from 'lodash';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {v4 as uuidv4} from 'uuid';
import {User} from '../../model/customer/user';
import {BulkOrderInput} from '../../model/order/bulkOrderInput';
import {BulkOrderType} from '../../model/order/bulkOrderType';
import {CoCodeListItem} from '../../model/order/coCodeListItem';
import {CoCodeLists} from '../../model/order/coCodeLists';
import {CoCodeListsDetail} from '../../model/order/coCodeListsDetail';
import StatusEnum = CoCodeListItem.StatusEnum;
import {CocodeLoadOrRemoveResult} from '../../model/order/cocodeLoadOrRemoveResult';
import {EntityStatus} from '../../model/order/entityStatus';
import {OrderBaseData} from '../../model/order/orderBaseData';
import {OrdersSearchResult} from '../../model/order/ordersSearchResult';
import {InsdataCustomerService} from '../../service/insdata-customer.service';
import {InsdataMessageService} from '../../service/insdata-message.service';
import {InsdataOrdersService} from '../../service/insdata-orders.service';

interface CodeDisplayObj {
  code: string;
  display: string;
}

@Component( {
  selector: 'app-cocode-upload',
  templateUrl: './cocode-upload.component.html',
  styleUrls: ['./cocode-upload.component.scss'],
} )
export class CocodeUploadComponent implements OnInit, OnDestroy {

  public get filterStatus(): typeof StatusEnum {
    return StatusEnum;
  }
  @ViewChild( 'deletingSingleCocodeModal', {static: false} ) deletingSingleCocodeModal: ModalDirective;
  @ViewChild( 'fileUploadingModal', {static: false} ) fileUploadModal: ModalDirective;
  @ViewChild( 'bulkOrderProcessingModal', {static: false} ) bulkOrderProcessingModal: ModalDirective;
  @ViewChild( 'viewHistoryModal', {static: true} ) viewHistoryModal: ModalDirective;
  @ViewChild( 'fileInput', {static: false} ) fileInput: ElementRef;
  @ViewChild( 'cocodeDeleteConfirmModal', {static: false} ) cocodeDeleteConfirmModal: ModalDirective;
  selectedYear: number;
  onUploadCocodePage: boolean;
  onCreateBulkOrderPage: boolean;
  selectedFileName = '';
  fileList: Array<string> = [];
  file: File;
  errorCode = 0;
  ext = '';
  orderYears: Array<number> = [];
  availableYears: Array<number> = [];
  selectedYearToViewHistory: number;
  selectedYearToCreateBulkOrder: number;
  cocodeListForSelectedYear: CoCodeListsDetail;
  disableChangesOnly: boolean;
  user: User;
  fetchingCocodeLists = true;
  loadingSingleListItem = false;
  pendingBulkOrderExists = false;
  importedDate;
  fetchingOrders = false;
  uploadStatus: string;
  loadingOrderYears = false;
  selectedBulkOrderType!: BulkOrderType;
  userOrders: Array<OrderBaseData> = [];
  orderBaseDetails: OrderBaseData = {
    bulkOrderType: this.selectedBulkOrderType,
    filingYear: ( new Date() ).getFullYear(),
    orderType: '',
    outcomeStatus: 'active',
    lastFourCcDigits: '',
    orderId: uuidv4(),
    orderStatus: EntityStatus.Pending,
    purchaseDate: new Date(),
    subTotalUsd: 0.00,
    taxUsd: 0.00,
    totalUsd: 0.00,
    transactionId: '',
    userId: '',   // User ID must be left blank here because it's obtained on initiation of the component.
  };
  bulkOrderInput: BulkOrderInput = {
    filingYear: 0,
    orderType: 'BulkOrderInput',
  };
  showTab = 'csvCriteriaTab';

  currentPageViewHistory = 0;
  rowsPerPageViewHistory = 10;
  totalResultViewHistory: number;

  currentPage = 0;
  rowsPerPage = 25;	// Arbitrary value (probably should be set by user and maybe default 100), many things adjust off of this.
  paginationVariance = 3;	// Pagination shows, at most, this many pages away from current page
  totalResult: number;
  params: any = {};
  isDeletingCocodes = false;
  listLoadOrOrderInProgress = false;
  cocodesAvailableToDelete = true;
  stmtTypeCriteriaForm: FormGroup;
  mergedCocodeList: Array<CoCodeListItem> = [];
  uploadedCocodeHeaders: CodeDisplayObj[] = [
    {code: 'orderId', display: 'Company Name'},
    {code: 'purchaseDate', display: 'Cocode Number'},
    {code: 'orderStatus', display: 'Status'},
    {code: 'orderDetails', display: 'Downloaded Date'},
    {code: 'orderDetails', display: 'Action'},
  ];
  selectedFilterStatus: StatusEnum;
  onViewHistoryPage = false;
  returnToListViewMessage = 'Return to List View';
  cocodeUploadBaseUrl = '/home/cocodeUpload';
  unusedCocodeCount = 0;
  availableStatus = 'Available – PDFs available but not yet downloaded. Cocode can be removed from list.';
  noFilingStatus = 'No filing – No PDFs available for download. Cocode can be removed from list.';
  lockedStatus = 'Locked – PDFs already downloaded. Cocodes cannot be removed from list.';
  pendingStatus = 'Pending – The status for this cocode will be updated in 24 hours to \'Locked\' \'No filing\' or \'Available\'.';
  private readonly ngUnsubscribe: Subject<any> = new Subject();

  constructor( private readonly insdataMessageService: InsdataMessageService,
              private readonly insdataCustomerService: InsdataCustomerService,
              private readonly insdataOrderService: InsdataOrdersService,
              private readonly activatedRoute: ActivatedRoute,
              private readonly router: Router
  ) {
  }

  ngOnInit() {
    this.loadingOrderYears = true;
    this.activatedRoute.data.subscribe( ( data: { orderYears: Array<number> } ) => {
      this.orderYears = data.orderYears;
    }, () => {
      // Nothing to do.
    }, () => {
      this.loadingOrderYears = false;
    } );
    this.insdataCustomerService.getCurrentUser().pipe( takeUntil( this.ngUnsubscribe ) ).subscribe( ( user: User ) => {
      this.user = user;
    }, () => {
      this.insdataMessageService.showErrorMessage( 'Unable to load user. For further assistance contact service desk.', false, 10000 );
    } );
    this.fetchCocodeLists();
    this.activatedRoute.params.pipe( takeUntil( this.ngUnsubscribe ) ).subscribe( ( params: any ) => {
      this.params = params;
      if ( !params.showViewHistoryOnPageLoad ) {
        this.currentPage = Number( this.params.page ) || 1;
      }
      this.currentPageViewHistory = params.showViewHistoryOnPageLoad ? Number( this.params.page ) || 1 : 1;
    } );
    this.fetchBulkOrderForYear();
  }

  selectFile( $event ) {
    this.fileList = $event.target.files;
    this.file = $event.target.files[0];
    if ( this.fileList.length > 0 ) {
      this.ext = this.file.name.substr( this.file.name.lastIndexOf( '.' ) + 1 );
      this.fileInput.nativeElement.value = '';
      if ( this.validateUploadedFile( this.file ) ) {
        if ( this.file.name.trim().length > 20 ) {
          this.selectedFileName = `${( this.file.name ).trim().substr( 0, 20 )}...`;
        } else {
          this.selectedFileName = ( this.file.name ).trim();
        }
      } else {
        this.removeFile();
      }
    }
  }

  goBackToCocodeListManager() {
    this.onUploadCocodePage = false;
    this.errorCode = 0;
    this.file = null;
    this.selectedYear = undefined;
    this.selectedFileName = '';
    this.onCreateBulkOrderPage = false;
    this.insdataMessageService.clearMessage();
  }

  clearError() {
    this.errorCode = 0;
    this.insdataMessageService.clearMessage();
  }

  addByUploadCSV() {
    if ( this.validateOnSubmit() ) {
      this.fileUploadModal.show();
      this.insdataOrderService.createCocodeList( this.user.userId, this.selectedYear, this.file ).pipe( takeUntil( this.ngUnsubscribe ) ).subscribe( () => {
        this.fileUploadModal.hide();
        this.resetFileUpload();
        this.onUploadCocodePage = false;
        this.availableYears = [];
        this.fetchCocodeLists();
        if ( this.selectedYear ) {
          this.fetchCocodeListsForYear( this.selectedYear );
        }
        this.insdataMessageService.showInformationalMessage( 'Processing uploaded file. We will email you shortly. Please check your email.', false );
      }, () => {
        this.fileUploadModal.hide();
        this.insdataMessageService.showErrorMessage( 'File upload unsuccessful. Please refer to your email for specific error details.', false );
      } );
    }
  }

  validateUploadedFile( file ) {
    this.insdataMessageService.clearMessage();
    if ( file.size > 2097152 ) {
      this.removeFile();
      this.errorCode = 2;
      this.insdataMessageService.showErrorMessage( 'Upload file has exceeded maximum size of 2 MB.', false, 10000 );
      return false;
    }
    if ( this.ext.toLowerCase() !== 'csv' ) {
      this.errorCode = 3;
      this.insdataMessageService.showErrorMessage( 'File format not supported. Please upload .csv file.', false, 10000 );
      return false;
    }
    return true;
  }

  validateOnSubmit() {
    this.insdataMessageService.clearMessage();
    if ( !this.selectedYear ) {
      this.errorCode = 1;
      this.insdataMessageService.showErrorMessage( 'Please select order year to upload cocode list.', false, 10000 );
      return false;
    }
    if ( this.showTab === 'csvCriteriaTab' && !this.file ) {
      this.errorCode = 4;
      this.insdataMessageService.showErrorMessage( 'Please upload a file before clicking submit.', false, 10000 );
      return false;
    }
    if ( this.showTab === 'stmtTypesCriteriaTab' && this.stmtTypeCriteriaForm.value.statementType === '' ) {
      this.errorCode = 5;
      this.insdataMessageService.showErrorMessage( 'Please select Order Year and Statement Type to proceed.', false, 10000 );
      return false;
    }
    return true;
  }

  removeFile() {
    this.fileInput.nativeElement.value = '';
    this.fileList = null;
    this.file = null;
    this.selectedFileName = '';
  }

  resetFileUpload() {
    this.removeFile();
    this.selectedYear = null;
    this.errorCode = 0;
    this.insdataMessageService.clearMessage();
  }

  fetchCocodeLists() {
    this.insdataOrderService.fetchCocodeLists( this.user.userId ).pipe( takeUntil( this.ngUnsubscribe ) ).subscribe( ( cocodeLists: CoCodeLists ) => {
      if ( cocodeLists ) {
        for ( const cocodeListItem of cocodeLists.cocodeLists ) {
          if ( !this.availableYears.includes( cocodeListItem.filingYear ) ) {
            this.availableYears.push( cocodeListItem.filingYear );
          }
        }
        this.selectedYearToViewHistory = this.availableYears[0];
        if ( this.selectedYearToViewHistory ) {
          this.fetchCocodeListsForYear();
          this.fetchingCocodeLists = false;
        } else {
          this.fetchingCocodeLists = false;
        }
      }
    }, () => {
      this.insdataMessageService.showErrorMessage( 'Unable to retrieve cocode upload history.', false );
      this.fetchingCocodeLists = false;
    } );
  }

  fetchCocodeListByPageNumber( pageNumber?: number ) {
    if ( pageNumber ) {
      this.currentPage = pageNumber;
    }
    this.fetchCocodeListsForYear();
  }

  fetchCocodeListsForYear( year?: number ) {
    let yearToView = this.selectedYearToViewHistory;
    if ( year ) {
      yearToView = year;
    }
    this.loadingSingleListItem = true;
    this.insdataOrderService.fetchCocodeList( this.user.userId, yearToView, null , ( this.currentPage - 1 ) * this.rowsPerPage,
      this.rowsPerPage, ( this.currentPageViewHistory - 1 ) * this.rowsPerPageViewHistory, this.rowsPerPageViewHistory ).pipe(
            take( 1 ) ).subscribe( ( cocodeLists: CoCodeListsDetail ) => {
      this.cocodeListForSelectedYear = cocodeLists;
      this.fetchingCocodeLists = false;
      this.loadingSingleListItem = false;
      this.totalResult = this.cocodeListForSelectedYear ? this.cocodeListForSelectedYear.pageSearchResults.numItemsMatched : 0;
      this.totalResultViewHistory = this.cocodeListForSelectedYear ? this.cocodeListForSelectedYear.histPageSearchResults.numItemsMatched : 0;
    }, () => {
      this.insdataMessageService.clearMessage();
      const errMsg = `Upload history not available for ${this.selectedYearToViewHistory} since file upload failed. Please check email, correct errors and re-upload file.`;
      this.insdataMessageService.showErrorMessage( errMsg );
      this.availableYears = [];
      this.fetchCocodeLists();
    } );
  }

  showCreateBulkOrderPage() {
    this.selectedYearToCreateBulkOrder = this.availableYears[0];
    this.fetchBulkOrderForYear();
    this.onCreateBulkOrderPage = true;
  }

  fetchBulkOrderForYear() {
    this.fetchingOrders = true;
    this.insdataOrderService.fetchOrders( 'BULK' ).subscribe( ( data: OrdersSearchResult ) => {
      if ( data && data.orders ) {
        this.userOrders = data.orders;

        // When there is no order for filing year
        if ( this.userOrders.filter( ( order ) => order.filingYear === this.selectedYearToCreateBulkOrder ).length === 0 ) {
          this.disableChangesOnly = true;
        } else {
          // Latest order's reset status
          this.disableChangesOnly = this.sortOrders( this.userOrders
            .filter( ( order ) => order.filingYear === this.selectedYearToCreateBulkOrder ) )
            .splice( 0, 1 )[0].cocodeListResetStatus;
        }
        for ( const item of data.orders ) {
          if ( item.orderStatus === 'pending' ) {
            this.pendingBulkOrderExists = true;
            break;
          }
        }
      }
      this.fetchingOrders = false;
    } );
  }

  createBulkOrderValidation() {
    if ( this.selectedBulkOrderType != null ) {
      this.createBulkOrder();
    } else {
      this.insdataMessageService.showErrorMessage( 'Please specify Order type.', false );
    }
  }

  createBulkOrder() {
    this.bulkOrderProcessingModal.show();
    // Fill out the bulk order details here when creating the bulk order. Year is the one obtained from UI.
    this.bulkOrderInput.filingYear = this.selectedYearToCreateBulkOrder;
    this.orderBaseDetails.userId = this.user.userId;

    // Join both the base order details and bulk order details here.
    const bulkOrderInputBody = {
      ...this.orderBaseDetails,
      ...this.bulkOrderInput,
    };
    bulkOrderInputBody.bulkOrderType = this.selectedBulkOrderType;
    this.insdataOrderService.addOrder( bulkOrderInputBody as BulkOrderInput ).subscribe( () => {
      this.insdataMessageService.showInformationalMessage( 'An Order is created, and we will email when the order is ready to be downloaded.', false );
      this.onCreateBulkOrderPage = false;
      this.bulkOrderProcessingModal.hide();
      this.fetchBulkOrderForYear();
    }, () => {
      this.insdataMessageService.showErrorMessage( 'Something went wrong. Please try again later or contact service desk.', false );
      this.bulkOrderProcessingModal.hide();
    } );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  sortOrders( orders: Array<OrderBaseData> ): Array<OrderBaseData> {
    return orders.sort( function( a, b ) {
      return ( new Date( b.purchaseDate ).getTime() - new Date( a.purchaseDate ).getTime() );
    } );
  }

  calcTotalPagesForViewHistory(): number {
    return Math.ceil( this.cocodeListForSelectedYear.histPageSearchResults.numItemsMatched / this.rowsPerPageViewHistory );
  }

  calcCocodeTotalPages(): number {
    return Math.ceil( this.cocodeListForSelectedYear.pageSearchResults.numItemsMatched / this.rowsPerPage );
  }

  calcStartNumber(): number {
    return ( this.rowsPerPage * ( this.currentPage - 1 ) ) + 1;
  }

  calcEndNumber(): number {
    return this.currentPage === this.calcCocodeTotalPages() ? this.cocodeListForSelectedYear.pageSearchResults.numItemsMatched : this.calcStartNumber() + ( this.rowsPerPage - 1 );
  }

  calcStartNumberForViewHistory(): number {
    return ( this.rowsPerPageViewHistory * ( this.currentPageViewHistory - 1 ) ) + 1;
  }

  calcEndNumberForViewHistory(): number {
    return this.currentPageViewHistory === this.calcTotalPagesForViewHistory() ?
      this.cocodeListForSelectedYear.histPageSearchResults.numItemsMatched : this.calcStartNumberForViewHistory() + ( this.rowsPerPageViewHistory - 1 );

  }

  navigateFromPaginationForViewHistory( newPage: number ): void {
    if ( !newPage ) {
      return; // Do nothing if user clicks on current page
    }
    this.currentPageViewHistory = newPage;
    this.fetchCocodeListsForYear();
    this.router.navigate( [this.cocodeUploadBaseUrl, {...this.params, page: newPage, selectedYear: this.selectedYearToViewHistory, showViewHistoryOnPageLoad: true}] )
      .then();	// Change URL
  }

  navigateCocodeFromPagination( newPage: number ): void {
    if ( !newPage ) {
      return; // Do nothing if user clicks on current page
    }
    this.currentPage = newPage;
    this.filterCocodes( this.selectedFilterStatus );
    this.router.navigate( [this.cocodeUploadBaseUrl, {...this.params, page: newPage, selectedYear: this.mergedCocodeList}] ).then();	// Change URL
  }

  buildPageLinksForViewHistory(): any[][] {
    const paginationPageNumbers: number[] = _.range(
      _.max( [1, this.currentPageViewHistory - this.paginationVariance] ),
      _.min( [this.calcTotalPagesForViewHistory(), this.currentPageViewHistory + this.paginationVariance] ) + 1
    );
    return paginationPageNumbers
      .map( ( thisPage: number ): any[] =>
        [this.cocodeUploadBaseUrl, {...this.params, page: thisPage, selectedYear: this.selectedYearToViewHistory, showViewHistoryOnPageLoad: true}] );
  }

  buildCocodePageLinks(): any[][] {
    const paginationPageNumbers: number[] = _.range(
      _.max( [1, this.currentPage - this.paginationVariance] ),
      _.min( [this.calcCocodeTotalPages(), this.currentPage + this.paginationVariance] ) + 1
    );
    return paginationPageNumbers.map( ( thisPage: number ): any[] => [this.cocodeUploadBaseUrl, {...this.params, page: thisPage, selectedYear: this.selectedYearToViewHistory}] );
  }

  resultsHavePagination(): boolean {
    // Update this to account for any result sets that don't have pagination
    return true;
  }

  downloadCocodeList() {
    this.insdataOrderService.downloadCocodeListExport( this.user.userId, this.selectedYearToViewHistory ).pipe( takeUntil( this.ngUnsubscribe ) ).subscribe( ( link ) => {
      const anchor = document.createElement( 'a' );
      anchor.href = link;
      anchor.click();
      this.insdataMessageService.clearMessage();
    }, () => {
      this.insdataMessageService.showErrorMessage( 'An error occurred when trying to download the cocode list.', false, 10000 );
    } );
  }

  confirmDeletionOfCocodes() {
    // First fetch orders for the order year and see if there are any pending bulk orders.
    this.insdataOrderService.fetchOrders( 'BULK' ).pipe( take( 1 ) ).subscribe( ( orderResult ) => {
      const x = _.filter( orderResult.orders, ( o ) => {
        return o.orderStatus === 'pending';
      } );
      // If there are, exit the function and display message that deletion is not allowed.
      if ( x && x.length > 0 ) {
        this.pendingBulkOrderExists = true;
        this.cocodeDeleteConfirmModal.show();
        return;
      }
      // Fetch cocode lists to see if there are any lists still in loading process.
      this.insdataOrderService.fetchCocodeList( this.user.userId, this.selectedYearToViewHistory ).pipe(
        take( 1 ) ).subscribe( ( lists ) => {
          const y = _.filter( lists.cocodeLists, ( o ) => {
            return o.loadInProgress === true;
          } );
          // If there are, exit this function and display message that deletion is not allowed.
          if ( y && y.length > 0 ) {
            this.listLoadOrOrderInProgress = true;
            this.cocodeDeleteConfirmModal.show();
            return;
          }
        // If there are none loading in process, then check if there is at least one cocode with pending, available or nofiling status.
        this.verifyIfCocodesAvailableToDelete( lists );
      }, () => {
          console.error( 'Something went wrong fetching cocode lists' );
        } );

    }, () => {
      console.error( 'Something went wrong fetching orders' );
    } );
  }

  closeConfirmModal() {
    this.cocodeDeleteConfirmModal.hide();
  }

  deleteCocodes() {
    this.isDeletingCocodes = true;
    this.insdataOrderService.deleteCocodeList( this.user.userId, this.selectedYearToViewHistory ).subscribe( () => {
      // No requirement defined for success scenario.
    }, ( e ) => {
      console.error( `Something went wrong deleting cocode list. ${e}` );
    }, () => {
      this.availableYears = [];
      this.fetchCocodeLists();
      this.fetchCocodeListsForYear( this.selectedYearToViewHistory );
      this.cocodeDeleteConfirmModal.hide();
      this.isDeletingCocodes = false;
    } );
  }

  showCSVCriteriaPage() {
    this.showTab = 'csvCriteriaTab';
  }

  showStmtTypeCriteriaPage() {
    this.resetStmtTypeCriteriaForm();
    this.showTab = 'stmtTypesCriteriaTab';
    this.stmtTypeCriteriaFormInit();
  }

  addByStmtType() {
    if ( this.validateOnSubmit() ) {
      this.insdataMessageService.showWarningMessage( 'Adding cocodes for selected Statement Type...' );
      this.insdataOrderService.createCocodeListByStatementType( this.user.userId,
        this.stmtTypeCriteriaForm.value.orderYear,
        this.stmtTypeCriteriaForm.value.statementType ).pipe( take( 1 ) ).subscribe( () => {
        this.insdataMessageService.clearMessage();
        this.insdataMessageService.showInformationalMessage(
          'We are working on adding the requested cocodes to your list. An email confirmation will be sent once completed.', false, 5000 );
        this.stmtTypeCriteriaFormInit();
      }, () => {
        this.insdataMessageService.showErrorMessage( 'Something went wrong. Please try again later.', false, 5000 );
      } );
    }
  }

  stmtTypeCriteriaFormInit() {
    const statementType = '';
    const currentYear = new Date().getFullYear();
    this.errorCode = 0;
    if ( new Date().getMonth() > 2 ) {
       this.selectedYear = currentYear;
    } else {
      this.selectedYear = currentYear - 1;
    }
    this.stmtTypeCriteriaForm = new FormGroup( {
      'orderYear': new FormControl( this.selectedYear, Validators.required ),
      'statementType': new FormControl( statementType, Validators.required ),
    } );
  }

  filterCocodes( filter: StatusEnum ) {
    this.selectedFilterStatus = filter;
    this.insdataOrderService.fetchCocodeList( this.user.userId, this.selectedYearToViewHistory, filter, ( this.currentPage - 1 ) * this.rowsPerPage,
      this.rowsPerPage, ( this.currentPageViewHistory - 1 ) * this.rowsPerPageViewHistory, this.rowsPerPageViewHistory ).pipe(
      take( 1 ) ).subscribe( ( cocodeLists: CoCodeListsDetail ) => {
      this.cocodeListForSelectedYear = cocodeLists;
      this.totalResult = this.cocodeListForSelectedYear ? this.cocodeListForSelectedYear.pageSearchResults.numItemsMatched : 0;
    }, () => {
      this.insdataMessageService.clearMessage();
      const errMsg = `Error retrieving ${this.selectedYearToViewHistory} cocode with ${this.selectedFilterStatus} status.`;
      this.insdataMessageService.showErrorMessage( errMsg );
      this.availableYears = [];
      this.fetchCocodeLists();
    } );
  }

  showViewHistoryPage() {
      this.onViewHistoryPage = true;
      this.currentPageViewHistory = 1;
  }

  exitOnViewHistoryPage() {
    this.onViewHistoryPage = false;
  }

  deleteCocode( cocode: number ) {
    this.deletingSingleCocodeModal.show();
    this.insdataOrderService.deleteCocode(
      this.user.userId, this.selectedYearToViewHistory, cocode )
      .pipe( take( 1 ) ).subscribe( ( cocodeLoadOrRemoveResult: CocodeLoadOrRemoveResult ) => {
      this.deletingSingleCocodeModal.hide();
      if ( cocodeLoadOrRemoveResult.success ) {
        this.insdataMessageService.showInformationalMessage( `Cocode ${cocode} successfully removed from ${this.selectedYearToViewHistory} cocode list` );
        this.fetchCocodeLists();
      } else {
        for ( const errorMessage of cocodeLoadOrRemoveResult.errorMessages ) {
          this.insdataMessageService.showErrorMessage( errorMessage );
        }
      }
    }, ( e ) => {
      this.deletingSingleCocodeModal.hide();
      for ( const errorMessage of e.error.errorMessages ) {
        this.insdataMessageService.showErrorMessage( errorMessage.replace( 'org.naic.fs.insdata.common.svc.BusinessException:', '' ) );
      }
    } );
  }

  private verifyIfCocodesAvailableToDelete( lists: CoCodeListsDetail ) {
    for ( const cocodeList of lists.cocodeLists ) {
      const p = _.some( cocodeList.cocodeSet, {'status': 'available'} );
      const q = _.some( cocodeList.cocodeSet, {'status': 'pending'} );
      const r = _.some( cocodeList.cocodeSet, {'status': 'nofiling'} );
      // Display message as per the results obtained.
      if ( p || q || r ) {
        this.unusedCocodeCount = 0;
        const a = _.countBy( cocodeList.cocodeSet, {'status': 'available'} );
        const b = _.countBy( cocodeList.cocodeSet, {'status': 'pending'} );
        const c = _.countBy( cocodeList.cocodeSet, {'status': 'nofiling'} );
        if ( a.true ) {
          this.unusedCocodeCount += a.true;
        }
        if ( b.true ) {
          this.unusedCocodeCount += b.true;
        }
        if ( c.true ) {
          this.unusedCocodeCount += c.true;
        }
        this.cocodesAvailableToDelete = true;
        this.cocodeDeleteConfirmModal.show();
        break;
      } else {
        this.cocodesAvailableToDelete = false;
        this.cocodeDeleteConfirmModal.show();
      }
    }
  }

  private resetStmtTypeCriteriaForm() {
    this.insdataMessageService.clearMessage();
    this.errorCode = 0;
    this.stmtTypeCriteriaFormInit();
  }
}
