/*
 * Copyright 2024 National Association of Insurance Commissioners
 */

import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { createMask } from '@ngneat/input-mask';
import { take } from 'rxjs/operators';
import { PublicationsCheckoutService } from '../../../api/publicationsCheckout.service';
import { StripeToken } from '../../../model/billing/stripeToken';
import { User } from '../../../model/customer/user';
import { PaymentRemittance } from '../../../model/publications/paymentRemittance';
import { PubCart } from '../../../model/publications/pubCart';
import { PubCartItem } from '../../../model/publications/pubCartItem';
import { InsdataCustomerService } from '../../../service/insdata-customer.service';
import { InsdataLoadingSpinnerService } from '../../../service/insdata-loading-spinner.service';
import { InsdataMessageService } from '../../../service/insdata-message.service';
import { noPoBoxValidator } from '../../../shared/validators/no-po-box.directive';
import { oneOfValidator } from '../../../shared/validators/one-of.directive';
import { PubsService } from '../pubs.service';

@Component( {
  selector: 'app-publications-checkout',
  templateUrl: './publications-checkout.component.html',
  styleUrls: [ './publications-checkout.component.scss' ],
} )
export class PublicationsCheckoutComponent implements OnInit, AfterViewInit {
  static submitDisabled = true;

  get isSubmitDisabled(): boolean {
    return PublicationsCheckoutComponent.submitDisabled;
  }

  orderId: string;
  paymentIntent: StripeToken;
  elements: any;
  card: any;
  stripe: any;
  loading = false;


  customerInformationFormGroup: FormGroup;
  cart: PubCart;
  user: User;
  states = [
    'Alabama',
    'Alaska',
    'American Samoa',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'District of Columbia',
    'Florida',
    'Georgia',
    'Guam',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Northern Mariana Islands',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Puerto Rico',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'U.S. Virgin Islands',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming',
  ];

  phoneInputMask = createMask( '(999) 999-9999' );
  postalCodeInputMask = createMask( '99999' );

  get totalDue(): number {
    let total = 0;
    this.cart.items.forEach( ( item: PubCartItem ) => {
      total = total + ( item.quantity * item.publication.price );
    } );
    return total;
  }

  get companyName(): AbstractControl {
    return this.customerInformationFormGroup.get( 'companyName' );
  }

  get streetAddress(): AbstractControl {
    return this.customerInformationFormGroup.get( 'streetAddress' );
  }

  get city(): AbstractControl {
    return this.customerInformationFormGroup.get( 'city' );
  }

  get state(): AbstractControl {
    return this.customerInformationFormGroup.get( 'state' );
  }

  get postalCode(): AbstractControl {
    return this.customerInformationFormGroup.get( 'postalCode' );
  }

  get phoneNumber(): AbstractControl {
    return this.customerInformationFormGroup.get( ( 'phoneNumber' ) );
  }

  constructor(
    private readonly formBuilder: FormBuilder,
    private pubsService: PubsService,
    private customerService: InsdataCustomerService,
    private spinnerService: InsdataLoadingSpinnerService,
    private publicationsCheckoutService: PublicationsCheckoutService,
    private messageService: InsdataMessageService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.customerInformationFormGroup = this.formBuilder.group( {
      companyName: [ '', Validators.maxLength( 400 ) ],
      streetAddress: [ '', [ Validators.maxLength( 50 ), noPoBoxValidator() ] ],
      city: [ '', Validators.maxLength( 20 ) ],
      state: [ '', [ oneOfValidator( this.states ) ] ],
      postalCode: [ '', [ Validators.maxLength( 5 ), Validators.pattern( '^[0-9]*$' ) ] ],
      phoneNumber: [ '' ],
    } );

    this.getCurrentUser();
    this.pubsService.pubCart$.subscribe( ( cart: PubCart ) => {
      this.cart = cart;
    } );
  }

  ngAfterViewInit(): void {

    // Get a payment intent with the passed in token.
    this.publicationsCheckoutService.generatePaymentIntent( this.user.userId ).pipe( take( 1 ) ).subscribe( ( token ) => {
      this.loading = false;
      // If a valid paymentIntent is obtained, show the card elements on the UI.
      if ( token ) {
        this.paymentIntent = token;
        this.stripe = Stripe( this.paymentIntent.publicAccessKey );
        this.elements = this.stripe.elements();
        this.card = this.stripe.elements().create( 'card' );
        this.card.mount( '#pub-card-element' );
      }

      // Upon changing any values on the card, stripe automatically displays error message because of this code.
      this.card.on( 'change', function ( event ) {
        document.querySelector( '#card-error' ).textContent = event.error ? event.error.message : '';
        PublicationsCheckoutComponent.submitDisabled = !!event.error;
      } );
    }, () => {
      console.error( 'Something went wrong fetching a Payment Intent.' );
    } );
  }

  completeOrder(): void {
    this.spinnerService.showLoadingSpinner();
    this.stripe.confirmCardPayment( this.paymentIntent.clientSecretToken, {
      payment_method: {
        card: this.card,
      },
    } ).then( ( result: any ) => {
      if ( !result.error ) {
        const paymentRemittance: PaymentRemittance = {
          paymentIntentId: this.paymentIntent.paymentIntentId,
        };
        paymentRemittance.companyName = this.companyName?.value;
        paymentRemittance.streetAddress = this.streetAddress?.value;
        paymentRemittance.city = this.city?.value;
        paymentRemittance.state = this.state?.value;
        paymentRemittance.postalCode = this.postalCode?.value;
        paymentRemittance.phoneNumber = this.phoneNumber?.value !== null ? this.phoneNumber.value.toString().replace( /\D/g, '' ) : null;
        this.publicationsCheckoutService.payForCart( this.user.userId, paymentRemittance )
          .pipe( take( 1 ) )
          .subscribe( ( pubCart ) => {
            this.paymentIntent = null;
            this.pubsService.pubCart$.next( pubCart );
            this.spinnerService.hideLoadingSpinner();
            this.messageService.showInformationalMessage( 'Your Purchase was successful.', false, 5000 );
            this.router.navigate( [ '/home/publications/purchased' ] );
          }, () => {
            this.spinnerService.hideLoadingSpinner();
            this.messageService.showErrorMessage( 'There was an error processing your purchase.', false, 5000 );
          } );
      } else {
        this.spinnerService.hideLoadingSpinner();
        this.messageService.showErrorMessage( result.error.message, false, 10000 );
      }
    } );
  }

  getCurrentUser(): void {
    this.customerService
      .getCurrentUser()
      .pipe( take( 1 ) )
      .subscribe(
        ( data ) => {
          this.user = data;
        },
        () => {
          console.error( 'Something went wrong fetching user details using "getCurrentUser()" call.' );
        }
      );
  }

  isErrorDisplayed( control: AbstractControl ): boolean {
    return control.invalid && ( control.touched || control.dirty );
  }
}
