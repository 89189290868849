/**
 * NAIC InsData Cart Application Service API
 * NAIC InsData Cart oriented application service API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: InsDataStaff@naic.org
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Cart } from '../model/cart/cart';
import { ProductDataInput } from '../model/common/productDataInput';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class CartService {

  protected basePath = 'https://localhost:8447';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (let consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }


  /**
   * Empty user&#39;s cart
   * Empty user&#39;s cart
   * @param userId The user UUID for the cart that needs to be emptied.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public emptyCartForUser(userId: string, observe?: 'body', reportProgress?: boolean): Observable<Cart>;
  public emptyCartForUser(userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Cart>>;
  public emptyCartForUser(userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Cart>>;
  public emptyCartForUser(userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    if (userId === null || userId === undefined) {
      throw new Error('Required parameter userId was null or undefined when calling emptyCartForUser.');
    }

    let headers = this.defaultHeaders;

    // authentication (OAuth2) required
    if (this.configuration.accessToken) {
      let accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = [
    ];

    return this.httpClient.delete<Cart>(`${this.basePath}/cart/${encodeURIComponent(String(userId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get cart for userId
   * Get any cart contents for a specific user
   * @param userId The user UUID for the cart that needs to be fetched.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public fetchCartForUser(userId: string, observe?: 'body', reportProgress?: boolean): Observable<Cart>;
  public fetchCartForUser(userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Cart>>;
  public fetchCartForUser(userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Cart>>;
  public fetchCartForUser(userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    if (userId === null || userId === undefined) {
      throw new Error('Required parameter userId was null or undefined when calling fetchCartForUser.');
    }

    let headers = this.defaultHeaders;

    // authentication (OAuth2) required
    if (this.configuration.accessToken) {
      let accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = [
    ];

    return this.httpClient.get<Cart>(`${this.basePath}/cart/${encodeURIComponent(String(userId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Save a product to the users cart
   * Save product to the users cart
   * @param userId User UUID for the cart
   * @param body Product to add to the cart
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public postProduct(userId: string, body: ProductDataInput, observe?: 'body', reportProgress?: boolean): Observable<Cart>;
  public postProduct(userId: string, body: ProductDataInput, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Cart>>;
  public postProduct(userId: string, body: ProductDataInput, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Cart>>;
  public postProduct(userId: string, body: ProductDataInput, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    if (userId === null || userId === undefined) {
      throw new Error('Required parameter userId was null or undefined when calling postProduct.');
    }
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling postProduct.');
    }

    let headers = this.defaultHeaders;

    // authentication (OAuth2) required
    if (this.configuration.accessToken) {
      let accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = [
    ];
    let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.post<Cart>(`${this.basePath}/cart/${encodeURIComponent(String(userId))}/addProduct/`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Remove an item from the cart
   *
   * @param userId User UUID for user&#39;s cart to remove item from
   * @param itemId Item UUID for item to remove from the cart
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public removeItemFromCart(userId: string, itemId: string, observe?: 'body', reportProgress?: boolean): Observable<Cart>;
  public removeItemFromCart(userId: string, itemId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Cart>>;
  public removeItemFromCart(userId: string, itemId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Cart>>;
  public removeItemFromCart(userId: string, itemId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    if (userId === null || userId === undefined) {
      throw new Error('Required parameter userId was null or undefined when calling removeItemFromCart.');
    }
    if (itemId === null || itemId === undefined) {
      throw new Error('Required parameter itemId was null or undefined when calling removeItemFromCart.');
    }

    let headers = this.defaultHeaders;

    // authentication (OAuth2) required
    if (this.configuration.accessToken) {
      let accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = [
    ];

    return this.httpClient.delete<Cart>(`${this.basePath}/cart/${encodeURIComponent(String(userId))}/removeItem/${encodeURIComponent(String(itemId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
