/*!
 * Copyright 2021 National Association of Insurance Commissioners
 */

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Cart} from '../model/cart/cart';
import {InsdataShoppingCartService} from './insdata-shopping-cart.service';

@Injectable()
export class InsdataCheckoutResolver implements Resolve<Cart> {

  constructor( private readonly shoppingCartService: InsdataShoppingCartService ) {
  }

  resolve( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Observable<Cart> | Promise<Cart> | Cart {
    return this.shoppingCartService.refreshCart().pipe( map( ( cart ) => {
      return cart;
    } ) );
  }
}
