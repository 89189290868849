/*!
 * Copyright 2021 National Association of Insurance Commissioners
 */

import {of as observableOf, Observable} from 'rxjs';

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {map, switchMap} from 'rxjs/operators';
import {InsdataCustomerService} from '../service/insdata-customer.service';
import {InsdataMessageService} from '../service/insdata-message.service';
import {OidcAuthService} from '../service/oidc-auth.service';

@Injectable()
export class UserGuard implements CanActivate {

  constructor( private readonly oidcAuthService: OidcAuthService,
              private readonly insdataCustomerService: InsdataCustomerService,
              private readonly insdataMessageService: InsdataMessageService ) {
  }

  canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Observable<boolean> | Promise<boolean> | boolean {
    return this.oidcAuthService.isLoggedInObs().pipe( switchMap( ( data ) => {
      if ( data ) {
        return this.insdataCustomerService.isCurrentUserAllowedForIndustry().pipe( map( ( result ) => {
          if ( !result ) {
            this.insdataMessageService.showErrorMessage( 'You are not authorized to access this site' );
          }
          return result;
        } ) );
      } else {
        return observableOf( false );
      }
    } ) );
  }
}
