/*!
 * Copyright 2021 National Association of Insurance Commissioners
 */

export const environment = {
  production: true,
  envName: 'prod',
  authUrl: 'https://login.common.naic.org/nidp',
  redirectBaseUrl: 'https://insdata.naic.org',
  apiUrl: 'https://insdata-services.naic.org',
  clientId: '3e6c98a4-36b5-44e1-a07e-c1a581786a45',
  loginContract: '/authz?acr_values=naic/secure/name/password/uri',
  userSignUpUrl: 'https://sspr.common.naic.org/sspr/public/newuser?forwardURL=',
  companyServiceUrl: 'https://services.naic.org/insdata-company-service',
  customerServiceUrl: 'https://customers.insdata.naic.org',
  sessionTimeOut: 1800000,
};
