/*
 * Copyright 2024 National Association of Insurance Commissioners
 */

import { Injectable } from '@angular/core';
import { CanDeactivate, UrlTree } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';
import { PurchasedComponent } from '../public/publications/purchased/purchased.component';

@Injectable( {
  providedIn: 'root',
} )
export class PurchasedCanDeactivateGuard implements CanDeactivate<PurchasedComponent> {

  constructor( private modalService: BsModalService ) {
  }

  canDeactivate(
    component: PurchasedComponent ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if ( !component.downloadModalRef ) {
      return true;
    }

    const subject: Subject<boolean> = new Subject<boolean>();

    component.deactivateModalRef = this.modalService.show( component.deactivateModalTemplate, { keyboard: false, ignoreBackdropClick: true } );
    component.deactivateSubject = subject;

    return subject.asObservable();
  }

}
