<div class="company-profile" *ngIf="company">
  <a class="mb-2 d-inline-block" (click)="goBack()" tabindex>
    <span class="fa fa-chevron-left"></span>
    Return to Search Results
  </a>
  <div class="card">
    <div class="card-header">
      <h3>{{company.companyName}}</h3>
    </div>
    <div class="card-body">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6 col-sm-2 d-inline-flex">
            <label><strong>Address:</strong></label>
            <div class="col">
              <div id="companyAddress1">
                {{company.streetAddress1}}<br/>
                <p *ngIf="company.streetAddress2">{{company.streetAddress2}}<br/></p>
                {{company.city}}, {{company.state}} {{company.postalCode}}
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-2 d-inline-flex">
            <label><strong>State of Domicile:</strong></label>
            <div class="col">
              {{company.stateOfDomicile}}
            </div>
          </div>
        </div>
        <br/>
        <div class="row">
          <div class="col-md-6 col-sm-2 d-inline-flex">
            <label><strong>CoCode:</strong></label>
            <div class="col">
              {{company.companyCoCode}}
            </div>
          </div>
          <div class="col d-inline-flex">
            <label><strong>Statement Types:</strong></label>
            <div class="col">
              <div *ngFor="let compStatementType of company.statementTypes">
                {{compStatementType.value}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <br/>
      <div *ngIf="loadingProducts">
        <p>Loading Products... <span class="fa fa-spinner fa-spin fa-lg"></span></p>
      </div>

      <div id="accordion" role="tablist" *ngIf="productDataKeys">
        <div class="card company-detail" *ngFor="let productDataMap of productDatakeyWrappers">
          <div class="card-header card-header-clickable" role="tab" [id]="productDataMap.title | trim"
               data-toggle="collapse" [attr.data-target]="'#collapse' + (productDataMap.title | trim)"
               (click)="showProductData(productDataMap)">
            <h5 class="mb-0">
              <a data-toggle="collapse"
                 class="collapsed"
                 href="#collapse{{productDataMap.title | trim}}"
                 aria-expanded="false"
                 attr.aria-controls="collapse{{productDataMap.title | trim}}">
                {{productDataMap.title}}<span
                class="fa fa-caret-up float-right"></span><span class="fa fa-caret-down float-right"
                                                                title="See More"></span>
              </a>
            </h5>
            <p class="float-right" *ngIf="productDataMap.shouldShowAddToCocodeButton && !productDataMap.isCocodeOnUsersCocodeList"><button type="button" class="btn btn-sm btn-primary" [disabled]="!productDataMap.isAddToCocodeEnabled" (click)="addCocodeToCocodeList(productDataMap)">Add to {{productDataMap.title.substring(0, 4)}} cocode list</button></p>
            <p class="float-right" *ngIf="productDataMap.shouldShowAddToCocodeButton && productDataMap.isCocodeOnUsersCocodeList"><span class="fa fa-check-circle icon-green"></span>&nbsp;Added to {{productDataMap.title.substring(0, 4)}} Cocode List</p>
          </div>
          <div id="collapse{{productDataMap.title | trim}}" class="collapse" role="tabpanel"
               attr.aria-labelledby="{{productDataMap.title | trim}}" data-parent="#accordion">
            <div *ngIf="loadingActiveProductDatas"><p><span class="fa fa-spinner fa-spin fa-lg"></span></p></div>
            <div class="alert alert-warning"
                 *ngIf="!loadingActiveProductDatas  && (!productDataMap.productDatas || productDataMap.productDatas.length === 0) && !productDataMap.error">
              No Products for this Filing Period
            </div>
            <div class="alert alert-danger"
                 *ngIf="!loadingActiveProductDatas  && (!productDataMap.productDatas || productDataMap.productDatas.length === 0) && productDataMap.error">
              An error occurred when fetching products for this Filing Period
            </div>
            <div *ngIf="productDataMap.productDatas && productDataMap.productDatas.length > 0">
              <div class="card-body" *ngFor="let productData of productDataMap.productDatas">
                <div class="card">
                  <div class="card-body">
                    <div class="row justify-content-between">
                      <div class="col-lg-4"
                           [ngClass]="{'col-lg-4': productData.sellable , 'col-lg-8': !productData.sellable}">
                        <p *ngIf="productData.productDataKey.submissionFilingPeriod !== 'Annual'">
                          <strong>{{productData.productDataKey.statementYear}} {{productData.productDataKey.submissionFilingPeriod}} Filings</strong>
                          {{!productData.sellable ? ' - This information is unavailable. Visit ‘Help’ for more details.' : ''}}
                        </p>
                        <p
                          *ngIf="productData.productDataKey.submissionFilingPeriod === 'Annual' && productData.productDataKey.isKey">
                          <strong>{{productData.productDataKey.statementYear}} Key {{productData.productDataKey.submissionFilingPeriod}} Filings
                          </strong> {{!productData.sellable ? ' - This information is unavailable. Visit ‘Help’ for more details.' : ''}}
                        </p>
                        <p
                          *ngIf="productData.productDataKey.submissionFilingPeriod === 'Annual' && !productData.productDataKey.isKey">
                          <strong>{{productData.productDataKey.statementYear}} Non-Key {{productData.productDataKey.submissionFilingPeriod}} Filings
                          </strong> {{!productData.sellable ? ' - This information is unavailable. Visit ‘Help’ for more details.' : ''}}
                        </p>
                      </div>
                      <div class="col-lg-3">
                        <div class="float-lg-right" *ngIf="productData.sellable && (userRole !== 'ROLE_SPECIAL' && userRole !== 'ROLE_ALL_ACCESS')">
                          {{productData.priceUsd | currency:'USD':true:'1.2-2'}}
                          <a *ngIf="productsInTransition.indexOf(productData) === -1 && doNotCharge"
                             (click)="doNotChargeDownloadFile(productData)" title="Download Product"><span
                            class="fa fa-2x fa-download"></span></a>
                          <a
                            *ngIf="!hasProductInCart(productData) && productsInTransition.indexOf(productData) === -1 && !doNotCharge"
                            (click)="!isCartLimitMet() && addToCart(productData)"
                            [class.disabled]="isCartLimitMet()"><span
                            class="fa fa-2x fa-cart-plus" title="Add to Cart"></span></a>
                          <span *ngIf="productsInTransition.indexOf(productData) > -1"
                                class="fa fa-2x fa-spinner fa-spin"></span>
                          <a
                            *ngIf="hasProductInCart(productData) && productsInTransition.indexOf(productData) === -1 && !doNotCharge"
                            (click)="removeFromCart(productData)"><span
                            class="fa fa-2x fa-trash"
                            title="Remove Item from Cart"></span></a>
                        </div>
                        <div class="float-lg-right" *ngIf="!productData.sellable">
                        <span class="fa-stack"
                              title="This item cannot be added to your cart. See HELP for more details.">
                          <span class="fa fa-2x fa-cart-plus fa-stack-1x" title="Add to Cart"></span>
                          <span class="fa fa-2x fa-ban fa-stack-2x text-danger"></span>
                        </span>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <table class="table borderless company-detail" aria-describedby="companySearchResultsDetailTable">
                        <thead>
                        <tr>
                          <th scope="col" class="c-35">File Name</th>
                          <th scope="col" class="c-30">Statement Type</th>
                          <th scope="col" class="c-10">Version</th>
                          <th scope="col" class="c-15">Date Filed</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let statementFile of productData.statementFiles"
                            title="{{statementFile.infoNotSellable ? 'This item cannot be added to your cart. See HELP for more details.' : ''}}">
                          <td
                            [class.non-sellable-statement]="statementFile.infoNotSellable">{{statementFile.submissionFilingType}}</td>
                          <td
                            [class.non-sellable-statement]="statementFile.infoNotSellable">{{statementFile.statementType | titlecase}}</td>
                          <td
                            [class.non-sellable-statement]="statementFile.infoNotSellable">{{statementFile.submissionVersionType}}</td>
                          <td
                            [class.non-sellable-statement]="statementFile.infoNotSellable">{{statementFile.dateFiled | date:'MM/dd/yyyy'}}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div bsModal #loadingModal="bs-modal" class="modal" id="loadingModal" tabindex="-1" role="dialog"
       [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-sm" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title"><strong>{{loadingModalText}}</strong></h5>
        </div>
        <div class="modal-body">
          <span class="fa fa-spinner fa-spin fa-2x"></span>
        </div>
      </div>
    </div>
  </div>

  <div bsModal #cartIsFullModal="bs-modal" class="modal" id="cartIsFullModal" tabindex="-1" role="dialog"
       [config]="{backdrop: 'static'}">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title"><strong>Full Cart</strong></h5>
          <button type="button" class="close pull-right" aria-label="Close" (click)="cartIsFullModal.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          Your cart is full. Please complete this order.
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="continueToCheckout()" data-dismiss="modal">CONTINUE TO
            PAYMENT
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
