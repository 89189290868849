/*!
 * Copyright 2021 National Association of Insurance Commissioners
 */

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {InsdataCustomerService} from '../service/insdata-customer.service';

@Injectable()
export class BulkOrderManagerGuard implements CanActivate {
  constructor( private readonly insdataCustomerService: InsdataCustomerService ) {
  }

  canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Observable<boolean> | Promise<boolean> | boolean {
    let userRole;
    this.insdataCustomerService.getCurrentUserRole().subscribe( ( data ) => {
      userRole = data;
    } );
    return userRole === 'ROLE_ALL_ACCESS';
  }
}
