/*!
 * Copyright 2021 National Association of Insurance Commissioners
 */

import {Component, EventEmitter, HostBinding, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import * as _ from 'lodash';
import {CompaniesStatementsOrderBy} from '../../../model/company/companiesStatementsOrderBy';
import { CompanyData } from '../../../model/company/companyData';
import * as models from '../../../model/company/models';

@Component( {
  selector: 'app-company-search-results',
  templateUrl: './company-search-results.component.html',
  styleUrls: ['./company-search-results.component.scss'],
} )
export class CompanySearchResultsComponent implements OnInit, OnDestroy {
  @HostBinding( 'class.col' ) public style1 = true;
  @HostBinding( 'class.mt-3' ) public style2 = true;
  @HostBinding( 'class.mt-md-0' ) public style3 = true;

  @Output() selectedCompany: EventEmitter<CompanyData> = new EventEmitter<CompanyData>();
  @Output() newPage: EventEmitter<any> = new EventEmitter<any>();
  @Output() newSort: EventEmitter<CompaniesStatementsOrderBy> = new EventEmitter<CompaniesStatementsOrderBy>();

  currentPage = 0;
  smallnumPages = 0;
  params: any = {};
  companyResults: models.CompaniesStatementsSearchResult[] = [];
  companiesStatementsSearchResults: models.CompaniesStatementsSearchResults;
  companyRows: models.CompanyData[] = []; // Just the rows to show
  rowsPerPage = 10;	// Aarbitrary value (probably should be set by user and maybe default 100), many things adjust off of this.
  paginationVariance = 3;	// Ppagination shows, at most, this many pages away from current page
  public companiesStatementsOrderBy = CompaniesStatementsOrderBy; // For Scope
  sortField: CompaniesStatementsOrderBy;
  totalResult: number;

  private sub: any;

  constructor( private readonly router: Router,
              private readonly activatedRoute: ActivatedRoute ) {
  }

  ngOnInit() {
    this.sub = this.activatedRoute.params.subscribe( ( params: any ) => {
      this.params = params;
      this.currentPage = Number( this.params.page ) || 1;
      this.totalResult = this.companiesStatementsSearchResults ? this.companiesStatementsSearchResults.pageSearchResults.numItemsMatched : 0;
      this.companyResults = this.companiesStatementsSearchResults ? this.companiesStatementsSearchResults.companiesStatements : undefined;
      this.sortField = this.companiesStatementsSearchResults ? this.companiesStatementsSearchResults.orderedBy : undefined;
    } );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  resultsHavePagination(): boolean {
    // TODO: Update this to account for any resultsets that don't have pagination
    return true;
  }

  getCurrentRows(): models.CompaniesStatementsSearchResult[] {
    return this.companyResults;
  }

  calcTotalPages(): number {
    if ( !this.companiesStatementsSearchResults.pageSearchResults.numItemsMatched ) {
      console.error( 'no company results!' );  //  Shouldn't ever get here
    }

    return Math.ceil( this.companiesStatementsSearchResults.pageSearchResults.numItemsMatched / this.rowsPerPage );
  }

  calcStartNumber(): number {
    return ( this.rowsPerPage * ( this.currentPage - 1 ) ) + 1;
  }

  calcEndNumber(): number {
    return this.currentPage === this.calcTotalPages() ? this.companiesStatementsSearchResults.pageSearchResults.numItemsMatched : this.calcStartNumber() + ( this.rowsPerPage - 1 );
  }

  navigateFromPagination( newPage: number ): void {
    if ( !newPage ) {
      return; // Do nothing if user clicks on current page
    }
    this.newPage.emit( newPage );
    this.router.navigate( ['/home/companySearch', {...this.params, page: newPage}] );	// Cchange URL
  }

  buildPageLinks(): any[][] {
    const paginationPageNumbers: number[] = _.range(
      _.max( [1, this.currentPage - this.paginationVariance] ),
      _.min( [this.calcTotalPages(), this.currentPage + this.paginationVariance] ) + 1
    );
    return paginationPageNumbers.map( ( thisPage: number ): any[] => ['/home/companySearch', {...this.params, page: thisPage}] );
  }
  showCompanyDataDetail( company: CompanyData ) {
    this.selectedCompany.emit( company );
  }

  sort( newSortField: CompaniesStatementsOrderBy ) {
    this.sortField = newSortField;
    this.newSort.emit( this.sortField );
  }
}
