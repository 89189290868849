/*!
 * Copyright 2021 National Association of Insurance Commissioners
 */

import {Location} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import * as _ from 'lodash';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {Cart} from '../../../model/cart/cart';
import {ProductData} from '../../../model/common/productData';
import {CompanyData} from '../../../model/company/companyData';
import {ProductDataKey} from '../../../model/company/models';
import {BillingOption} from '../../../model/customer/billingOption';
import {User} from '../../../model/customer/user';
import {InsdataCompanySearchService} from '../../../service/insdata-company-search.service';
import {InsdataCustomerService} from '../../../service/insdata-customer.service';
import {InsdataMessageService} from '../../../service/insdata-message.service';
import {InsdataOrdersService} from '../../../service/insdata-orders.service';
import {InsdataShoppingCartService} from '../../../service/insdata-shopping-cart.service';

interface ProductDataWrapper {
  title: string;
  productDataKeys: Array<ProductDataKey>;
  productDatas?: Array<ProductData>;
  error?: any;
  shouldShowAddToCocodeButton?: boolean;
  isAddToCocodeEnabled?: boolean;
  isCocodeOnUsersCocodeList?: boolean;
}

@Component( {
  selector: 'app-company-search-detail',
  templateUrl: './company-search-detail.component.html',
  styleUrls: ['./company-search-detail.component.scss'],
} )
export class CompanySearchDetailComponent implements OnInit, OnDestroy {
  @HostBinding( 'class.col' ) public style1 = true;
  @HostBinding( 'class.mt-2' ) public style2 = true;
  @HostBinding( 'class.mt-md-0' ) public style3 = true;

  @ViewChild( 'cartIsFullModal', {static: false} ) cartIsFullModal: ModalDirective;
  @ViewChild( 'loadingModal', {static: false} ) loadingModal: ModalDirective;
  @Input() company: CompanyData;
  @Output() close = new EventEmitter();

  productDatakeyWrappers: Array<ProductDataWrapper>;

  productDataKeys = new Array<ProductDataKey>();
  cartIcon = 'cart-plus';
  map = new Map();
  cart: Cart;
  productsInTransition: Array<ProductData> = []; // Array for Products that are in the process of being added to or removed from cart or being downloaded as Do Not Charge user
  loadingProducts = false;
  loadingActiveProductDatas = false;
  doNotCharge = false;
  currentUser: User;
  userRole: string;
  loadingModalText = '';

  private readonly ngUnsubscribe: Subject<any> = new Subject();
  // ProductDataKeys: ProductDataKeys;
  private readonly numberOfYearsToDisplay = 10;

  constructor( private readonly route: ActivatedRoute,
              private readonly http: HttpClient,
              private readonly location: Location,
              private readonly router: Router,
              private readonly shoppingCartService: InsdataShoppingCartService,
              private readonly companySearchService: InsdataCompanySearchService,
              private readonly insdataMessageService: InsdataMessageService,
              private readonly insdataCustomerService: InsdataCustomerService,
              private readonly insdataOrdersService: InsdataOrdersService ) {
  }

  ngOnInit() {
    this.productDatakeyWrappers = [];
    this.cart = this.shoppingCartService.cart;
    this.shoppingCartService.cartChange.pipe( takeUntil( this.ngUnsubscribe ) ).subscribe( ( cart ) => this.cart = cart );
    if ( this.company ) {
      this.loadingProducts = true;
      this.initializeProductDataKeys();
      this.loadingProducts = false;

      // Fetch current year which would be the first item in the productDataKeys
      const firstProductDataWrapper = this.productDatakeyWrappers[0];
      this.companySearchService.fetchProductDatasForProductDataKeys( {keys: firstProductDataWrapper.productDataKeys} )
        .pipe( takeUntil( this.ngUnsubscribe ) ).subscribe( ( productDatas: Array<ProductData> ) => {
        firstProductDataWrapper.productDatas = this.sortProductDatas( productDatas );
        const convertSecondsToMilliseconds = 1000;
        _.forEach( firstProductDataWrapper.productDatas, function ( product ) {
          _.forEach( product.statementFiles, function ( statementFile ) {
            statementFile.dateFiled = ( statementFile.dateFiled * convertSecondsToMilliseconds ) + 21600000;
          } );
        } );
      }, ( err ) => {
        firstProductDataWrapper.error = err;
      } );
      this.insdataCustomerService.getCurrentUser().pipe( takeUntil( this.ngUnsubscribe ) ).subscribe( ( user ) => {
        this.currentUser = user;
        this.doNotCharge = user.billingFlag === BillingOption.DoNotCharge;
        for ( const productDataWrapper of this.productDatakeyWrappers ) {
          this.populateIsCocodeAddedToList( productDataWrapper );
          this.populateIsAddToCocodeAvailable( productDataWrapper );
        }
      } );

      this.insdataCustomerService.getCurrentUserRole().pipe( takeUntil( this.ngUnsubscribe ) ).subscribe( ( data ) => {
        this.userRole = data;
      } );
    }
  }

  /**
   * Fetches or shows the ProductDatas given the array of productDataKeys from the ProductDataWrapper
   * If ProductDataWrapper has no productDatas then fetch the ProductDatas for it.
   * @param productDataWrapper
   */
  showProductData( productDataWrapper: ProductDataWrapper ) {
    this.loadingActiveProductDatas = true;
    if ( !productDataWrapper.productDatas ) {
      productDataWrapper.error = undefined;
      this.companySearchService.fetchProductDatasForProductDataKeys( {keys: productDataWrapper.productDataKeys} )
        .pipe( takeUntil( this.ngUnsubscribe ) ).subscribe( ( productDatas: Array<ProductData> ) => {
        productDataWrapper.productDatas = this.sortProductDatas( productDatas );
        const convertSecondsToMilliseconds = 1000;
        _.forEach( productDataWrapper.productDatas, function ( product ) {
          _.forEach( product.statementFiles, function ( file ) {
            file.dateFiled = ( file.dateFiled * convertSecondsToMilliseconds ) + 21600000;
          } );
        } );
        this.loadingActiveProductDatas = false;
      }, ( err ) => {
        productDataWrapper.error = err;
        this.loadingActiveProductDatas = false;
      } );
    } else {
      this.loadingActiveProductDatas = false;
    }
  }


  /**
   * Initializes the productDataWrappers with Annual and Quarterly placeholders for the previous number of Years to display
   */
  initializeProductDataKeys() {
    const currentDate = new Date();
    const currentYear = currentDate.getMonth() >= 2 ? currentDate.getFullYear() : currentDate.getFullYear() - 1;
    for ( let i = 0; i <= this.numberOfYearsToDisplay; i++ ) {
      this.productDatakeyWrappers.push( {
        title: `${String( currentYear - i )} Order Year`,
        productDataKeys: [{
          companyName: this.company.companyName,
          companyCoCode: this.company.companyCoCode,
          isKey: true,
          statementYear: currentYear - i - 1,
          submissionFilingPeriod: 'Annual',
        },                {
          companyName: this.company.companyName,
          companyCoCode: this.company.companyCoCode,
          isKey: false,
          statementYear: currentYear - i - 1,
          submissionFilingPeriod: 'Annual',
        },                {
          companyName: this.company.companyName,
          companyCoCode: this.company.companyCoCode,
          isKey: null,
          statementYear: currentYear - i,
          submissionFilingPeriod: 'Quarter 1',
        },                {
            companyName: this.company.companyName,
            companyCoCode: this.company.companyCoCode,
            isKey: null,
            statementYear: currentYear - i,
            submissionFilingPeriod: 'Quarter 2',
        },                {
            companyName: this.company.companyName,
            companyCoCode: this.company.companyCoCode,
            isKey: null,
            statementYear: currentYear - i,
            submissionFilingPeriod: 'Quarter 3',
          }],
      } );
  }
  }

  goBack() {
    this.close.emit( null );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  sortProductDatas( productDatas: Array<ProductData> ): Array<ProductData> {
    return productDatas.sort( function( a, b ) {
      return ( +b.productDataKey.isKey - +a.productDataKey.isKey )
          || ( a.productDataKey.statementYear - b.productDataKey.statementYear )
          || ( a.productDataKey.submissionFilingPeriod.localeCompare( b.productDataKey.submissionFilingPeriod ) );
    } );
  }

  addToCart( productData: ProductData ) {
    // ProductData is in the process of being added to Cart
    this.productsInTransition.push( productData );
    this.showLoadingModal( 'Adding Item to Cart' );
    this.shoppingCartService.addToCart( { productDataKey: productData.productDataKey } ).subscribe( ( cart ) => {
      // ProductData is added
      this.productsInTransition.splice( this.productsInTransition.indexOf( productData ), 1 );
      this.hideLoadingModal();
      if ( cart.limit.current >= cart.limit.max ) {
        this.cartIsFullModal.show();
      }
    }, () => {
      this.cartErrorAction( productData );
    } );
  }

  removeFromCart( productData: ProductData ) {
    // ProductData is in the process of being removed from Cart
    this.productsInTransition.push( productData );
    this.showLoadingModal( 'Removing Item from Cart' );
    this.shoppingCartService.removeProductFromCart( productData ).subscribe( () => {
      // ProductData is removed
      this.productsInTransition.splice( this.productsInTransition.indexOf( productData ), 1 );
      this.hideLoadingModal();
    }, () => {
      this.cartErrorAction( productData );
    } );
  }

  cartErrorAction( productData: ProductData ) {
    this.insdataMessageService.showErrorMessage( 'An Error Occurred.  Please try again later' );
    // Something happened, no longer in the process of being removed
    this.productsInTransition.splice( this.productsInTransition.indexOf( productData ), 1 );
    this.hideLoadingModal();
  }

  hasProductInCart( productData: ProductData ) {
    return this.shoppingCartService.hasProductInCart( productData );
  }

  continueToCheckout() {
    this.router.navigate( ['home/checkout'] );
  }

  doNotChargeDownloadFile( productData: ProductData ) {
    this.insdataMessageService.clearMessage();
    this.productsInTransition.push( productData );
    this.showLoadingModal( 'Downloading File...' );
    this.insdataOrdersService.addOrderByProductDataKey( this.currentUser.userId, productData.productDataKey ).pipe( takeUntil( this.ngUnsubscribe ) ).subscribe( ( orderId ) => {
      this.companySearchService.downloadOrderFiles( this.currentUser.userId, orderId ).pipe( take( 1 ) ).subscribe( ( link ) => {
        const anchor = document.createElement( 'a' );
        anchor.href = link;
        anchor.click();
        this.productsInTransition.splice( this.productsInTransition.indexOf( productData ), 1 );
        this.hideLoadingModal();
      }, () => {
        this.errorDownloadingFile( productData );
      } );
    }, () => {
      this.errorDownloadingFile( productData );
    } );
  }

  errorDownloadingFile( productData: ProductData ) {
    this.insdataMessageService.showErrorMessage( 'An Error Occurred when trying to download your file.', false, 10000 );
    this.productsInTransition.splice( this.productsInTransition.indexOf( productData ), 1 );
    this.hideLoadingModal();
  }

  showLoadingModal( modalText: string ) {
    this.loadingModalText = modalText;
    this.loadingModal.show();
  }

  hideLoadingModal() {
    this.loadingModal.hide();
  }

  isCartLimitMet(): boolean {
    if ( !this.cart ) {
      return false;
    } else {
      return this.cart.limit.current >= this.cart.limit.max;
    }
  }

  populateIsAddToCocodeAvailable( productDataMap: ProductDataWrapper )  {
    if ( this.currentUser.authorities.some( ( a ) => a.authorityCode === 'ROLE_SPECIAL' ) ) {
      productDataMap.shouldShowAddToCocodeButton = true;
    }
    const filingYear = +productDataMap.title.substring( 0, 4 );
     this.insdataCustomerService.getCurrentUserDetail().pipe( take( 1 ) ).subscribe( ( currentUserDetail ) => {
       const currentUserContract = currentUserDetail.contracts.find( ( c ) => c.filingYear === filingYear );
       // User has no contract
       if ( !currentUserContract ) {
         productDataMap.isAddToCocodeEnabled = false;
         return;
       }
       productDataMap.isAddToCocodeEnabled = this.company.activeOrderYears && this.company.activeOrderYears.includes( filingYear );
     } );
  }

  populateIsCocodeAddedToList( productDataMap: ProductDataWrapper ) {
    const filingYear = +productDataMap.title.substring( 0, 4 );
    this.insdataOrdersService.fetchCocodeList( this.currentUser.userId, filingYear ).pipe( take( 1 ) ).subscribe( ( cocodelistDetail ) => {
      if ( cocodelistDetail && cocodelistDetail.cocodeLists ) {
        for ( const cocodeList of cocodelistDetail.cocodeLists ) {
          if ( cocodeList.cocodeSet && cocodeList.cocodeSet.some( ( c ) => c.cocode === this.company.companyCoCode ) && cocodeList.filingYear === filingYear ) {
            productDataMap.isCocodeOnUsersCocodeList = true;
            return;
          }
        }
      }
    } );
  }

  addCocodeToCocodeList( productDataMap: ProductDataWrapper ) {
    this.showLoadingModal( 'Adding Cocode to Cocode List' );
    const filingYear = +productDataMap.title.substring( 0, 4 );
    this.insdataOrdersService.addCocodeToCocodeList( this.currentUser.userId, filingYear, this.company.companyCoCode ).pipe( take( 1 ) ).subscribe( ( cocodeLoadResult ) => {
      this.hideLoadingModal();
      if ( cocodeLoadResult.success ) {
        productDataMap.isCocodeOnUsersCocodeList = true;
        this.insdataMessageService.showInformationalMessage(
          'Successfully added cocode to your cocode list' );
      } else {
        for ( const errorMessage of cocodeLoadResult.errorMessages ) {
          this.insdataMessageService.showErrorMessage( errorMessage );
        }
      }
    }, ( e ) => {
     this.hideLoadingModal();
      for ( const errorMessage of e.error.errorMessages ) {
        this.insdataMessageService.showErrorMessage( errorMessage.replace( 'org.naic.fs.insdata.common.svc.BusinessException:', '' ) );
      }
    } );
  }


}
