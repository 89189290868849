/*!
 * Copyright 2021 National Association of Insurance Commissioners
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe( {
  name: 'trim',
} )
/**
 * Trims ALL the white space.
 *
 * example:
 * {{ "hello world " | trim }}
 * formats to "helloworld"
 */
export class TrimPipe implements PipeTransform {

  transform( value: string, args?: string[] ): any {
    return value.replace( /\s+/g, '' );
  }

}
