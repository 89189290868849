/*!
 * Copyright 2021 National Association of Insurance Commissioners
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PaginationComponent } from '../public/pagination/pagination.component';
import { TrimPipe } from './pipes/trim.pipe';
import { NoPoBoxDirective } from './validators/no-po-box.directive';
import { OneOfDirective } from './validators/one-of.directive';

@NgModule( {
  imports: [ CommonModule ],
  declarations: [
    PaginationComponent,
    TrimPipe,
    NoPoBoxDirective,
    OneOfDirective,
  ],
  exports: [
    CommonModule,
    TrimPipe,
    FormsModule,
    PaginationComponent,
    NoPoBoxDirective,
  ],
} )
export class SharedModule {
}
