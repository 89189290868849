/*!
 * Copyright 2021 National Association of Insurance Commissioners
 */

import {of as observableOf, Observable} from 'rxjs';

import {HttpClient, HttpEvent, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {map, share} from 'rxjs/operators';
import {CompanyService} from '../api/company.service';
import {ProductData} from '../model/common/productData';
import {ProductDataKeys} from '../model/common/productDataKeys';
import * as models from '../model/company/models';
/**
 * Wrapper service class for CompanyService since CompanyService is generated by Swagger.
 * Business Logic should go here.
 */
// Declare var dT_;

@Injectable()
export class InsdataCompanySearchService {
  private statementTypes: models.StatementTypes;
  private states: models.States;
  private fetchingStatementTypeInProgress: Observable<any>;
  private fetchingStatesInProgress: Observable<any>;

  constructor( private readonly companySearchApi: CompanyService,
              protected readonly httpClient: HttpClient ) {
    // If (typeof dT_ !== 'undefined' && dT_.initAngularNg) {
    //   DT_.initAngularNg(httpClient, HttpHeaders);
    // }
  }

  public searchNaicCompaniesStatements( searchCriteria: models.CompaniesStatementsSearchCriteria,
                                       extraHttpRequestParams?: any ): Observable<models.CompaniesStatementsSearchResults> {
    return this.companySearchApi.searchNaicCompaniesStatements( searchCriteria, extraHttpRequestParams );
  }
  public downloadOrderFiles( userId: string, orderId: string, observe?: 'body', reportProgress?: boolean ): Observable<string>;
  public downloadOrderFiles( userId: string, orderId: string, observe?: 'response', reportProgress?: boolean ): Observable<HttpResponse<string>>;
  public downloadOrderFiles( userId: string, orderId: string, observe?: 'events', reportProgress?: boolean ): Observable<HttpEvent<string>>;
  public downloadOrderFiles( userId: string, orderId: string, observe: any = 'body', reportProgress = false ): Observable<any> {
    return this.companySearchApi.downloadOrderFiles( userId, orderId, observe, reportProgress );
  }

  public downloadOrderLink( downloadLinkCriteria: models.DownloadLinkCriteria, observe?: 'body', reportProgress?: boolean ): Observable<Array<string>>;
  public downloadOrderLink( downloadLinkCriteria: models.DownloadLinkCriteria, observe: any = 'body', reportProgress = false ): Observable<any> {
    return this.companySearchApi.downloadOrderLink( downloadLinkCriteria, observe, reportProgress );
  }

  public fetchProductDatasForProductDataKeys( productDataKeys: ProductDataKeys, observe?: 'body', reportProgress?: boolean ): Observable<Array<ProductData>>;
  public fetchProductDatasForProductDataKeys( productDataKeys: ProductDataKeys, observe: any = 'body', reportProgress = false ): Observable<any> {
    return this.companySearchApi.fetchProductDatasForProductDataKeys( productDataKeys, observe, reportProgress );
  }

  public fetchStatementTypes( observe?: 'body', reportProgress?: boolean ): Observable<models.StatementTypes>;
  public fetchStatementTypes( observe: any = 'body', reportProgress = false ): Observable<any> {
    if ( this.statementTypes ) { // If cache data is available return that
      return observableOf( this.statementTypes );
    } else if ( this.fetchingStatementTypeInProgress ) { // If the observable call is in progress return that
      return this.fetchingStatementTypeInProgress;
    } else {
      this.fetchingStatementTypeInProgress = this.companySearchApi.fetchStatementTypes( observe, reportProgress ).pipe( map( ( statementTypes ) => {
        this.fetchingStatementTypeInProgress = null; // We're going to cache the data so no need to have observable in progress
        this.statementTypes = statementTypes; // Cache the data
        return this.statementTypes;
      } ) ).pipe( share() ); // Shared so more than one subscriber can get the result
      return this.fetchingStatementTypeInProgress;
    }
  }

  public fetchStates( observe?: 'body', reportProgress?: boolean ): Observable<models.States>;
  public fetchStates( observe?: 'response', reportProgress?: boolean ): Observable<HttpResponse<models.States>>;
  public fetchStates( observe?: 'events', reportProgress?: boolean ): Observable<HttpEvent<models.States>>;
  public fetchStates( observe: any = 'body', reportProgress = false ): Observable<any> {
    if ( this.states ) { // If cache data is available return that
      return observableOf( this.states );
    } else if ( this.fetchingStatesInProgress ) { // If the observable call is in progress return that
      return this.fetchingStatesInProgress;
    } else {
      this.fetchingStatesInProgress = this.companySearchApi.fetchStates( observe, reportProgress ).pipe( map( ( states ) => {
        this.fetchingStatesInProgress = null;  // We're going to cache the data so no need to have observable in progress
        this.states = states;  // Cache the data
        return this.states;
      } ) ).pipe( share() );  // Shared so more than one subscriber can get the result
      return this.fetchingStatesInProgress;
    }
  }

  public fetchOrderYears( observe?: 'body', reportProgress?: boolean ): Observable<models.OrderYears>;
  public fetchOrderYears( observe?: 'response', reportProgress?: boolean ): Observable<HttpResponse<models.OrderYears>>;
  public fetchOrderYears( observe?: 'events', reportProgress?: boolean ): Observable<HttpEvent<models.OrderYears>>;
  public fetchOrderYears( observe: any = 'body', reportProgress = false ): Observable<any> {
    return this.companySearchApi.fetchOrderYears( observe, reportProgress );
  }
}
