/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 * !
 * Copyright 2021 National Association of Insurance Commissioners
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes,extraRequire}
 * tslint:disable
 */
var styles = [".ttip[_ngcontent-%COMP%]{position:relative;display:inline-block}.ttip[_ngcontent-%COMP%]   .ttiptext[_ngcontent-%COMP%]{visibility:hidden;white-space:nowrap;background-color:#000;color:#fff;text-align:center;border-radius:5px;padding:5px;position:absolute;z-index:1;top:-5px;left:105%}.ttip[_ngcontent-%COMP%]:hover   .ttiptext[_ngcontent-%COMP%]{visibility:visible}.erroneous[_ngcontent-%COMP%]{background-color:#fcc!important}.card-title#cocodeUploadInfoHeader[_ngcontent-%COMP%]{margin-bottom:.25rem}.card-body#cocodeUploadInfoBody[_ngcontent-%COMP%]{padding-bottom:0;padding-top:.75rem}p#cocodeUploadDtInfo[_ngcontent-%COMP%]{margin-bottom:.5rem}"];
export { styles as styles };

