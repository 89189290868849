/**
 * NAIC InsData Company Service API
 * NAIC InsData Company Service API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: fstier2@naic.internal
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Text Search Flag
 */
export type TextSearchFlag = 'exactMatch' | 'startsWith' | 'contains' | 'endsWith';

export const TextSearchFlag = {
    ExactMatch: 'exactMatch' as TextSearchFlag,
    StartsWith: 'startsWith' as TextSearchFlag,
    Contains: 'contains' as TextSearchFlag,
    EndsWith: 'endsWith' as TextSearchFlag
};
