/*!
 * Copyright 2021 National Association of Insurance Commissioners
 */

import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component( {
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
} )
export class LandingComponent implements OnInit {

  constructor( private readonly router: Router ) {
  }

  ngOnInit() {
    this.router.navigate( ['/home/companySearch'] );
  }
}
