/**
 * NAIC InsData Order Service
 * NAIC InsData Order service
 *
 * OpenAPI spec version: 1.0.0
 * Contact: InsDataStaff@naic.org
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * CoCode List History.
 */
export interface CoCodeListHistory { 
    /**
     * Action taken.
     */
    action: CoCodeListHistory.ActionEnum;
    /**
     * Number of CoCodes affected.
     */
    numCoCodesAffected: number;
    /**
     * Date/time action was taken.
     */
    actionDateTime: Date;
    /**
     * Id of user who took action.
     */
    userId: string;
}
export namespace CoCodeListHistory {
    export type ActionEnum = 'Add' | 'Delete';
    export const ActionEnum = {
        Add: 'Add' as ActionEnum,
        Delete: 'Delete' as ActionEnum
    };
}
