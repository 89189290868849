/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 * !
 * Copyright 2021 National Association of Insurance Commissioners
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes,extraRequire}
 * tslint:disable
 */
var styles = [".checkout-summary[_ngcontent-%COMP%]{font-size:1rem}.padded[_ngcontent-%COMP%]{padding:5px}base[_ngcontent-%COMP%]{color:#303238;fontSize:\"16px\";fontFamily:'\"Open Sans\", sans-serif';fontSmoothing:\"antialiased\"}#card-error[_ngcontent-%COMP%], invalid[_ngcontent-%COMP%]{color:#e5424d}#card-error[_ngcontent-%COMP%]{font-size:1rem}"];
export { styles as styles };

