<div class="mt-1 mr-3 ml-3">
  <div *ngIf="insdataMessage"
       [ngClass]="{
		'alert': insdataMessage,
		'alert-success': insdataMessage.type === 'info',
		'alert-warning': insdataMessage.type === 'showWarningMessage',
		'alert-danger': insdataMessage.type === 'error'
	}" role="alert">
    {{insdataMessage.text}}

    <button type="button" class="close float-right" (click)="clearMessage()" style="cursor: pointer">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>
