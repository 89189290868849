<!-- Company Search -->
<div id="company-search-page" class="row">
  <section class="col-md-4 company-search-form py-2">
    <h3 id="companySearchHeader">Company Search</h3>
    <form (submit)="searchCompanies()" (keydown)="formOnKeyDownFunction($event)">
      <div>
        <label for="companyName" class="company-search-label">Company Name</label>
        <div class="row form-group d-sm-flex">
          <div class="col">
            <input type="text" required class="form-control input-sm company-search-label" id="companyName"
                   name="companyName"
                   [(ngModel)]="companySearchCriteria.companyName">
          </div>
        </div>
      </div>
      <div>
        <label for="companyCode" class="company-search-label">Company Code</label>
        <input type="hidden" [(ngModel)]="companySearchCriteria.textSearchFlag" name="searchOptionRadio"
               value="contains">
        <div class="row form-group d-sm-flex">
          <div class="col">
            <input type="text"
                   min="0"
                   maxlength="5"
                   required
                   (keypress)="onlyNumbersAllowed($event)"
                   class="form-control input-sm no-spin"
                   id="companyCode"
                   [(ngModel)]="companySearchCriteria.companyCoCode"
                   name="companyCode" #companyCode="ngModel">
          </div>
        </div>
      </div>
        <div id="statementTypes" *ngIf="allStatementTypes">
          <label class="company-search-label">Statement Type</label>

          <div class="form-check">
            <div class="row">
              <div class="col-sm-1"></div>
              <div class="col">
                <input type="radio"
                       class="form-check-input"
                       id="ALL"
                       name="statementType"
                       [value]="undefined"
                       [(ngModel)]="companySearchCriteria.statementType"/>
                <label for="ALL" class="form-check-label pl-1">All</label>
              </div>
            </div>
          </div>
        </div>

          <div class="form-check"  *ngFor="let statementType of allStatementTypes?.statementTypes">
            <div class="row">
              <div class="col-sm-1"></div>
              <div class="col">
                <input type="radio"
                       class="form-check-input"
                       id="{{statementType.value}}"
                       name="statementType"
                       [value]="statementType"
                       [(ngModel)]="companySearchCriteria.statementType"/>
                <label for="{{statementType.value}}" class="form-check-label pl-1">{{statementType.value}}</label>
              </div>
            </div>
          </div>

        <div id="stateOfDomicile" *ngIf="allStates">
          <label class="company-search-label">State of Domicile</label>
          <div class="row form-group d-sm-flex">
            <div class="col">
              <select class="form-control" [(ngModel)]="companySearchCriteria.stateOfDomicile" name="selectedState">
                <option [ngValue]="undefined">All States</option>
                <option *ngFor="let state of allStates?.states" value="{{state.code}}">{{state.description}}</option>
              </select>
            </div>
          </div>
        </div>
        <div id="licenseState" *ngIf="allStates">
          <div>
            <label class="company-search-label">Licensed in State</label>
            <div class="row form-group d-sm-flex">
              <div class="col">
                <select class="form-control" [(ngModel)]="companySearchCriteria.licensedInState"
                        name="selectedLicensedState">
                  <option [ngValue]="undefined">All States</option>
                  <option *ngFor="let state of allStates?.states" value="{{state.code}}">{{state.description}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      <div class="row">
        <div class="col text-center">
          <input type="submit" value="Search" class="btn btn-primary mr-2" id="tst-company-search-save-btn"
                 [disabled]="!formIsValid()">
          <input type="button" value="Reset" class="btn btn-secondary" (click)="resetForm()"
                 id="tst-company-search-reset-btn">
        </div>
      </div>
    </form>
  </section>
  <div class="col-md mt-2 mt-md-0">
    <div *ngIf="!showingResultsOrDetails()">
      <p *ngIf="loading">Loading Results... <span class="fa fa-spinner fa-spin fa-lg"></span></p>
      <div *ngIf="!loading && !(companiesStatementsResults || []).length">
        <div *ngIf="hasSearched" class="alert alert-warning">
          Your search yielded no results.
        </div>
        <p *ngIf="!hasSearched">Enter at least one criterion to begin your search.</p>
      </div>
    </div>
    <ng-template appCompanySearchResultHost></ng-template>
  </div>
</div>
