<!--
  ~ Copyright 2024 National Association of Insurance Commissioners
  -->

<ncui-header
  [hideAppHeader]="true"
  [appTitle]="header.appTitle"
  [appSubtitle]="header.appSubtitle"
  [appLogo]="header.appLogo"
  [loggedInUser]="isAuthenticated && firstName ? {name: firstName} : null"
  [dropdownMenuItems]="getLoginSignUpOptions()"
  [realm]="realm || 'prod'"
  [helpModalHeaderText]="header.helpModalHeaderText"
  [helpModalContentHtml]="header.helpModalContentHtml"
  [aboutModalComponentListItems]="header.aboutModalComponentListItems"
  (logout)="logout()"
  (dropdownMenuItemClick)="clickLoginOption()"></ncui-header>

<!-- InsData NAVIGATION -->
<nav class="navbar navbar-expand-md navbar-light bg-faded second-bar">
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navRoutingLinks"
          aria-controls="navRoutingLinks" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="navbar-brand">
    <img class="brandmark" src="assets/images/insdata-logo-white-sm.png" alt="InsData Logo">
  </div>
  <div class="collapse navbar-collapse" *ngIf="!isAuthenticated || isOnAgreement()">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a class="nav-link">&nbsp;</a>
      </li>
    </ul>
  </div>
  <div class="collapse navbar-collapse" id="navRoutingLinks" *ngIf="isAuthenticated && !isOnAgreement()">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['companySearch']" id="company-search-link">Company Search</a>
      </li>
      <li *ngIf="publicationsActive" class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['publications']" id="publications-link">Publications</a>
      </li>
      <li class="nav-item" routerLinkActive="active" *ngIf="cocodeGuard.canActivate(null, null)">
        <a class="nav-link" [routerLink]="['cocodeUpload']" id="cocode-upload-link">Cocode List Manager</a>
      </li>
      <li class="nav-item" routerLinkActive="active" *ngIf="bulkOrderManagerGuard.canActivate(null, null)">
        <a class="nav-link" [routerLink]="['bulkOrderManager']" id="bulk-order-manager-link">Bulk Order
          Manager</a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['viewOrders']" id="view-orders-link">Orders</a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['account']" id="account-link">Account</a>
      </li>
    </ul>
    <ul class="navbar-nav"
        *ngIf="!isCurrentUserDoNotCharge && (userRole !== 'ROLE_SPECIAL' && userRole !== 'ROLE_ALL_ACCESS')">
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['shoppingCart']" id="shopping-cart" title="View Cart"><span
          class="fa fa-shopping-cart"></span>&nbsp;&nbsp;Cart
          ({{ cart && cart.items ? cart.items.length : 0 }})</a>
      </li>
    </ul>
  </div>
</nav>
