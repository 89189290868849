/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 * !
 * Copyright 2021 National Association of Insurance Commissioners
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes,extraRequire}
 * tslint:disable
 */
var styles = [".borderless[_ngcontent-%COMP%]   th[_ngcontent-%COMP%], tr[_ngcontent-%COMP%]{border:none}.cart-header[_ngcontent-%COMP%]{padding-top:.5em}table[_ngcontent-%COMP%]{font-size:1rem}.price-width[_ngcontent-%COMP%]{width:8em}"];
export { styles as styles };

