<div class="row justify-content-center mb-3">
  <div class="col-xl-8 col-lg-10 col-md-10 col-sm-12">
    <div id="orders-home-page" *ngIf="!shouldShowDownloadPage">
      <h2>Orders</h2>
      <div class="alert alert-info">{{helpText}}</div>
      <div class="tabs mb-3">
        <ul class="nav nav-tabs">
          <li class="nav-item" (click)="fetchAndDisplayBulkOrders()"
              *ngIf="(userRole === 'ROLE_SPECIAL'|| userRole === 'ROLE_ALL_ACCESS')"
              id="bulkOrdersTab">
            <a [class]="'nav-link' + ((showTab === 'BULK')? ' active' : '')">Bulk Orders</a>
          </li>
          <li class="nav-item" (click)="fetchAndDisplayCartOrders()" id="cartOrdersTab">
            <a [class]="'nav-link' + ((showTab === 'CART')? ' active' : '')">Cart Orders</a>
          </li>
        </ul>
      </div>
      <div class="row">
        <div class="col-12">
          <div *ngIf="loadingOrders">
            <p>Loading Orders... <span class="fa fa-spinner fa-spin fa-lg"></span></p>
          </div>
          <div *ngIf="showTab === 'CART'">
            <div *ngIf="!orders.length && !loadingOrders">
              <span>You have no completed orders.</span>
            </div>
            <div *ngIf="orders.length && !loadingOrders">
              <div class="card">
                <div class="card-header">
                  <h4>Your Orders</h4>
                </div>
                <div class="card-body">
                  <p class="page-indicator float-right font-weight-bold" id="tst-cart-order-page-indicator">
                    Results {{calcStartNumber()}} - {{calcEndNumber()}} of {{totalResult}}</p>
                  <table class="table" aria-describedby="order details table">
                    <thead class="thead-light">
                    <tr>
                      <th *ngFor="let header of orderHeaders" [class.text-right]="header.code === 'totalUsd'" scope="col">
                        <a (click)="sort(header.code)" class="clickable">
                          {{header.display}}
                          <span *ngIf="sortFields[0] === header.code"
                                [class]="'fa fa-caret-' + (sortOrder === 'asc' ? 'up' : 'down')"></span>
                        </a>
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let row of getCurrentRows()">
                      <td>{{row.orderId | slice:-truncatedUuidLength}}</td>
                      <td>{{row.purchaseDate | date:'MM/dd/yyyy'}}</td>
                      <td>{{row.transactionId ? row.transactionId : 'N/A'}}</td>
                      <td
                        [ngClass]="{'processingError': !row.orderStatus || row.orderStatus === entityStatus.Inactive}"
                        *ngIf="row.orderStatus !== entityStatus.Active">{{getOrderStatusLabel(row.orderStatus)}}</td>
                      <td *ngIf="row.orderStatus === entityStatus.Active"><a class="text-primary"
                                                                             title="Download .zip file"
                                                                             (click)="downloadZip(row.orderId)">Download
                        Order</a></td>
                      <td class="text-right">{{row.totalUsd | currency:'USD':true:'1.2-2'}}</td>
                      <td><a class="text-primary" (click)="viewOrderDetails(row)">View Detail</a>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <div class="text-center">
                    <pagination class="pagination-wrapper" id="tst-cart-order-pagination"
                                [totalPages]="calcTotalPages()"
                                [currentPage]="currentPage"
                                [pageLinks]="buildPageLinks()"
                                (onPaginate)="navigateFromPagination($event)"></pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="showTab === 'BULK' && (userRole === 'ROLE_SPECIAL'|| userRole === 'ROLE_ALL_ACCESS')">
            <div *ngIf="!orders.length && !loadingOrders">
              <span>You have no bulk orders yet.</span>
            </div>
            <div *ngIf="orders.length && !loadingOrders && showTab === 'BULK'">
              <div class="card">
                <div class="card-header">
                  <h4 *ngIf="showTab === 'BULK'">Your Bulk Orders</h4>
                </div>
                <div class="card-body">
                  <p class="page-indicator float-right font-weight-bold" id="tst-company-search-page-indicator">
                    Results {{calcStartNumber()}} - {{calcEndNumber()}} of {{totalResult}}</p>
                  <table class="table" aria-describedby="Bulk Orders Table">
                    <thead class="thead-light">
                    <tr scope="col">
                      <th *ngFor="let header of bulkOrderHeaders" scope="col">
                        {{header.display}}
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let row of getCurrentRows()">
                      <td>{{row.orderId | slice:-truncatedUuidLength}}</td>
                      <td>{{row.purchaseDate | date:'MM/dd/yyyy'}}</td>
                      <td
                        [ngClass]="{'processingError': !row.orderStatus || row.orderStatus === entityStatus.Inactive}"
                        *ngIf="row.orderStatus !== entityStatus.Active">{{getOrderStatusLabel(row.orderStatus)}}</td>
                      <td *ngIf="row.orderStatus === entityStatus.Active">
                        <button type="button" class="btn btn-outline-primary"
                                (click)="goToDownloadPage(row.orderId)">Go To Download Page
                        </button>
                      </td>
                      <td *ngIf="row.orderStatus === entityStatus.Cancelled">{{getOrderStatusLabel(row.orderStatus)}}</td>
                      <td><span *ngIf="row.orderStatus === entityStatus.Active">
                      <a class="text-primary" (click)="downloadManifestFile(row)"><span class="fa fa-download"></span> View Detail</a></span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <div class="text-center">
                    <pagination class="pagination-wrapper" id="tst-cs-pagination"
                                [totalPages]="calcTotalPages()"
                                [currentPage]="currentPage"
                                [pageLinks]="buildPageLinks()"
                                (onPaginate)="navigateFromPagination($event)"></pagination>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div id="download-page" *ngIf="shouldShowDownloadPage">
        <ol class="breadcrumb" style="background-color: white; padding: 0.75rem 0rem">
          <li class="breadcrumb-item"><a (click)="goBackToMainOrdersPage()" style="color:#2c6ba0"
                                         id="bc_link_cocodeListManager">Orders</a></li>
          <li class="breadcrumb-item active" aria-current="page" >Download Page</li>
        </ol>

      <h2><strong>{{selectedBulkOrderId | slice:-truncatedUuidLength}} Order Download Files</strong></h2>
      <div class="alert alert-warning" role="alert">{{downloadPageMessage}}</div>
      <h3>{{selectedBulkOrderLinks.length}} <span *ngIf="selectedBulkOrderLinks.length > 1">Files Ready to Download</span> <span *ngIf="selectedBulkOrderLinks.length === 1">File Ready to Download</span></h3>
      <div *ngFor="let link of selectedBulkOrderLinks; let i = index" [attr.data-index]="i">
       <a [class.file-link]="!hasBeenVisited(i)" [class.visited-file-link]="hasBeenVisited(i)" href="javascript:void(0)" (click)="downloadBulkOrder(i)"> <span class="fa fa-download"></span> {{getFileName(link)}}</a>
      </div>
    </div>

    <div bsModal #loadingOrderDetailModal="bs-modal" class="modal" id="loadingOrderDetailModal" tabindex="-1"
         role="dialog" [config]="{backdrop: 'static'}">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"><strong>Loading Order Details</strong></h5>
          </div>
          <div class="modal-body">
            <span class="fa fa-spinner fa-spin fa-2x"></span>
          </div>
        </div>
      </div>
    </div>
    <div bsModal #loadingDownloadPage="bs-modal" class="modal" id="loadingDownloadPage" tabindex="-1"
         role="dialog" [config]="{backdrop: 'static'}">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"><strong>Loading Download Page</strong></h5>
          </div>
          <div class="modal-body">
            <span class="fa fa-spinner fa-spin fa-2x"></span>
          </div>
        </div>
      </div>
    </div>
    <div bsModal #errorOccurredModal="bs-modal" class="modal" id="errorOccuredModal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"><strong>An Error Occurred</strong></h5>
            <button type="button" class="close pull-right" aria-label="Close" (click)="errorOccurredModal.hide()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            An Error Occurred. Please try again later.
          </div>
        </div>
      </div>
    </div>
    <div bsModal #viewDetailsModal="bs-modal" class="modal" id="viewDetailsModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"><strong>Order Details</strong></h5>
            <button type="button" class="close" (click)="viewDetailsModal.hide()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" *ngIf="activeOrderDetail">
            <div class="row">
              <div class="col"><label>Order Number</label>
                {{activeOrderDetail.orderData.orderId | slice:-truncatedUuidLength}}
              </div>

              <div class="col">
                <label>Transaction #</label>
                {{activeOrderDetail.orderData.transactionId || 'N/A'}}
              </div>
            </div>
            <div class="row">
              <div class="col">
                <label>Order Date</label>
                {{activeOrderDetail.orderData.purchaseDate | date:'MM/dd/yyyy'}}
              </div>
              <div class="col">
                <label>Card Number</label>
                ****{{activeOrderDetail.orderData.lastFourCcDigits}}
              </div>
            </div>
            <div class="row">
              <div class="col">
                <label># of Products</label>
                {{activeOrderDetail.orderItemDetails.length}}
              </div>
              <div class="col">
                <label>Order Total</label>
                {{activeOrderDetail.orderData.totalUsd | currency:'USD':true:'1.2-2'}}
              </div>
            </div>

            <div id="accordion2" role="tablist" class="order-detail-accordion"
                 *ngIf="activeOrderItemDetails && activeOrderItemDetails.length">
              <div class="card">
                <div class="card-header card-header-clickable card-header-nobgcolor" role="tab" data-toggle="collapse">
                  <h5 class="mb-0">
                    <div
                      class="btn btn-focus-off w-100"
                      aria-expanded="false">
                      <div class="row">
                        <div class="col-sm">Company</div>
                        <div class="col-sm">Co Code</div>
                        <div class="col-sm">Year</div>
                        <div class="col-sm">Product</div>
                        <div class="col-sm">Price</div>
                        <div class="col-sm text-sm-right"></div>
                      </div>
                    </div>
                  </h5>
                </div>
              </div>
            </div>

            <div id="accordion" role="tablist" class="order-detail-accordion"
                 *ngIf="activeOrderItemDetails && activeOrderItemDetails.length">
              <div class="card" *ngFor="let activeOrderItemDetail of activeOrderItemDetails">
                <div class="card-header card-header-clickable" role="tab"
                     [id]="activeOrderItemDetail.orderItem.orderItemId" data-toggle="collapse"
                     [attr.data-target]="'#collapse' + activeOrderItemDetail.orderItem.orderItemId">
                  <h5 class="mb-0">
                    <a data-toggle="collapse"
                       class="collapsed btn w-100"
                       href="#collapse{{activeOrderItemDetail.orderItem.orderItemId}}"
                       aria-expanded="false"
                       attr.aria-controls="collapse{{activeOrderItemDetail.orderItem.orderItemId}}">
                      <div class="row">
                        <div class="col-sm"
                             [title]="activeOrderItemDetail.orderItem.productDataKey.companyName || 'None'">{{activeOrderItemDetail.orderItem.productDataKey.companyName
                        || 'None'}}</div>
                        <div class="col-sm"
                             [title]="activeOrderItemDetail.orderItem.productDataKey.companyCoCode">{{activeOrderItemDetail.orderItem.productDataKey.companyCoCode}}</div>
                        <div class="col-sm"
                             [title]="activeOrderItemDetail.orderItem.productDataKey.statementYear">{{activeOrderItemDetail.orderItem.productDataKey.statementYear}}</div>
                        <div class="col-sm"
                             [title]="getFormattedStatementFilingPeriod(activeOrderItemDetail.orderItem.productDataKey)">{{getFormattedStatementFilingPeriod(activeOrderItemDetail.orderItem.productDataKey)}}</div>
                        <div class="col-sm"
                             [title]="activeOrderItemDetail.orderItem.priceUsd | currency:'USD':true:'1.2-2'">{{activeOrderItemDetail.orderItem.priceUsd
                          | currency:'USD':true:'1.2-2'}}</div>
                        <div class="col-sm text-sm-right"><span class="fa fa-caret-up fa-lg"></span><span
                          class="fa fa-caret-down fa-lg" title="See More"></span></div>
                      </div>
                    </a>
                  </h5>
                </div>
                <div [id]="'collapse' + activeOrderItemDetail.orderItem.orderItemId" class="collapse" role="tabpanel"
                     attr.aria-labelledby="{activeOrderItemDetail.orderItem.orderItemId}}">
                  <div class="card-body">
                    <div class="card">
                      <div class="card-body">
                        <div class="row">
                          <table class="table table-striped table-shrinkable" aria-describedby="active order item detail table">
                            <thead>
                            <tr>
                              <th scope="col">File Name</th>
                              <th scope="col">Statement Type</th>
                              <th scope="col">Version</th>
                              <th scope="col">Date Filed</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let statementFile of activeOrderItemDetail.statementFiles">
                              <td data-responsive-label="File Name">{{statementFile.submissionFilingType}}</td>
                              <td
                                data-responsive-label="Statement Type">{{statementFile.statementType | titlecase}}</td>
                              <td data-responsive-label="Version">{{statementFile.submissionVersionType}}</td>
                              <td
                                data-responsive-label="Date Filed">{{(statementFile.dateFiled * 1000) + 21600000| date:'MM/dd/yyyy'}}</td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer"></div>
        </div>
      </div>
    </div>
  </div>

</div>
