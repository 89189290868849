/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 * !
 * Copyright 2021 National Association of Insurance Commissioners
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes,extraRequire}
 * tslint:disable
 */
var styles = [".account-form[_ngcontent-%COMP%]{background:#ccc}"];
export { styles as styles };

