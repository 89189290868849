/*
 * Copyright 2024 National Association of Insurance Commissioners
 */

import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

@Injectable( {
  providedIn: 'root',
} )
export class DisabledRouteGuard implements CanActivate {
  canActivate(): boolean {
    return false;
  }
}

