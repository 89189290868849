<ul class="pagination" *ngIf="totalPages > 1">
	<li *ngIf="currentPage > 1" class="page-item">
		<a (click)="paginate(currentPage - 1)" [id]="'tst-pagination-' + id + '-prev-link'" class="page-link"
       [class.disabled]="!canPaginate(currentPage - 1)" aria-label="Previous">
      <span aria-hidden="true"><span class="fa fa-chevron-left"></span> Previous</span>
    </a>
	</li>
	<li *ngFor="let pageLink of pageLinks; let i = index"
		[class.active]="pageLink[1].page === currentPage" class="page-item">
		<a (click)="paginate(pageLink[1].page)" class="page-link" [id]="'tst-pagination-' + id + '-page-' + i + '-btn'">
      {{pageLink[1].page}}
    </a>
  </li>
  <li *ngIf="currentPage < totalPages" class="page-item">
    <a (click)="paginate(currentPage + 1)" [id]="'tst-pagination-' + id + '-next-link'" class="page-link"
       [class.disabled]="!canPaginate(currentPage + 1)" aria-label="Next">
      <span aria-hidden="true">Next <span class="fa fa-chevron-right"></span></span>
    </a>
  </li>
</ul>
<p class="page-indicator" *ngIf="totalPages" [id]="'tst-pagination-' + id + '-page-indicator'">
  <strong>Page {{currentPage}} of {{totalPages}}</strong></p>
