/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 * !
 * Copyright 2021 National Association of Insurance Commissioners
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes,extraRequire}
 * tslint:disable
 */
var styles = [".colon-after[_ngcontent-%COMP%]:after{content:\":\"}#wrapper[_ngcontent-%COMP%]{margin-top:10px;margin-bottom:10px}.full-page[_ngcontent-%COMP%]{height:100%;position:absolute;width:100%;display:flex;flex-direction:column;justify-content:space-between;overflow-y:auto}@media (-ms-high-contrast:none),screen and (-ms-high-contrast:active){.full-page[_ngcontent-%COMP%]{display:block}}@media (min-width:1400px){#main.container[_ngcontent-%COMP%]{max-width:1340px}}@media (min-width:1600px){#main.container[_ngcontent-%COMP%]{max-width:none}}.my-card[_ngcontent-%COMP%]{padding:10px;position:relative;flex-direction:column;min-width:0;word-wrap:break-word;background-color:#fff;background-clip:border-box;border:1px solid rgba(0,0,0,.125);border-radius:1rem}.my-card[_ngcontent-%COMP%], .my-center[_ngcontent-%COMP%]{margin-left:auto;margin-right:auto}.mntNoticeDiv[_ngcontent-%COMP%], .my-center[_ngcontent-%COMP%]{text-align:center}.mntNoticeDiv[_ngcontent-%COMP%]{border-top:1px solid #ccc;border-bottom:1px solid #ccc;padding-top:10px;padding-bottom:10px;justify-content:center;background:linear-gradient(#d0f4f5,#fff,#d0f4f5);max-width:100%;margin-top:auto;margin-bottom:auto}.units[_ngcontent-%COMP%] > [_ngcontent-%COMP%]:last-child{transform:rotatex(180deg) translatey(-10px);-webkit-mask-image:linear-gradient(transparent 40%,#fff 90%);mask-image:linear-gradient(transparent 40%,#fff 90%)}.units[_ngcontent-%COMP%]{width:-moz-max-content;width:max-content;user-select:none;-moz-user-select:none;-webkit-user-select:none;pointer-events:none;padding:0 10px}.goldBack[_ngcontent-%COMP%]{background:radial-gradient(ellipse farthest-corner at right bottom,#ffeb8b 0,#fff 8%,#e8b441 30%,#d9b575 40%,transparent 80%),radial-gradient(ellipse farthest-corner at left top,#fff 0,#ffffac 8%,#fffcf5 25%,#ffeeb8 62.5%,#5d4a1f 100%)}"];
export { styles as styles };

