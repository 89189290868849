/**
 * NAIC InsData Order Service
 * NAIC InsData Order service
 *
 * OpenAPI spec version: 1.0.0
 * Contact: InsDataStaff@naic.org
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Bulk Order Type.
 */
export type BulkOrderType = 'allFiles' | 'changesSinceLast' | 'dateRange';

export const BulkOrderType = {
    AllFiles: 'allFiles' as BulkOrderType,
    ChangesSinceLast: 'changesSinceLast' as BulkOrderType,
    DateRange: 'dateRange' as BulkOrderType
};
