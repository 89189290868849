/*!
 * Copyright 2021 National Association of Insurance Commissioners
 */

import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';

@Injectable()
export class RealmService {

  getRealm(): string {
    switch ( environment.envName ) {
      case 'ag':
        return ' Localhost AG';
      case 'local': // Fall through
      case 'localhost':
        return ' Localhost';
      case 'dev1':
        return ' Dev 1';
      case 'dev2':
        return ' Dev 2';
      case 'dev': // Fall through
        return 'dev';
      case 'dvlp':
        return ' Dvlp';
      case 'int':
        return 'int';
      case 'prod2':
        return ' Prod 2';
      case 'beta':
        return 'beta';
      case 'prod': // Fall through
      case 'production': // Fall through
      default:
        return 'prod';
    }
  }
}
