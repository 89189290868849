/*!
 * Copyright 2021 National Association of Insurance Commissioners
 */


import {from as observableFrom, Observable} from 'rxjs';

import {HttpClient, HttpEvent, HttpResponse} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {mergeMap} from 'rxjs/operators';
import {OrderService} from '../api/order.service';
import {ProductDataKey} from '../model/common/productDataKey';
import {UserDetail} from '../model/customer/userDetail';
import {CoCodeList} from '../model/order/coCodeList';
import {CoCodeLists} from '../model/order/coCodeLists';
import {CoCodeListsDetail} from '../model/order/coCodeListsDetail';
import {CocodeDownloadCount} from '../model/order/cocodeDownloadCount';
import {CocodeLoadOrRemoveResult} from '../model/order/cocodeLoadOrRemoveResult';
import {CocodeLoadResult} from '../model/order/cocodeLoadResult';
import {OrderDetail} from '../model/order/orderDetail';
import {OrderInput} from '../model/order/orderInput';
import {OrdersSearchResult} from '../model/order/ordersSearchResult';
import {InsdataCustomerService} from './insdata-customer.service';
import {OidcAuthService} from './oidc-auth.service';

// Declare var dT_;

@Injectable()
export class InsdataOrdersService {
  private userId: any;

  constructor( private readonly orderService: OrderService,
              private readonly oidcAuthService: OidcAuthService,
              private readonly insdataCustomerService: InsdataCustomerService,
              protected readonly httpClient: HttpClient ) {
    // If (typeof dT_ !== 'undefined' && dT_.initAngularNg) {
    //   DT_.initAngularNg(httpClient, HttpHeaders);
    // }
  }

  public addOrder( orderInput: OrderInput ): Observable<string> {
    return observableFrom( this.getUserId() ).pipe( mergeMap( ( userId ) => {
      return this.orderService.addOrder( userId, orderInput );
    } ) );
  }

  public fetchOrderDetail( orderId: string ): Observable<OrderDetail> {
    return observableFrom( this.getUserId() ).pipe( mergeMap( ( userId ) => {
      return this.orderService.fetchOrderDetail( userId, orderId );
    } ) );
  }

  public fetchOrders( orderType, start = null, limit = null ): Observable<OrdersSearchResult> {
    return observableFrom( this.getUserId() ).pipe( mergeMap( ( userId ) => {
      return this.orderService.fetchOrders( userId, orderType, start, limit );
    } ) );
  }

  /**
   * Get an array of product keys for an order
   *
   * @param userId The user UUID for the user who purchased the order.
   * @param orderId The order UUID for the order that needs to be fetched.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public fetchOrderProductKeys( userId: string, orderId: string, observe?: 'body', reportProgress?: boolean ): Observable<Array<ProductDataKey>>;
  public fetchOrderProductKeys( userId: string, orderId: string, observe?: 'response', reportProgress?: boolean ): Observable<HttpResponse<Array<ProductDataKey>>>;
  public fetchOrderProductKeys( userId: string, orderId: string, observe?: 'events', reportProgress?: boolean ): Observable<HttpEvent<Array<ProductDataKey>>>;
  public fetchOrderProductKeys( userId: string, orderId: string, observe: any = 'body', reportProgress = false ): Observable<any> {
    return this.orderService.fetchOrderProductKeys( userId, orderId, observe, reportProgress );
  }

  /**
   * Add an order for a user
   *
   * @param userId User UUID for the order
   * @param body ProductDataKey
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addOrderByProductDataKey( userId: string, body: ProductDataKey, observe?: 'body', reportProgress?: boolean ): Observable<string>;
  public addOrderByProductDataKey( userId: string, body: ProductDataKey, observe?: 'response', reportProgress?: boolean ): Observable<HttpResponse<string>>;
  public addOrderByProductDataKey( userId: string, body: ProductDataKey, observe?: 'events', reportProgress?: boolean ): Observable<HttpEvent<string>>;
  public addOrderByProductDataKey( userId: string, body: ProductDataKey, observe: any = 'body', reportProgress = false ): Observable<any> {
    return this.orderService.addOrderByProductDataKey( userId, body, observe, reportProgress );
  }

  /**
   * Create a user&#39;s cocode list from a file.
   *
   * @param userId The user UUID for the special user.
   * @param filingYear Filing Year.
   * @param uploadCsvfile The file to upload.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createCocodeList( userId: string, filingYear: number, uploadCsvfile: Blob, observe?: 'body', reportProgress?: boolean ): Observable<CoCodeList>;
  public createCocodeList( userId: string, filingYear: number, uploadCsvfile: Blob, observe?: 'response', reportProgress?: boolean ): Observable<HttpResponse<CoCodeList>>;
  public createCocodeList( userId: string, filingYear: number, uploadCsvfile: Blob, observe?: 'events', reportProgress?: boolean ): Observable<HttpEvent<CoCodeList>>;
  public createCocodeList( userId: string, filingYear: number, uploadCsvfile: Blob, observe: any = 'body', reportProgress = false ): Observable<any> {
    return this.orderService.createCocodeList( userId, filingYear, uploadCsvfile, observe, reportProgress );
  }


  /**
   * Fetch user&#39;s cocode lists
   *
   * @param userId The user UUID for the special user.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public fetchCocodeLists( userId: string, observe?: 'body', reportProgress?: boolean ): Observable<CoCodeLists>;
  public fetchCocodeLists( userId: string, observe?: 'response', reportProgress?: boolean ): Observable<HttpResponse<CoCodeLists>>;
  public fetchCocodeLists( userId: string, observe?: 'events', reportProgress?: boolean ): Observable<HttpEvent<CoCodeLists>>;
  public fetchCocodeLists( userId: string, observe: any = 'body', reportProgress = false ): Observable<any> {
    return this.orderService.fetchCocodeLists( userId, observe, reportProgress );
  }

  /**
   * Fetch a user&#39;s cocode list
   *
   * @param userId The user UUID for the user.
   * @param filingYear Filing Year.
   * @param itemStatusFilter cocode status
   * @param start start item
   * @param limit item limit
   * @param histStart history start item
   * @param histLimit history item limit
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public fetchCocodeList( userId: string, filingYear: number, itemStatusFilter?: 'none' | 'pending' | 'available' | 'nofiling' | 'locked',
                          start?: number, limit?: number, histStart?: number, histLimit?: number,
                          observe?: 'body', reportProgress?: boolean ): Observable<CoCodeListsDetail>;
  public fetchCocodeList( userId: string, filingYear: number, itemStatusFilter?: 'none' | 'pending' | 'available' | 'nofiling' | 'locked',
                          start?: number, limit?: number, histStart?: number, histLimit?: number,
                          observe?: 'response', reportProgress?: boolean ): Observable<HttpResponse<CoCodeListsDetail>>;
  public fetchCocodeList( userId: string, filingYear: number, itemStatusFilter?: 'none' | 'pending' | 'available' | 'nofiling' | 'locked',
                          start?: number, limit?: number, histStart?: number, histLimit?: number,
                          observe?: 'events', reportProgress?: boolean ): Observable<HttpEvent<CoCodeListsDetail>>;
  public fetchCocodeList( userId: string, filingYear: number, itemStatusFilter?: 'none' | 'pending' | 'available' | 'nofiling' | 'locked',
                          start?: number, limit?: number, histStart?: number, histLimit?: number,
                          observe: any = 'body', reportProgress = false ): Observable<any> {
    return this.orderService.fetchCocodeList( userId, filingYear, itemStatusFilter, start, limit, histStart, histLimit, observe, reportProgress );
  }
  /**
   * Download a user&#39;s cocode list file link (Admin and Special User Only)
   *
   * @param userId The user UUID for the user.
   * @param orderYear Order Year.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public downloadCocodeListExport( userId: string, orderYear: number, observe?: 'body', reportProgress?: boolean ): Observable<string>;
  public downloadCocodeListExport( userId: string, orderYear: number, observe?: 'response', reportProgress?: boolean ): Observable<HttpResponse<string>>;
  public downloadCocodeListExport( userId: string, orderYear: number, observe?: 'events', reportProgress?: boolean ): Observable<HttpEvent<string>>;
  public downloadCocodeListExport( userId: string, orderYear: number, observe: any = 'body', reportProgress = false ): Observable<any> {
    return this.orderService.downloadCocodeListExport( userId, orderYear, observe, reportProgress );
  }

  /**
   * user&#39;s cocode download list from a filingYear.
   *
   * @param userId The user UUID for the user who purchased the order.
   * @param filingYear Filing Year.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public fetchOrdersDownloadCount( userId: string, filingYear: number, observe?: 'body', reportProgress?: boolean ): Observable<CocodeDownloadCount>;
  public fetchOrdersDownloadCount( userId: string, filingYear: number, observe?: 'response', reportProgress?: boolean ): Observable<HttpResponse<CocodeDownloadCount>>;
  public fetchOrdersDownloadCount( userId: string, filingYear: number, observe?: 'events', reportProgress?: boolean ): Observable<HttpEvent<CocodeDownloadCount>>;
  public fetchOrdersDownloadCount( userId: string, filingYear: number, observe: any = 'body', reportProgress = false ): Observable<any> {
    return this.orderService.fetchOrdersDownloadCount( userId, filingYear, observe, reportProgress );
  }


  /**
   * Delete or purge a cocode list for specified year and user.
   *
   * @param userId The user UUID for the user.
   * @param filingYear Filing Year.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteCocodeList( userId: string, filingYear: number, observe?: 'body', reportProgress?: boolean ): Observable<number>;
  public deleteCocodeList( userId: string, filingYear: number, observe?: 'response', reportProgress?: boolean ): Observable<HttpResponse<number>>;
  public deleteCocodeList( userId: string, filingYear: number, observe?: 'events', reportProgress?: boolean ): Observable<HttpEvent<number>>;
  public deleteCocodeList( userId: string, filingYear: number, observe: any = 'body', reportProgress = false ): Observable<any> {
    return this.orderService.deleteCocodeList( userId, filingYear, observe, reportProgress );
  }

  /**
   * Adds cocodes to a User&#39;s cocode list based off of statement type
   *
   * @param userId The user UUID for the special user.
   * @param filingYear Filing Year.
   * @param statementType The statement type of cocodes to be added to the User&#39;s cocode list
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createCocodeListByStatementType( userId: string, filingYear: number, statementType: string, observe?: 'body', reportProgress?: boolean ): Observable<boolean>;
  public createCocodeListByStatementType( userId: string, filingYear: number, statementType: string, observe: any = 'body', reportProgress = false ): Observable<any> {
    return this.orderService.createCocodeListByStatementType( userId, filingYear, statementType, observe, reportProgress );
  }

  /**
   * Add a cocode to a User&#39;s cocode list
   *
   * @param userId The user UUID for the special user.
   * @param filingYear Filing Year.
   * @param cocode NAIC Company CoCode
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addCocodeToCocodeList( userId: string, filingYear: number, cocode: number, observe?: 'body', reportProgress?: boolean ): Observable<CocodeLoadResult>;
  public addCocodeToCocodeList( userId: string, filingYear: number, cocode: number, observe?: 'response', reportProgress?: boolean ): Observable<HttpResponse<CocodeLoadResult>>;
  public addCocodeToCocodeList( userId: string, filingYear: number, cocode: number, observe?: 'events', reportProgress?: boolean ): Observable<HttpEvent<CocodeLoadResult>>;
  public addCocodeToCocodeList( userId: string, filingYear: number, cocode: number, observe: any = 'body', reportProgress = false ): Observable<any> {
    return this.orderService.addCocodeToCocodeList( userId, filingYear, cocode, observe, reportProgress );
  }

  public deleteCocode( userId: string, filingYear: number, cocode: number, observe?: 'body', reportProgress?: boolean ): Observable<CocodeLoadOrRemoveResult>;
  public deleteCocode( userId: string, filingYear: number, cocode: number, observe?: 'response', reportProgress?: boolean ): Observable<HttpResponse<CocodeLoadOrRemoveResult>>;
  public deleteCocode( userId: string, filingYear: number, cocode: number, observe?: 'events', reportProgress?: boolean ): Observable<HttpEvent<CocodeLoadOrRemoveResult>>;
  public deleteCocode( userId: string, filingYear: number, cocode: number, observe: any = 'body', reportProgress = false ): Observable<any> {
    return this.orderService.deleteCocode( userId, filingYear, cocode, observe, reportProgress );
  }

  private async getUserId(): Promise<any> {
    if ( !this.userId ) {
      if ( !this.oidcAuthService.userInfo ) {
        await this.oidcAuthService.callUserInfo();
      }
      const userDetail: UserDetail = await this.insdataCustomerService.fetchUserByName( this.oidcAuthService.userInfo.nickname ).toPromise();
      this.userId = userDetail.user.userId;
    }
    return this.userId;
  }

  }
