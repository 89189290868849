/*!
 * Copyright 2021 National Association of Insurance Commissioners
 */
import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';

@Directive( {
  selector: '[appOneOf]',
  providers: [ { provide: NG_VALIDATORS, useExisting: OneOfDirective, multi: true } ],
} )
export class OneOfDirective implements Validator {
  @Input( 'appOneOf' ) oneOf: any[];

  validate( control: AbstractControl ): ValidationErrors | null {
    return this.oneOf ? oneOfValidator( this.oneOf )( control ) : null;
  }
}

export function oneOfValidator( values: any[] ): ValidatorFn {
  return ( control: AbstractControl ): { [ key: string ]: any } | null => {
    const valueInArray = values.includes( control.value );
    return valueInArray ? null : { oneOf: { value: control.value } };
  };
}
