/*!
 * Copyright 2021 National Association of Insurance Commissioners
 */

import {Injectable} from '@angular/core';

@Injectable()
export class InsdataDateConverter {
  private readonly convertSecondsToMilliseconds = 1000;
  private readonly offsetGMTTime = 21600000;

  convert( val: any ): any {
    return ( val * this.convertSecondsToMilliseconds ) + this.offsetGMTTime;
  }
}
