/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 * !
 * Copyright 2021 National Association of Insurance Commissioners
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes,extraRequire}
 * tslint:disable
 */
var styles = ["#scroll-container[_ngcontent-%COMP%]{overflow:hidden}#scroll-text[_ngcontent-%COMP%]{transform:translateX(100%);animation:my-animation 20s linear infinite}@keyframes my-animation{0%{transform:translateX(100%)}to{transform:translateX(-100%)}}"];
export { styles as styles };

