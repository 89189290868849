/*
 * Copyright 2024 National Association of Insurance Commissioners
 */

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  NcuiAboutModalComponentListItem,
  NcuiHeaderDropdownOption
} from 'common-ui-components/src/ncui-interfaces';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
// @ts-ignore
import * as packageDotJson from '../../../../package.json';
import { environment } from '../../../environments/environment';
import { BulkOrderManagerGuard } from '../../guards/bulk-order-manager-guard';
import { CocodeGuard } from '../../guards/cocode-guard';
import { Cart } from '../../model/cart/cart';
import { InsdataCustomerService } from '../../service/insdata-customer.service';
import { InsdataShoppingCartService } from '../../service/insdata-shopping-cart.service';
import { OidcAuthService } from '../../service/oidc-auth.service';
import { RealmService } from '../../service/realm.service';

@Component( {
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: [ './header.component.scss' ],
} )
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild( 'helpModal', { static: true } )
  public helpModal: ModalDirective;
  public cart: Cart;
  isAuthenticated = false;
  firstName: string;
  ncuiAboutModalComponentListItems: Array<NcuiAboutModalComponentListItem> = [ {
    name: 'InsData Industry Application',
    version: packageDotJson.version || 'n/a',
    buildDate: packageDotJson.buildDate || '2021-07-20',
  } ];
  header: any = {
    appTitle: 'InsData User App',
    appSubtitle: 'User App for InsData',
    appLogo: '../../../assets/images/insdata-icon.png',
    helpModalHeaderText: 'NAIC InsData Help Information',
    helpModalContentHtml: `
      <div class="row">
        <div class="col">
          <ul class="ml-0 pl-0 font-large">
            <li><strong>Phone Number:</strong> 816-783-8500</li>
            <li><strong>Email:</strong> <a href="mailto:help@naic.org">help@naic.org</a></li>
            <li><strong>FAQ:</strong> <a href="https://www.naic.org/documents/insdata_home_faq.pdf?67">https://www.naic.org/documents/insdata_home_faq.pdf</a></li>
          </ul>
          <h5 class="font-weight-bold">Can't Add an Item to the Cart?</h5>
          <p>Annual or Quarterly Statements considered confidential, statements \
          that have not been filed or statements where the domiciliary state has requested to be \
          contacted directly cannot be added to the cart. For more information related to \
          unavailable statements, please see the <a href="https://www.naic.org/documents/insdata_home_user_guide.pdf">InsData Users Guide</a>.</p>
          <br/>
          <h5 class="font-weight-bold">Is Your Cart Full?</h5>
          <p>InsData limits the amount of products to be purchased in a single order. \
          If your cart is full, please complete your purchase. You may then add more products to a separate order.</p>
        </div><!-- /.col -->
      </div><!-- /.row -->
    `,
    aboutModalComponentListItems: this.ncuiAboutModalComponentListItems,
  };
  realm: string;
  isCurrentUserDoNotCharge = false;
  userRole: string;
  publicationsActive = false; // Flag for displaying the publications link
  private readonly ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    private readonly oidcAuthService: OidcAuthService,
    private readonly realmService: RealmService,
    private readonly router: Router,
    private readonly shoppingCartService: InsdataShoppingCartService,
    private readonly insdataCustomerService: InsdataCustomerService,
    readonly cocodeGuard: CocodeGuard,
    readonly bulkOrderManagerGuard: BulkOrderManagerGuard
  ) {
  }

  ngOnInit() {
    this.realm = this.realmService.getRealm();
    this.oidcAuthService.isLoggedInSubject.subscribe( ( loggedIn ) => {
      this.isAuthenticated = loggedIn;
      if ( loggedIn ) {
        setTimeout( () => this.shoppingCartService.refreshCart().pipe( takeUntil( this.ngUnsubscribe ) ).subscribe( ( cart ) => this.cart = cart ), 100 );
        this.shoppingCartService.cartChange.pipe( takeUntil( this.ngUnsubscribe ) ).subscribe( ( cart ) => {
          this.cart = cart;
        } );
        this.firstName = this.firstName || 'Account';
        setTimeout( () => this.firstName = this.oidcAuthService.getFirstName(), 100 );
        setTimeout( () => this.insdataCustomerService.isCurrentUserDoNotCharge().pipe( takeUntil( this.ngUnsubscribe ) ).subscribe( ( result ) => {
          this.isCurrentUserDoNotCharge = result;
        } ), 100 );
        setTimeout( () => this.insdataCustomerService.getCurrentUserRole().pipe( takeUntil( this.ngUnsubscribe ) ).subscribe( ( result ) => {
          this.userRole = result;
        } ), 100 );
      }
    } );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  login() {
    this.oidcAuthService.startSigninMainWindow();
  }

  help() {
    this.helpModal.show();
  }

  close() {
    this.helpModal.hide();
  }

  logout(): void {
    this.oidcAuthService.startSignoutMainWindow();
  }

  clickLoginOption(): void {
    this.oidcAuthService.startSigninMainWindow();
  }

  getLoginSignUpOptions(): NcuiHeaderDropdownOption[] {
    return [
      {
        text: 'Login',
        emitFunctionOnClick: true,
      },
      {
        text: 'Sign Up',
        url: environment.userSignUpUrl + environment.redirectBaseUrl,
      },
    ];
  }

  isOnAgreement(): boolean {
    return ( this.router.url === '/home/agreement' );
  }
}
