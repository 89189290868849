<!--
  ~ Copyright 2024 National Association of Insurance Commissioners
  -->

<div class="container">
  <div class="row mb-3">
    <div class="col-12">
      <div class="card border-dark">
        <div class="card-header border-dark">
          <h4><b>Order Summary</b></h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12 align-self-center">
              <div class="row mb-2" id="order-summary-header">
                <div class="col-5 align-self-center">
                  <span><b>Publication</b></span>
                </div>
                <div class="col-2 align-self-center">
                  <span><b>Quantity</b></span>
                </div>
                <div class="col-2 align-self-center text-right">
                  <span><b>Unit Price</b></span>
                </div>
                <div class="col-3 align-self-center text-right">
                  <span><b>Item Price</b></span>
                </div>
              </div>
              <div class="row" id="order-summary-line-items" *ngFor="let lineItem of cart.items">
                <div class="col-5 align-self-center mb-3">
                  <span>{{ lineItem.publication.productName }}</span>
                </div>
                <div class="col-2 align-self-center">
                  <span>{{ lineItem.quantity }}</span>
                </div>
                <div class="col-2 align-self-center text-right">
                  <span>{{ lineItem.publication.price | currency }}</span>
                </div>
                <div class="col-3 align-self-center text-right">
                  <span>{{ lineItem.quantity * lineItem.publication.price | currency }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer border-dark w-100">
          <div class="float-right">
            <span><b>Total Due: {{ totalDue | currency }}</b></span>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-6">
      <div class="card border-dark">
        <div class="card-header border-dark">
          <h4><b>Customer Information</b><sup>(Optional)</sup></h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12 align-self-center">
              <form [formGroup]="customerInformationFormGroup">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group" [ngClass]="{ 'was-validated': isErrorDisplayed(companyName) }">
                      <label><b>Company Name</b></label>
                      <input type="text" class="form-control form-control-sm rounded"
                             formControlName="companyName" id="company-name-input" maxlength="400"
                             [ngClass]="isErrorDisplayed(companyName) ? 'is-invalid' : 'border border-dark'"
                      >
                      <div *ngIf="isErrorDisplayed(companyName)" class="text-danger pull-right">
                        <small *ngIf="companyName.hasError('maxlength')">Too Long</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label><b>Mailing Address</b></label>
                      <div class="row">
                        <div class="col-12">
                          <input type="text" class="form-control form-control-sm rounded"
                                 formControlName="streetAddress" id="street-address-input"
                                 [ngClass]="isErrorDisplayed(streetAddress) ? 'is-invalid' : 'border-dark'"
                          >
                          <small>Street Address (No PO Box)</small>
                          <div *ngIf="isErrorDisplayed(streetAddress)" class="text-danger pull-right">
                            <small *ngIf="streetAddress.hasError('maxlength')">Too Long</small>
                            <small *ngIf="streetAddress.hasError('poBox')">No PO Box</small>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <input type="text" class="form-control form-control-sm rounded"
                                 formControlName="city" id="city-input" maxlength="50"
                                 [ngClass]="isErrorDisplayed(city) ? 'is-invalid' : 'border-dark'"
                          >
                          <small>City</small>
                          <div *ngIf="isErrorDisplayed(city)" class="text-danger pull-right">
                            <small *ngIf="city.hasError('maxlength')">Too Long</small>
                          </div>
                        </div>
                        <div class="col-3">
                          <input type="text" class="form-control form-control-sm rounded"
                                 formControlName="state" id="state-input" [typeahead]="states"
                                 [isAnimated]="true"
                                 [ngClass]="isErrorDisplayed(state) ? 'is-invalid' : 'border-dark'"
                          >
                          <small>State</small>
                          <div *ngIf="isErrorDisplayed(state)" class="text-danger pull-right">
                            <small *ngIf="state.hasError('oneOf')">Invalid State</small>
                          </div>
                        </div>
                        <div class="col-3">
                          <input type="text" class="form-control form-control-sm rounded"
                                 formControlName="postalCode" id="postal-code-input"
                                 [ngClass]="isErrorDisplayed(postalCode) ? 'is-invalid' : 'border-dark'"
                                 [inputMask]="postalCodeInputMask"
                                 placeholder="_____"
                          >
                          <small>Zip Code</small>
                          <div *ngIf="isErrorDisplayed(postalCode)" class="text-danger pull-right">
                            <small>Invalid Zip Code</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label><b>Phone Number</b></label>
                      <input type="text" class="form-control form-control-sm rounded"
                             formControlName="phoneNumber" id="phone-number-input"
                             [inputMask]="phoneInputMask"
                             placeholder="(___) ___-____"
                             [ngClass]="isErrorDisplayed(phoneNumber) ? 'is-invalid' : 'border-dark'"
                      >
                      <div *ngIf="isErrorDisplayed(phoneNumber)" class="text-danger pull-right">
                        <small>Invalid Phone</small>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="col-6 h-100">
      <div class="card border-dark">
        <div class="card-header border-dark">
          <h4><b>Complete Your Order</b></h4>
        </div>
        <div class="card-body border-dark">
          <div id="form-group">
            <div class="form-control">
              <p *ngIf="loading">Loading... <span class="fa fa-spinner fa-spin fa-lg"></span></p>
              <div id="pub-card-element"><!--Stripe.js injects the Card Element--></div>
            </div>
            <br/>
            <p id="card-error" role="alert"></p>
          </div>
        </div>
        <div class="card-footer border-dark w-100">
          <div class="float-right">
            <button type="button" class="btn btn-primary" id="publications-complete-order-button"
                    (click)="completeOrder()"
                    [disabled]="isSubmitDisabled">
              Complete Order
            </button>
            <button type="button" class="btn btn-clear-wrapper" id="publications-cancel-order-button"
                    [routerLink]="['../publicationsCart']">Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
