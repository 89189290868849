/*!
 * Copyright 2021 National Association of Insurance Commissioners
 */

import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {v4 as uuidv4} from 'uuid';
import {OrderYears} from '../../model/company/orderYears';
import {User} from '../../model/customer/user';
import {BulkOrderInput} from '../../model/order/bulkOrderInput';
import {BulkOrderType} from '../../model/order/bulkOrderType';
import {EntityStatus} from '../../model/order/entityStatus';
import {OrderBaseData} from '../../model/order/orderBaseData';
import {OrdersSearchResult} from '../../model/order/ordersSearchResult';
import {InsdataCompanySearchService} from '../../service/insdata-company-search.service';
import {InsdataCustomerService} from '../../service/insdata-customer.service';
import {InsdataMessageService} from '../../service/insdata-message.service';
import {InsdataOrdersService} from '../../service/insdata-orders.service';

export enum Tabs {
  orderType,
  dateRange,
}

@Component( {
  selector: 'app-bulk-order-manager',
  templateUrl: './bulk-order-manager.component.html',
  styleUrls: ['./bulk-order-manager.component.scss'],
} )
export class BulkOrderManagerComponent implements OnInit, OnDestroy {

  get tabs(): typeof Tabs {
    return Tabs;
  }
  @ViewChild( 'bulkOrderProcessingModal', {static: false} ) bulkOrderProcessingModal: ModalDirective;


  bsConfig: Partial<BsDatepickerConfig>;
  isFromDateValid: boolean;
  isToDateValid: boolean;
  minFromDate: Date;
  maxFromDate: Date;
  minToDate: Date;
  maxToDate: Date;
  fromDate: Date;
  toDate: Date;
  currentTab: Tabs = Tabs.orderType;
  selectedYearToCreateBulkOrder: number;
  bulkOrderSubmitted: boolean;
  pendingBulkOrderExists: boolean;
  fetchingBulkOrders = false;
  availableYears: Array<number> = [];
  user: User;
  selectedBulkOrderType: BulkOrderType = 'allFiles';
  bulkOrderInput: BulkOrderInput = {
    filingYear: 0,
    orderType: 'BulkOrderInput',
  };
  // Create a default base order object and modify it accordingly when submitting.
  orderBaseDetails: OrderBaseData = {
    bulkOrderType: this.selectedBulkOrderType,
    filingYear: ( new Date() ).getFullYear(),
    orderType: '',
    outcomeStatus: 'active',
    lastFourCcDigits: '',
    orderId: uuidv4(),
    orderStatus: EntityStatus.Pending,
    purchaseDate: new Date(),
    subTotalUsd: 0.00,
    taxUsd: 0.00,
    totalUsd: 0.00,
    transactionId: '',
    userId: '',   // User ID must be left blank here because it's obtained on initiation of the component.
  };
  allOrders: OrdersSearchResult;

  private readonly successMessage = 'An Order is created, and we will email when the order is ready to be downloaded.';
  private readonly timeoutMessage = 'Something went wrong. Please try again later or contact sbirequests@naic.org.';
  private readonly noOrderYearSelectedMessage = 'Please select an order year to create an orders';
  private readonly userInfoNotAvailableMsg = 'User info is not available yet. Please try in a few seconds.';
  private readonly ngUnsubscribe: Subject<any> = new Subject();


  constructor( private readonly insdataMessageService: InsdataMessageService,
              private readonly insdataOrderService: InsdataOrdersService,
              private readonly insdataCustomerService: InsdataCustomerService,
              private readonly insdataCompanySearchService: InsdataCompanySearchService ) { }

  ngOnInit() {
   this.insdataCustomerService.getCurrentUser().pipe( take( 1 ) ).subscribe( ( user ) => {
     this.user = user;
     this.insdataCompanySearchService.fetchOrderYears().pipe( takeUntil( this.ngUnsubscribe ) ).subscribe( ( data: OrderYears ) => {
       this.availableYears = data.years;
     }, () => {
       console.error( 'Something went wrong fetching order years using fetchOrderYears method' );
     } );

     this.fetchingBulkOrders = true;
     this.insdataOrderService.fetchOrders( 'BULK' ).pipe( takeUntil( this.ngUnsubscribe ) ).subscribe( ( data: OrdersSearchResult ) => {
       this.fetchingBulkOrders = false;
       if ( data && data.orders ) {
         this.allOrders = data;
         for ( const order of data.orders ) {
           if ( order.orderStatus === 'pending' ) {
             this.pendingBulkOrderExists = true;
             break;
           }
         }
       }
     }, () => {
       console.error( 'Something went wrong fetching orders using fetchOrders method' );
     } );
   }, () => {
     this.insdataMessageService.showErrorMessage( 'Unable to load user. For further assistance contact service desk.', false, 10000 );
   } );

    // Set the BsDatePicker color to dark-blue
    this.bsConfig = Object.assign( {}, {containerClass: 'theme-dark-blue', showWeekNumbers: false, dateInputFormat: 'MM/DD/YYYY'} );
  }

  isDisabled(): boolean {
    if ( this.allOrders !== undefined && this.allOrders.orders != null ) {
      for ( const order of this.allOrders.orders ) {
        if ( order.filingYear === this.selectedYearToCreateBulkOrder
          && order.bulkOrderType === BulkOrderType.AllFiles ) {
          return false;
        }
      }
    }
    return true;
  }
  createBulkOrder() {
    if ( !this.selectedYearToCreateBulkOrder ) {
      this.insdataMessageService.showErrorMessage( this.noOrderYearSelectedMessage, false );
    } else if ( this.user == null ) {
      this.insdataMessageService.showErrorMessage( this.userInfoNotAvailableMsg, false, 10000 );
    } else {
      this.bulkOrderProcessingModal.show();
      // Fill out the bulk order details here when creating the bulk order. Year is the one obtained from UI.
      this.bulkOrderInput.filingYear = this.selectedYearToCreateBulkOrder;
      this.orderBaseDetails.userId = this.user.userId;

      // Join both the base order details and bulk order details here.
      const bulkOrderInputBody = {
        ...this.orderBaseDetails,
        ...this.bulkOrderInput,
      };
      bulkOrderInputBody.bulkOrderType = this.selectedBulkOrderType;
      if ( this.currentTab === this.tabs.dateRange ) {
        if ( this.fromDate == null || this.toDate == null ) {
          this.insdataMessageService.showErrorMessage( 'Please select dates', false, 10000 );
          return;
        }
        this.populateSelectedDateRange( bulkOrderInputBody );
        bulkOrderInputBody.bulkOrderType = BulkOrderType.DateRange;
      }
      this.insdataOrderService.addOrder( bulkOrderInputBody as BulkOrderInput ).subscribe( () => {
        this.insdataMessageService.showInformationalMessage( this.successMessage, false );
        this.bulkOrderSubmitted = true;
        this.bulkOrderProcessingModal.hide();
      }, () => {
        this.insdataMessageService.showErrorMessage( this.timeoutMessage, false );
        this.bulkOrderProcessingModal.hide();
      } );
    }
  }


  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  setCurrentTab( tab: Tabs ) {
    this.currentTab = tab;
    this.resetFields();
  }

  updateMinAndMaxDates() {
    if ( this.currentTab === this.tabs.dateRange && this.selectedYearToCreateBulkOrder ) {
      this.minFromDate =  new Date( `03/01/${this.selectedYearToCreateBulkOrder}` );
       const nextYear = +this.selectedYearToCreateBulkOrder + 1;
       const lastFebDay = this.isLeapYear( nextYear ) ? '29' : '28';
      this.maxFromDate = new Date( `02/${lastFebDay}/${nextYear}` );
      if ( this.maxFromDate > new Date() ) {
        this.maxFromDate = new Date();
      }

      this.minToDate = this.fromDate ? this.fromDate : new Date( `03/01/${this.selectedYearToCreateBulkOrder}` );
      this.maxToDate = new Date( `02/${lastFebDay}/${nextYear}` );
      if ( this.maxToDate > new Date() ) {
        this.maxToDate = new Date();
      }
      this.fromDate = null;
      this.toDate = null;
    }
  }

  updateMinMax() {
    if ( this.isFromDateValid ) {
      this.minToDate = this.fromDate;
    }
  }

  validateDate() {
    const today = new Date();
    if ( this.fromDate ) {
      this.isFromDateValid = !( ( this.fromDate < this.minFromDate ) || ( this.fromDate > this.maxFromDate ) || ( this.fromDate > today ) );
      if ( this.toDate ) {
        this.isToDateValid = !( ( this.toDate < this.fromDate ) || ( this.toDate > this.maxToDate ) || ( this.toDate > today ) );
      }

    }
  }


  isLeapYear( year ): boolean {
    return ( ( year % 4 === 0 ) && ( year % 100 !== 0 ) ) || ( year % 400 === 0 );
  }

  isValidForCreateBulkOrder() {
    if ( this.currentTab === this.tabs.orderType ) {
       return !!this.selectedYearToCreateBulkOrder;
    } else if ( this.currentTab === this.tabs.dateRange ) {
      return this.selectedYearToCreateBulkOrder && ( this.fromDate != null && this.isFromDateValid ) && ( this.toDate != null && this.isToDateValid );
    } else {
      return false;
    }
  }


  getDateString( date: Date ) {
    const year = date.getFullYear();
    const month = date.getMonth();
    const dt = date.getDate();
    const calculatedMonth = ( month > 8 ) ? ( month + 1 ) : ( `0${month + 1}` );
    const calculatedDate = ( dt > 9 ) ? ( dt ) : ( `0${dt}` );
    return  `${year}-${calculatedMonth}-${calculatedDate}` ;
  }

  private resetFields() {
    this.selectedYearToCreateBulkOrder = null;
    this.toDate = null;
    this.fromDate = null;
  }

  private populateSelectedDateRange( bulkOrderInputBody ) {
    if ( this.fromDate ) {
      bulkOrderInputBody.startDate = this.getDateString( this.fromDate );
    }

    if ( this.toDate ) {
      bulkOrderInputBody.endDate = this.getDateString( this.toDate );
    }
  }

}
