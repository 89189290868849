/**
 * NAIC InsData Billing Application Service API
 * NAIC InsData Billing Application Service API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: InsDataStaff@naic.org
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {
  HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent
} from '@angular/common/http';
import {CustomHttpUrlEncodingCodec} from '../encoder';

import {Observable} from 'rxjs';

import {BillingRemittance} from '../model/billing/billingRemittance';
import {BillingResponse} from '../model/billing/billingResponse';
import {CartWithTotals} from '../model/billing/cartWithTotals';
import {StripeToken} from '../model/billing/stripeToken';

import {BASE_PATH, COLLECTION_FORMATS} from '../variables';
import {Configuration} from '../configuration';


@Injectable()
export class BillingService {

  protected basePath = 'https://localhost:8449';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }


  /**
   * Delete Stripe token for payment submission.
   * Used to cancel or abandon a payment intent for a user&#39;s cart.
   * @param paymentIntentId The payment Intent ID for the payment intent that needs to be deleted.
   * @param userId The user ID for the cart that needs to be fetched.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deletePaymentIntent(paymentIntentId: string, userId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public deletePaymentIntent(paymentIntentId: string, userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public deletePaymentIntent(paymentIntentId: string, userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public deletePaymentIntent(paymentIntentId: string, userId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (paymentIntentId === null || paymentIntentId === undefined) {
      throw new Error('Required parameter paymentIntentId was null or undefined when calling deletePaymentIntent.');
    }

    if (userId === null || userId === undefined) {
      throw new Error('Required parameter userId was null or undefined when calling deletePaymentIntent.');
    }

    let headers = this.defaultHeaders;

    // authentication (OAuth2) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete<any>(`${this.basePath}/billing/${encodeURIComponent(String(userId))}/paymentIntent/${encodeURIComponent(String(paymentIntentId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get user&#39;s cart order with total cost and tax if applicable.
   * Get user&#39;s cart order with total cost and tax if applicable.
   * @param userId The user ID for the cart that needs to be fetched.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public fetchCartWithTotals(userId: string, observe?: 'body', reportProgress?: boolean): Observable<CartWithTotals>;
  public fetchCartWithTotals(userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CartWithTotals>>;
  public fetchCartWithTotals(userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CartWithTotals>>;
  public fetchCartWithTotals(userId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (userId === null || userId === undefined) {
      throw new Error('Required parameter userId was null or undefined when calling fetchCartWithTotals.');
    }

    let headers = this.defaultHeaders;

    // authentication (OAuth2) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<CartWithTotals>(`${this.basePath}/billing/${encodeURIComponent(String(userId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get Stripe token for payment submission to pay for user&#39;s cart.
   * Used as the nonce for Stripe processing
   * @param userId The user ID for the cart that needs to be fetched.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public generatePaymentIntent(userId: string, observe?: 'body', reportProgress?: boolean): Observable<StripeToken>;
  public generatePaymentIntent(userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StripeToken>>;
  public generatePaymentIntent(userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StripeToken>>;
  public generatePaymentIntent(userId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (userId === null || userId === undefined) {
      throw new Error('Required parameter userId was null or undefined when calling generatePaymentIntent.');
    }

    let headers = this.defaultHeaders;

    // authentication (OAuth2) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];

    return this.httpClient.post<StripeToken>(`${this.basePath}/billing/${encodeURIComponent(String(userId))}/paymentIntent`,
      null,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Pay for cart for userId
   * Pay for the user&#39;s current cart and turn it into an order.
   * @param body Means to pay for order
   * @param userId The user ID for the cart that needs to be fetched.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public payForCart(body: BillingRemittance, userId: string, observe?: 'body', reportProgress?: boolean): Observable<BillingResponse>;
  public payForCart(body: BillingRemittance, userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BillingResponse>>;
  public payForCart(body: BillingRemittance, userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BillingResponse>>;
  public payForCart(body: BillingRemittance, userId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling payForCart.');
    }

    if (userId === null || userId === undefined) {
      throw new Error('Required parameter userId was null or undefined when calling payForCart.');
    }

    let headers = this.defaultHeaders;

    // authentication (OAuth2) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<BillingResponse>(`${this.basePath}/billing/${encodeURIComponent(String(userId))}/payForCart`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
