/*
 * Copyright 2024 National Association of Insurance Commissioners
 */

import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { timer, BehaviorSubject, Subject } from 'rxjs';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { PubOrdersService } from '../../../api/pubOrders.service';
import { User } from '../../../model/customer/user';
import { PackageStatusResult } from '../../../model/publications/packageStatusResult';
import { PublicationOrder } from '../../../model/publications/publicationOrder';
import { PublicationOrderItem } from '../../../model/publications/publicationOrderItem';
import { InsdataCustomerService } from '../../../service/insdata-customer.service';
import { InsdataMessageService } from '../../../service/insdata-message.service';
import { InsdataDateConverter } from '../../../utils/insdata-date-converter';
import { PubsService } from '../pubs.service';

@Component( {
  selector: 'app-purchased',
  templateUrl: './purchased.component.html',
  styleUrls: [ './purchased.component.scss' ],
} )
export class PurchasedComponent implements OnInit, OnDestroy {
  @ViewChild( 'downloadModal' ) public downloadModalTemplate: TemplateRef<any>;
  @ViewChild( 'deactivateModal' ) public deactivateModalTemplate: TemplateRef<any>;
  user: User;
  purchases: PublicationOrder[] = [];
  selectedPurchase: PublicationOrder;
  deactivateModalRef: BsModalRef;
  downloadModalRef: BsModalRef;
  showDownloadModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>( false );
  deactivateSubject: Subject<boolean>;
  stopPolling: Subject<boolean> = new Subject();

  constructor(
    private customerService: InsdataCustomerService,
    private pubOrdersService: PubOrdersService,
    private modalService: BsModalService,
    private messageService: InsdataMessageService,
    private pubsService: PubsService,
    private dateConverter: InsdataDateConverter
  ) {
  }

  calculateOrderTotalQuantity( order: PublicationOrder ): number {
    let totalQuantity = 0;
    order.publicationOrderItems.forEach( ( item: PublicationOrderItem ) => {
      totalQuantity = totalQuantity + item.quantity;
    } );
    return totalQuantity;
  }

  deactivateAction( value: boolean ): void {
    this.deactivateModalRef.hide();
    this.downloadModalRef = undefined;
    if ( value ) {
      this.stopPolling.next();
      this.stopPolling.complete();
      this.showDownloadModal.next( false );
    } else {
      this.showDownloadModal.next( true );
    }
    this.deactivateSubject.next( value );
    this.deactivateSubject.complete();
  }

  downloadFile( link: string, orderId: string ): void {
    const anchor = document.createElement( 'a' );
    anchor.href = link;
    anchor.click();
    this.messageService.clearMessage();
    const increment$ = this.pubOrdersService.incrementDownloadCount( this.user.userId, orderId );
    increment$.toPromise();
    this.stopPolling = new Subject();
  }

  fetchPubOrdersForUser(): void {
    this.pubOrdersService.fetchPubOrdersForUser( this.user.userId ).pipe( take( 1 ) ).subscribe( ( purchases: PublicationOrder[] ) => {
      this.purchases = purchases.map( ( purchase: PublicationOrder ) => {
        return {
          ...purchase,
          purchaseDate: this.dateConverter.convert( purchase.purchaseDate ),
        };
      } );
      this.purchases = _.orderBy( this.purchases, [ 'purchaseDate' ], [ 'desc' ] );
    }, () => {
      this.purchases = [];
      console.error( 'There was a problem retrieving your purchases' );
    } );
  }

  getAddress(): string {
    let addressText = '';
    if ( this.selectedPurchase?.streetAddress?.length > 0 ) {
      addressText = `${ this.selectedPurchase.streetAddress }`;
      if ( this.selectedPurchase.city?.length > 0 || this.selectedPurchase.state?.length > 0 || this.selectedPurchase.postalCode?.length > 0 ) {
        addressText = `${ addressText }<br>`;
      }
    }
    if ( this.selectedPurchase?.city?.length > 0 ) {
      addressText = `${ addressText }${ this.selectedPurchase.city }`;
      if ( this.selectedPurchase.state?.length > 0 || this.selectedPurchase.postalCode?.length > 0 ) {
        addressText = `${ addressText }, `;
      }
    }
    if ( this.selectedPurchase?.state?.length > 0 ) {
      addressText = `${ addressText }${ this.selectedPurchase.state }`;
      if ( this.selectedPurchase.postalCode?.length > 0 ) {
        addressText = `${ addressText } `;
      }
    }
    if ( this.selectedPurchase?.postalCode?.length > 0 ) {
      addressText = `${ addressText }${ this.selectedPurchase.postalCode }`;
    }
    return addressText.length > 0 ? addressText : '';
  }

  getCurrentUser(): void {
    this.customerService
      .getCurrentUser()
      .pipe( take( 1 ) )
      .subscribe(
        ( data ) => {
          this.user = data;
          this.fetchPubOrdersForUser();
        },
        () => {
          console.error( 'Something went wrong fetching user details using "getCurrentUser()" call.' );
        }
      );
  }

  getFormattedPhoneNumber(): string {
    if ( this.selectedPurchase ) {
      const match = this.selectedPurchase.phoneNumber?.toString()?.match( /^(\d{3})(\d{3})(\d{4})$/ );
      if ( match ) {
        return `(${ match[ 1 ] }) ${ match[ 2 ] }-${ match[ 3 ] }`;
      }
    }

    return '';
  }

  initiateDownload( orderId: string ): void {
    this.pubOrdersService.initiatePackagingOfPublicationOrder( this.user.userId, orderId )
      .pipe( take( 1 ) )
      .subscribe( ( data: PackageStatusResult ) => {
        this.initiateDownloadStatusCheck( data.packageStatusId, orderId );
      }, () => {
        this.messageService.showErrorMessage( 'A problem was encountered while trying to initiate the order download.', false, 5000 );
      } );
  }

  initiateDownloadStatusCheck( packageStatusId: string, orderId: string ): void {
    timer( 0, 15000 )
      .pipe(
        switchMap( () => this.pubOrdersService.downloadStatusPublicationOrder( this.user.userId, packageStatusId ) ),
        takeUntil( this.stopPolling )
      )
      .subscribe( ( data: PackageStatusResult ) => {
        if ( data.url === null ) {
          this.showDownloadModal.next( true );
        } else {
          this.stopPolling.next( true );
          this.stopPolling.complete();
          this.showDownloadModal.next( false );
          this.downloadFile( data.url, orderId );
        }
      }, () => {
        this.messageService.showErrorMessage( 'A problem was encountered while getting the order download.', false, 5000 );
      } );
    timer( 120000 ).pipe( takeUntil( this.stopPolling ) ).subscribe( () => {
      this.stopPolling.next( true );
      this.stopPolling.complete();
      this.showDownloadModal.next( false );
      this.messageService.showErrorMessage( 'Your publication archive was unable to be downloaded. Please retry your download.' );
    } );
  }

  isDownloadActive( purchase: PublicationOrder ): boolean {
    return purchase.orderStatus.toLowerCase() === 'active';
  }

  ngOnDestroy(): void {
    this.stopPolling.next( true );
    this.stopPolling.complete();
  }

  ngOnInit(): void {
    this.pubsService.activeComponent = 'purchased';
    this.getCurrentUser();
    this.showDownloadModal.subscribe( ( showModal: boolean ) => {
      if ( showModal && !this.downloadModalRef ) {
        this.downloadModalRef = this.modalService.show( this.downloadModalTemplate, { keyboard: false, ignoreBackdropClick: true, backdrop: 'static' } );
      } else if ( !showModal && this.downloadModalRef ) {
        this.downloadModalRef.hide();
        this.downloadModalRef = undefined;
      }
    } );
  }


}
