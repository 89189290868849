/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 * !
 * Copyright 2021 National Association of Insurance Commissioners
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes,extraRequire}
 * tslint:disable
 */
var styles = ["label[_ngcontent-%COMP%]{text-align:center}#company-search-results[_ngcontent-%COMP%]   table[_ngcontent-%COMP%]   thead[_ngcontent-%COMP%]   th.co-code-cell[_ngcontent-%COMP%]{max-width:80px}#company-search-results[_ngcontent-%COMP%]   table[_ngcontent-%COMP%]   thead[_ngcontent-%COMP%]   th.home-office-cell[_ngcontent-%COMP%]{max-width:70px}.table-striped.table-hover[_ngcontent-%COMP%] > tbody[_ngcontent-%COMP%] > tr[_ngcontent-%COMP%]:hover, .table-striped.table-hover[_ngcontent-%COMP%] > tbody[_ngcontent-%COMP%] > tr[_ngcontent-%COMP%]:hover > td[_ngcontent-%COMP%]{background-color:#d3e6f7}"];
export { styles as styles };

