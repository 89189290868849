<div class="card">
  <div class="card-header">
    <button *ngIf="cart && cart.items && cart.items.length" type="button" class="btn btn-small float-right" style="line-height: 1.33;" data-toggle="modal"
      data-target="#clearCartModal"><span style="font-size:smaller;">Clear Cart</span></button>
    <h5 class="cart-header"><strong>Your Cart</strong></h5>
  </div>
  <div class="card-body">
    <div *ngIf="!(cart && cart.items && cart.items.length)">
      <span>Your Cart is Empty</span>
    </div>

    <table class="table borderless table-striped" *ngIf="cart && cart.items && cart.items.length" aria-describedby="shopping cart table">
      <thead>
      <tr>
        <th scope="col">Year</th>
        <th scope="col">Product</th>
        <th scope="col">Company</th>
        <th scope="col">CoCode</th>
        <th scope="col" class="text-right price-width">Price</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let cartItem of cart.items">
        <td>{{cartItem.product.productDataKey.statementYear}}</td>
        <td *ngIf="cartItem.product.productDataKey.submissionFilingPeriod === 'Annual'">
          {{cartItem.product.productDataKey.isKey ? 'Key' : 'Non-Key'}} {{cartItem.product.productDataKey.submissionFilingPeriod}} Statement Pages
        </td>
        <td *ngIf="cartItem.product.productDataKey.submissionFilingPeriod !== 'Annual'">
          {{cartItem.product.productDataKey.submissionFilingPeriod}} Statement Pages
        </td>
        <td>{{cartItem.product.productDataKey.companyName}}</td>
        <td>{{cartItem.product.productDataKey.companyCoCode}}</td>
        <td class="text-right price-width">{{cartItem.product.priceUsd | currency:'USD':true:'1.2-2'}}</td>
        <td class="text-center"><a (click)="removeFromCart(cartItem)" title="Remove Item from Cart"><span
          class="fa fa-2x fa-trash"></span></a></td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td><label class="float-right font-weight-bold colon-after"><strong>Subtotal ({{cart.items.length}}
          <span [ngPlural]="cart.items.length"><ng-template
            ngPluralCase="=1">item</ng-template><ng-template
            ngPluralCase="other">items</ng-template></span>)</strong></label></td><!-- -->
        <td class="text-right price-width">{{subtotalPrice | currency:'USD':true:'1.2-2'}}</td>
        <td></td>
      </tr>
      </tfoot>
    </table>
  </div>
  <div class="card-footer float-right" *ngIf="cart && cart.items && cart.items.length">
    <button type="button" class="btn btn-primary btn-sm float-right" (click)="checkout()">Checkout</button>
  </div>
</div>

<div class="modal" id="clearCartModal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"><strong>Clear Cart?</strong></h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Are you sure you want to clear your cart?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="clearCart()" data-dismiss="modal">Clear Cart</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>

<div bsModal #orderConfirmationModal="bs-modal" class="modal" id="orderConfirmationModal" tabindex="-1" role="dialog" [config]="{backdrop: 'static'}" (onHide)="continue()">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"><strong>Your order has been completed</strong></h5>
      </div>
      <div class="modal-body" *ngIf="orderConfirmationDetailForDoNotCharge && billingResponseForDoNotCharge">
        <div class="row d-sm-flex" >
          <div class="col"><label>Order Number:</label></div>
          <div class="col">{{orderConfirmationDetailForDoNotCharge.orderData.orderId |  slice:-truncatedOrderIdLength}}</div>
          <div class="w-100"></div>
          <div class="col"><label>Order Date:</label></div>
          <div class="col">{{orderConfirmationDetailForDoNotCharge.orderData.purchaseDate * 1000 | date:'MM/dd/yyyy'}}</div>
          <div class="w-100"></div>
          <div class="col"><label>Number of Items:</label></div>
          <div class="col">{{orderConfirmationDetailForDoNotCharge.orderItemDetails.length}}</div>
          <div class="w-100"></div>
          <div class="col"><label>Order Total:</label></div>
          <div class="col">{{orderConfirmationDetailForDoNotCharge.orderData.totalUsd |  currency:'USD':true:'1.2-2'}}</div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="continue()" data-dismiss="modal">Continue</button>
      </div>
    </div>
  </div>
</div>

<div bsModal #submittingOrderModal="bs-modal" class="modal" id="submittingOrderModal" tabindex="-1" role="dialog" [config]="{backdrop: 'static', keyboard: false}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"><strong>Submitting Order... (Do not Refresh this Page)</strong></h5>
      </div>
      <div class="modal-body">
        <span class="fa fa-spinner fa-spin fa-2x"></span>
      </div>
    </div>
  </div>
</div>


<div bsModal #submittingPaymentErrorModal="bs-modal" class="modal" id="submittingPaymentErrorModal" tabindex="-1" role="dialog" [config]="{backdrop: 'static', keyboard: false}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"><strong>An Error Occurred</strong></h5>
      </div>
      <div class="modal-body">
        An error occurred when submitting order.  Please check the Orders Page for the status of order
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="continue()" data-dismiss="modal">Continue</button>
      </div>
    </div>
  </div>
</div>
