<div class="row mb-4" id="pub-cart-header">
  <div class="col-12">
    <h4>Your Publications Cart</h4>
  </div>
</div>

<div class="row">
  <div class="col-2 align-self-center"><strong>SKU</strong></div>
  <div class="col-4 align-self-center"><strong>Product Name</strong></div>
  <div class="col-1 align-self-center"><strong>Quantity</strong></div>
  <div class="col-1 align-self-center text-right"><strong>Unit Price</strong></div>
  <div class="col-2 align-self-center text-right"><strong>Item Price</strong></div>
  <div class="col-1 align-self-center text-right">
    <button *ngIf="cart && cart.items && cart.items.length > 0" type="button" class="btn btn-clear-wrapper"
            id="removeCartButton" (click)="emptyCart(confirmEmptyCart)">
      <span class="fa fa-trash"></span>
    </button>
  </div>
</div>
<hr class="header-rule">

<div *ngIf="!cart || !cart.items || cart.items.length === 0; else cartItems" class="row mb-3">
  <div class="col-12">
    <h5>Your Publications Cart is Empty</h5>
  </div>
</div>
<ng-template #cartItems>
  <form #cartForm="ngForm">
    <ng-container *ngFor="let cartItem of cart.items; index as i">
      <div class="row mb-3">
        <div class="col-2 align-self-center" id="{{ cartItem.cartItemId | trim }}Sku">
          <span>{{ cartItem.publication.sku }}</span>
        </div>
        <div class="col-4 align-self-center" id="{{ cartItem.cartItemId | trim }}ProductName">
          <span>{{ cartItem.publication.productName }}</span>
        </div>
        <div class="col-1 align-self-center text-center" id="{{ cartItem.cartItemId | trim }}Quantity">
          <input type="text" [(ngModel)]="cartItem.quantity" size="4" maxlength="4"
                 class="form-control form-control-sm border border-dark rounded"
                 required
                 pattern="^[0-9]*$" #quantity="ngModel" id="{{cartItem.cartItemId | trim}}QuantityInput"
                 name="{{cartItem.cartItemId | trim}}QuantityInput"
                 (input)="formEdited = true"
          >
          <small *ngIf="quantity.dirty && quantity.errors">
            <span *ngIf="quantity.errors?.pattern" class="text-danger">Digits Only</span>
            <span *ngIf="quantity.errors?.required" class="text-danger">Required</span>
          </small>
        </div>
        <div class="col-1 align-self-center text-right" id="{{ cartItem.cartItemId | trim }}UnitPrice">
          <span>{{ cartItem.publication.price | currency }}</span>
        </div>
        <div class="col-2 align-self-center text-right" id="{{ cartItem.cartItemId | trim }}Price">
          <span>{{ getItemPrice(cartItem.publication.price, cartItem.quantity) | currency }}</span>
        </div>
        <div class="col-1 align-self-center text-right">
          <button type="button" class="btn btn-clear-wrapper"
                  id="{{cartItem.cartItemId | trim }}RemoveButton"
                  (click)="removeLineItem(cartItem.cartItemId)">
            <span class="fa fa-trash" title="Remove"></span>
          </button>
        </div>
      </div>
      <hr *ngIf="i < cart.items.length - 1" class="line-item-rule">
      <hr *ngIf="i === cart.items.length - 1" class="header-rule">
    </ng-container>
  </form>
  <div class="row mb-3">
    <div class="col-4 offset-2 align-self-center text-right">
      <span><strong>Total:</strong></span>
    </div>
    <div class="col-2 align-self-center">{{ totalQuantity }}</div>
    <div class="col-2 align-self-center text-right">{{ totalPrice | currency }}</div>
    <div class="col-1"></div>
  </div>
  <div class="row">
    <div class="col-12 align-self-center text-right">
      <button type="button" class="btn btn-secondary mr-2"
              id="pubCartUpdateButton"
              (click)="updateCart()"
              [disabled]="!formEdited || cartForm.invalid">
        Update Cart
      </button>
      <button type="button" class="btn btn-primary" id="pubCartCheckoutButton"
              [routerLink]="['../publicationsCheckout']"
              [disabled]="formEdited || cartForm.invalid">Checkout
      </button>
    </div>
  </div>
</ng-template>

<ng-template #confirmEmptyCart>
  <div class="modal-body text-center">
    <p>Are you sure you want to clear your Publications Cart?</p>
    <button type="button" class="btn btn-warning" id="clearCartYesButton" (click)="confirmEmpty()">Yes</button>
    <button type="button" class="btn btn-default" id="clearCartCancelButton" (click)="cancelEmpty()">Cancel</button>
  </div>
</ng-template>
