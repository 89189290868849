/*!
 * Copyright 2021 National Association of Insurance Commissioners
 */


import {from as observableFrom, throwError as observableThrowError, Observable, ReplaySubject} from 'rxjs';

import {HttpClient, HttpHeaders} from '@angular/common/http';
import {EventEmitter, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {User, UserManager} from 'oidc-client';
import {catchError, map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {RequestOptions} from './request-options';

const path = '/oauth/nam';
const settings: any = {
  authority: environment.authUrl + path,
  metadata: {
    issuer: environment.authUrl + path,
    authorization_endpoint: environment.authUrl + path + environment.loginContract,
    end_session_endpoint: `${environment.authUrl}/app/logout`,
    token_endpoint: `${environment.authUrl + path}/token`,
    userinfo_endpoint: `${environment.authUrl + path}/userinfo`,
    jwks_uri: `${environment.authUrl + path}/keys`,
  },
  metadataUrl: `${environment.redirectBaseUrl}/ipd`,
  client_id: environment.clientId,
  redirect_uri: `${environment.redirectBaseUrl}/auth.html`,
  post_logout_redirect_uri: 'http://www.naic.org',
  response_type: 'token',
  scope: 'profile+address+email+phone_number+openid+NAICCommon',
  silent_redirect_uri: `${environment.redirectBaseUrl}/silent-renew.html`,
  automaticSilentRenew: true,
  includeIdTokenInSilentRenew: true,
  accessTokenExpiringNotificationTime: 4,
  silentRequestTimeout: 10000,
  filterProtocolClaims: true,
  loadUserInfo: true,
};

@Injectable()
export class OidcAuthService {
  mgr: UserManager                          = new UserManager( settings );
  userLoadedEvent: EventEmitter<User>       = new EventEmitter<User>();
  isAdminSubject: ReplaySubject<boolean>    = new ReplaySubject( 1 );
  isLoggedInSubject: ReplaySubject<boolean> = new ReplaySubject( 1 );
  loggedIn                                  = false;
  userInfo: UserInfo                        = null;
  currentUser: User;
  authHeaders: HttpHeaders;
  CONTENT_TYPE = 'Content-Type';


  constructor( private readonly http: HttpClient,
              private readonly router: Router ) {
    this.mgr.getUser()
      .then( ( user ) => {
        if ( user ) {
          this.loggedIn = true;
          this.isLoggedInSubject.next( true );
          this.currentUser = user;
          this.userLoadedEvent.emit( user );
          this.callUserInfo().then( ( complete ) => {
            if ( !complete ) {
              this.logout();
            }
          } );
        } else {
          this.loggedIn = false;
          this.isLoggedInSubject.next( false );
        }
      } )
      .catch( ( err ) => {
        this.loggedIn = false;
        this.isLoggedInSubject.next( false );
        this.currentUser = null;
        this.userInfo = null;
      } );

    this.mgr.events.addUserLoaded( ( user ) => {
      this.currentUser = user;
      this.loggedIn    = user !== undefined;
      this.isLoggedInSubject.next( user !== undefined );
      this.userLoadedEvent.emit( user );
    } );

    this.mgr.events.addUserUnloaded( () => {
      this.loggedIn = false;
      this.isLoggedInSubject.next( false );
      this.userLoadedEvent.emit( null );
    } );

    this.mgr.events.addSilentRenewError( () => {
      if ( confirm( 'An issue occurred when trying to renew your session.  Please log in again' ) ) {
        this.startSignoutMainWindow();
      }
    } );
  }

  public getUserId(): string {
    if ( this.userInfo ) {
      return this.userInfo.preferredUsername;
    } else {
      this.callUserInfo().then( ( complete ) => {
        if ( complete ) {
          this.getUserId();
        } else {
          this.logout();
        }
      } );
    }
    return null;
  }

  public getFirstName(): string {
    return this.userInfo ? this.userInfo.givenName : null;
  }

  isLoggedInObs(): Observable<boolean> {
    return observableFrom( this.mgr.getUser() ).pipe( map<User, boolean>( ( user ) => !!user ) );
  }

  clearState() {
    this.mgr.clearStaleState().then( function () {
      // Do nothing
    } ).catch( function ( e ) {
      console.error( 'clearStateState error', e.message );
    } );
  }

  getUser() {
    this.mgr.getUser().then( ( user ) => {
      this.currentUser = user;
      this.userLoadedEvent.emit( user );
    } ).catch( function ( err ) {
      console.error( err );
    } );
  }

  callUserInfo(): Promise<boolean> {
    const options = this._setRequestOptions();
    return new Promise( ( complete ) => {
      this.http.get( `${environment.authUrl + path}/userinfo`, options )
        .pipe( catchError( ( error: any ) => {
          this.logout();
          return observableThrowError( `${error.toString()} => Custom catch: User Info call failed.` );
        } ) )
        .subscribe( ( response ) => {
          if ( response ) {
            this.userInfo = new UserInfo( response );
          }
          if ( this.userInfo !== null ) {
            complete( true );
          } else {
            console.error( 'User Info is unavailable!' );
            complete( false );
          }
        } );
    } );
  }


  removeUser() {
    this.mgr.removeUser().then( () => {
      this.userLoadedEvent.emit( null );
    } ).catch( function ( err ) {
      console.error( err );
    } );
  }

  startSigninMainWindow() {
    this.mgr.signinRedirect( {data: 'some data'} ).then( () => {
      // Do nothing
    } ).catch( ( err ) => console.error( err ) );
  }

  endSigninMainWindow() {
    this.mgr.signinRedirectCallback().then( function ( user ) {
      // Do nothing
    } ).catch( function ( err ) {
      console.error( err );
    } );
  }

  logout() {
    this.mgr.removeUser().then( ( done ) => {
      this.currentUser = null;
      this.userInfo    = null;
      this.loggedIn    = false;
      this.isLoggedInSubject.next( false );
      this.isAdminSubject.next( false );
    } );
  }

  startSignoutMainWindow() {
    sessionStorage.removeItem( 'companySearchCriteria' );
    sessionStorage.removeItem( 'companies' );
    this.mgr.getUser().then( ( user ) => {
      return this.mgr.signoutRedirect( {id_token_hint: user.id_token} ).then( ( resp ) => {
        setTimeout( null, 5000, () => {
          // Do nothing.
        } );
      } ).catch( function ( err ) {
        console.error( err );
      } );
    } );
  }

  public getAccessToken(): string {
      return this.currentUser ? this.currentUser.access_token : '';
    }

  private _setAuthHeaders( user: any ): void {
    this.authHeaders = new HttpHeaders();
    this.authHeaders = this.authHeaders.append( 'Authorization', `Bearer ${user.access_token}` );
    if ( !this.authHeaders.get( this.CONTENT_TYPE ) ) {
      this.authHeaders.append( this.CONTENT_TYPE, 'application/json' );
    }
  }

  private _setRequestOptions( options?: RequestOptions ) {
    if ( this.loggedIn ) {
      this._setAuthHeaders( this.currentUser );
    }
    if ( options ) {
      options.headers.append( this.authHeaders.keys[0], this.authHeaders.get( this.authHeaders.keys[0] ) );
    } else {
      options = new RequestOptions( {headers: this.authHeaders} );
    }

    return options;
  }
  }

  export class UserInfo {
  sub: string;
  website: string;
  nickname: string;
  preferredUsername: string;
  givenName: string;
  middleName: string;
  familyName: string;
  email: string;
  insdataUserId: string;

  constructor( userInfoResponse ) {
    this.sub                = userInfoResponse.sub;
    this.website            = userInfoResponse.website;
    this.nickname           = userInfoResponse.nickname;
    this.preferredUsername = userInfoResponse.preferred_username;
    this.givenName         = userInfoResponse.given_name;
    this.middleName        = userInfoResponse.middle_name;
    this.familyName        = userInfoResponse.family_name;
    this.email              = userInfoResponse.email;

  }
}
