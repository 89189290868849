/*!
 * Copyright 2021 National Association of Insurance Commissioners
 */

import {Injectable} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {Observable, Subject} from 'rxjs';

/**
 * Insdata Message Service
 * Fork from: http://jasonwatmore.com/post/2016/09/29/angular-2-user-registration-and-login-example-tutorial
 */
@Injectable()
export class InsdataMessageService {

  private readonly messageSubject: Subject<any> = new Subject<any>();
  private keepAfterNavigationChange = false;
  private timeOutObj;

  constructor( private readonly router: Router ) {
    // Clear alert message on route change
    router.events.subscribe( ( event ) => {
      if ( event instanceof NavigationStart && !this.keepAfterNavigationChange ) {
          this.messageSubject.next();
      }
    } );
  }

  /**
   * Show an Informational Message
   * @param {string} message
   * @param {boolean} keepAfterNavigationChange - whether to continue to show the message ever after changing pages
   * @param {number} timeout - the time in milisseconds for the message to show
   */
  showInformationalMessage( message: string, keepAfterNavigationChange = false, timeout?: number ) {
    this.showMessage( message, 'info', keepAfterNavigationChange, timeout );
  }

  /**
   * Show a Warning Message
   * @param {string} message
   * @param {boolean} keepAfterNavigationChange - whether to continue to show the message ever after changing pages
   * @param {number} timeout - the time in milisseconds for the message to show
   */
  showWarningMessage( message: string, keepAfterNavigationChange = false, timeout?: number ) {
    this.showMessage( message, 'showWarningMessage', keepAfterNavigationChange, timeout );
  }

  /**
   * Show an Error Message
   * @param {string} message
   * @param {boolean} keepAfterNavigationChange - whether to continue to show the message ever after changing pages
   * @param {number} timeout - the time in milisseconds for the message to show
   */
  showErrorMessage( message: string, keepAfterNavigationChange = false, timeout?: number ) {
    this.showMessage( message, 'error', keepAfterNavigationChange, timeout );
  }

  /**
   * Observable that broadcasts the message
   * @returns {Observable<any>}
   */
  getMessageSubject(): Observable<any> {
    return this.messageSubject.asObservable();
  }

  /**
   * Clear the current message being shown
   */
  clearMessage() {
    this.messageSubject.next();
    this.keepAfterNavigationChange = false;
    window.clearTimeout( this.timeOutObj );
  }

  private showMessage( message: string, messageType: string, keepAfterNavigationChange = false, timeout?: number ) {
    this.messageSubject.next( {text: message, type: messageType} );
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    if ( timeout ) {
      this.timeOutObj = setTimeout( () => {
        this.clearMessage();
      }, timeout );
    }
  }

}
