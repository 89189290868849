/*!
 * Copyright 2021 National Association of Insurance Commissioners
 */

/**
 * Copyright 2017 National Association of Insurance Commissioners
 *
 * Created by tamartin on 5/4/2017.
 */

import { Component } from '@angular/core';

@Component( {
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
} )
export class FooterComponent {
}
