/*
 * Copyright 2024 National Association of Insurance Commissioners
 */

/**
 * NAIC InsData Order Service
 * NAIC InsData Order service
 *
 * OpenAPI spec version: 1.0.0
 * Contact: InsDataStaff@naic.org
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs/Observable';
import { PackageStatusResult } from '../model/publications/packageStatusResult';
import { PublicationOrder } from '../model/publications/publicationOrder';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';


@Injectable( { providedIn: 'root' } )
export class PubOrdersService {

  protected basePath = 'https://localhost:8451';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor( protected httpClient: HttpClient, @Optional() @Inject( BASE_PATH ) basePath: string, @Optional() configuration: Configuration ) {
    if ( basePath ) {
      this.basePath = basePath;
    }
    if ( configuration ) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm( consumes: string[] ): boolean {
    const form = 'multipart/form-data';
    for ( const consume of consumes ) {
      if ( form === consume ) {
        return true;
      }
    }
    return false;
  }


  /**
   * Checks the status of downloading Publication Order
   * Check the status of a packaged Publication Order.  If its ready it&#39;ll download, if not then nothing
   * @param userId The user UUID for the cart that needs to be fetched.
   * @param publicationPackageStatusId The UUID of the Publication Package Status ID
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public downloadStatusPublicationOrder( userId: string, publicationPackageStatusId: string, observe?: 'body', reportProgress?: boolean ): Observable<PackageStatusResult>;
  public downloadStatusPublicationOrder( userId: string, publicationPackageStatusId: string, observe?: 'response', reportProgress?: boolean ): Observable<HttpResponse<PackageStatusResult>>;
  public downloadStatusPublicationOrder( userId: string, publicationPackageStatusId: string, observe?: 'events', reportProgress?: boolean ): Observable<HttpEvent<PackageStatusResult>>;
  public downloadStatusPublicationOrder( userId: string, publicationPackageStatusId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if ( userId === null || userId === undefined ) {
      throw new Error( 'Required parameter userId was null or undefined when calling downloadStatusPublicationOrder.' );
    }

    if ( publicationPackageStatusId === null || publicationPackageStatusId === undefined ) {
      throw new Error( 'Required parameter publicationPackageStatusId was null or undefined when calling downloadStatusPublicationOrder.' );
    }

    let headers = this.defaultHeaders;

    // authentication (OAuth2) required
    if ( this.configuration.accessToken ) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set( 'Authorization', 'Bearer ' + accessToken );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept( httpHeaderAccepts );
    if ( httpHeaderAcceptSelected != undefined ) {
      headers = headers.set( 'Accept', httpHeaderAcceptSelected );
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<PackageStatusResult>( `${ this.basePath }/orders/publications/downloadStatus/${ encodeURIComponent( String( userId ) ) }/${ encodeURIComponent( String( publicationPackageStatusId ) ) }`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get Publication Orders for a User
   * Get Publication Orders for a User
   * @param userId The user UUID for the cart that needs to be fetched.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public fetchPubOrdersForUser( userId: string, observe?: 'body', reportProgress?: boolean ): Observable<Array<PublicationOrder>>;
  public fetchPubOrdersForUser( userId: string, observe?: 'response', reportProgress?: boolean ): Observable<HttpResponse<Array<PublicationOrder>>>;
  public fetchPubOrdersForUser( userId: string, observe?: 'events', reportProgress?: boolean ): Observable<HttpEvent<Array<PublicationOrder>>>;
  public fetchPubOrdersForUser( userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if ( userId === null || userId === undefined ) {
      throw new Error( 'Required parameter userId was null or undefined when calling fetchPubOrdersForUser.' );
    }

    let headers = this.defaultHeaders;

    // authentication (OAuth2) required
    if ( this.configuration.accessToken ) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set( 'Authorization', 'Bearer ' + accessToken );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept( httpHeaderAccepts );
    if ( httpHeaderAcceptSelected != undefined ) {
      headers = headers.set( 'Accept', httpHeaderAcceptSelected );
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<PublicationOrder>>( `${ this.basePath }/orders/publications/publicationOrders/${ encodeURIComponent( String( userId ) ) }`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Increments the download count by 1 of the publication order
   * Increments the download count by 1 of the publication order
   * @param userId The user UUID for the cart that needs to be fetched.
   * @param publicationOrderId The UUID of the publication order id to initiate the packaging
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public incrementDownloadCount( userId: string, publicationOrderId: string, observe?: 'body', reportProgress?: boolean ): Observable<any>;
  public incrementDownloadCount( userId: string, publicationOrderId: string, observe?: 'response', reportProgress?: boolean ): Observable<HttpResponse<any>>;
  public incrementDownloadCount( userId: string, publicationOrderId: string, observe?: 'events', reportProgress?: boolean ): Observable<HttpEvent<any>>;
  public incrementDownloadCount( userId: string, publicationOrderId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if ( userId === null || userId === undefined ) {
      throw new Error( 'Required parameter userId was null or undefined when calling incrementDownloadCount.' );
    }

    if ( publicationOrderId === null || publicationOrderId === undefined ) {
      throw new Error( 'Required parameter publicationOrderId was null or undefined when calling incrementDownloadCount.' );
    }

    let headers = this.defaultHeaders;

    // authentication (OAuth2) required
    if ( this.configuration.accessToken ) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set( 'Authorization', 'Bearer ' + accessToken );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept( httpHeaderAccepts );
    if ( httpHeaderAcceptSelected != undefined ) {
      headers = headers.set( 'Accept', httpHeaderAcceptSelected );
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.patch<any>( `${ this.basePath }/orders/publications/incrementDownloadCount/${ encodeURIComponent( String( userId ) ) }/${ encodeURIComponent( String( publicationOrderId ) ) }`,
      null,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Initiate the packaging of a Publication Order
   * Initiate the packaging of a Publication Order
   * @param userId The user UUID for the cart that needs to be fetched.
   * @param publicationOrderId The UUID of the publication order id to initiate the packaging
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public initiatePackagingOfPublicationOrder( userId: string, publicationOrderId: string, observe?: 'body', reportProgress?: boolean ): Observable<PackageStatusResult>;
  public initiatePackagingOfPublicationOrder( userId: string, publicationOrderId: string, observe?: 'response', reportProgress?: boolean ): Observable<HttpResponse<PackageStatusResult>>;
  public initiatePackagingOfPublicationOrder( userId: string, publicationOrderId: string, observe?: 'events', reportProgress?: boolean ): Observable<HttpEvent<PackageStatusResult>>;
  public initiatePackagingOfPublicationOrder( userId: string, publicationOrderId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if ( userId === null || userId === undefined ) {
      throw new Error( 'Required parameter userId was null or undefined when calling initiatePackagingOfPublicationOrder.' );
    }

    if ( publicationOrderId === null || publicationOrderId === undefined ) {
      throw new Error( 'Required parameter publicationOrderId was null or undefined when calling initiatePackagingOfPublicationOrder.' );
    }

    let headers = this.defaultHeaders;

    // authentication (OAuth2) required
    if ( this.configuration.accessToken ) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set( 'Authorization', 'Bearer ' + accessToken );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept( httpHeaderAccepts );
    if ( httpHeaderAcceptSelected != undefined ) {
      headers = headers.set( 'Accept', httpHeaderAcceptSelected );
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.post<PackageStatusResult>( `${ this.basePath }/orders/publications/initiatePackaging/${ encodeURIComponent( String( userId ) ) }/${ encodeURIComponent( String( publicationOrderId ) ) }`,
      null,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
