/*!
 * Copyright 2021 National Association of Insurance Commissioners
 */


import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {EndUserAgreement} from '../model/customer/endUserAgreement';
import {InsdataCustomerService} from './insdata-customer.service';

@Injectable()
export class InsdataUserAgreementResolver implements Resolve<EndUserAgreement> {

  constructor( private readonly customerService: InsdataCustomerService ) {
  }

  resolve( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Observable<EndUserAgreement> | Promise<EndUserAgreement> | EndUserAgreement {
    return this.customerService.fetchEndUserAgreement().pipe( map( ( endUserAgreement ) => {
      return endUserAgreement;
    } ) );
  }
}
