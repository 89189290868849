/*!
 * Copyright 2021 National Association of Insurance Commissioners
 */

import { APP_BASE_HREF } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InputMaskModule } from '@ngneat/input-mask';

import { NcuiModule } from 'common-ui-components';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { environment } from '../environments/environment';
import { ApiModule } from './api/api.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Configuration } from './configuration';
import { AccountComponent } from './public/account/account.component';
import { AgreementComponent } from './public/agreement/agreement.component';
import { BannerComponent } from './public/banner/banner.component';
import { BulkOrderManagerComponent } from './public/bulk-order-manager/bulk-order-manager.component';
import { CocodeUploadComponent } from './public/cocode-upload/cocode-upload.component';
import { CompanyModule } from './public/company/company.module';
import { FooterComponent } from './public/footer/footer.component';
import { HeaderComponent } from './public/header/header.component';
import { HomeComponent } from './public/home/home.component';
import { InsdataLoadingSpinnerComponent } from './public/insdata-loading-spinner/insdata-loading-spinner.component';
import { InsdataMessageComponent } from './public/insdata-message/insdata-message.component';
import { LandingComponent } from './public/landing/landing.component';
import { LoginComponent } from './public/login/login/login.component';
import { LogoutComponent } from './public/logout/logout/logout.component';
import { CartComponent } from './public/publications/cart/cart.component';
import { PublicationListComponent } from './public/publications/publication-list/publication-list.component';
import { PublicationsCheckoutComponent } from './public/publications/publications-checkout/publications-checkout.component';
import { PublicationsComponent } from './public/publications/publications.component';
import { PurchasedComponent } from './public/publications/purchased/purchased.component';
import { ShoppingCartComponent } from './public/shopping-cart/shopping-cart.component';
import { CheckoutComponent } from './secured/checkout/checkout.component';
import { ViewOrdersComponent } from './secured/view-orders/view-orders.component';
import { EmptyResponseBodyErrorInterceptor } from './service/empty-response-body-error-interceptor';
import { InactivityService } from './service/inactivity.service';
import { InsdataBillingService } from './service/insdata-billing.service';
import { InsdataCustomerService } from './service/insdata-customer.service';
import { InsdataLoadingSpinnerService } from './service/insdata-loading-spinner.service';
import { InsdataMessageService } from './service/insdata-message.service';
import { InsdataOrdersService } from './service/insdata-orders.service';
import { InsdataShoppingCartService } from './service/insdata-shopping-cart.service';
import { OidcAuthService } from './service/oidc-auth.service';
import { RealmService } from './service/realm.service';
import { SharedModule } from './shared/shared.module';
import { InsdataDateConverter } from './utils/insdata-date-converter';

export function apiConfig( oidcAuthService: OidcAuthService ) {
  return new Configuration( {
    basePath: environment.apiUrl,
    accessToken: oidcAuthService.getAccessToken.bind( oidcAuthService ),
  } );
}

@NgModule( {
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonsModule,
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    AccordionModule.forRoot(),
    TypeaheadModule.forRoot(),
    CollapseModule,
    TooltipModule,
    InputMaskModule,
    AppRoutingModule,
    NcuiModule,
    CompanyModule,
    SharedModule,
    ApiModule,
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    LogoutComponent,
    AgreementComponent,
    AccountComponent,
    HeaderComponent,
    FooterComponent,
    LandingComponent,
    ShoppingCartComponent,
    CheckoutComponent,
    ViewOrdersComponent,
    InsdataMessageComponent,
    InsdataLoadingSpinnerComponent,
    CocodeUploadComponent,
    BulkOrderManagerComponent,
    BannerComponent,
    PublicationsComponent,
    PublicationListComponent,
    PurchasedComponent,
    CartComponent,
    PublicationsCheckoutComponent,
  ],
  providers: [
    OidcAuthService,
    RealmService,
    InsdataShoppingCartService,
    InsdataCustomerService,
    InsdataOrdersService,
    InsdataBillingService,
    InsdataDateConverter,
    InsdataMessageService,
    InsdataLoadingSpinnerService,
    InactivityService,
    {
      provide: Configuration,
      useFactory: apiConfig,
      deps: [ OidcAuthService ],
      multi: false,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EmptyResponseBodyErrorInterceptor,
      multi: true,
    },
    { provide: APP_BASE_HREF, useValue: '/' },

  ],
  bootstrap: [ AppComponent ],
} )
export class AppModule {
}
