/*
 *  Copyright 2021 National Association of Insurance Commissioners
 *
 */

/**
 * NAIC InsData Order Service
 * NAIC InsData Order service
 *
 * OpenAPI spec version: 1.0.0
 * Contact: InsDataStaff@naic.org
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { HttpClient, HttpEvent, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { Configuration } from '../configuration';
import { PubCart } from '../model/publications/pubCart';


import { BASE_PATH } from '../variables';


@Injectable( { providedIn: 'root' } )
export class PubCartService {

  protected basePath = 'https://localhost:8451';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor( protected httpClient: HttpClient, @Optional() @Inject( BASE_PATH ) basePath: string, @Optional() configuration: Configuration ) {
    if ( basePath ) {
      this.basePath = basePath;
    }
    if ( configuration ) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm( consumes: string[] ): boolean {
    const form = 'multipart/form-data';
    for ( const consume of consumes ) {
      if ( form === consume ) {
        return true;
      }
    }
    return false;
  }


  /**
   * Save a product to the users cart
   * Save product to the users cart
   * @param userId User UUID for the cart
   * @param publicationId Publication ID to be added to Cart.
   * @param quantity Number of Publications inside the Cart Item
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addPublicationToCart( userId: string, publicationId: string, quantity: number, observe?: 'body', reportProgress?: boolean ): Observable<PubCart>;
  public addPublicationToCart( userId: string, publicationId: string, quantity: number, observe?: 'response', reportProgress?: boolean ): Observable<HttpResponse<PubCart>>;
  public addPublicationToCart( userId: string, publicationId: string, quantity: number, observe?: 'events', reportProgress?: boolean ): Observable<HttpEvent<PubCart>>;
  public addPublicationToCart( userId: string, publicationId: string, quantity: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if ( userId === null || userId === undefined ) {
      throw new Error( 'Required parameter userId was null or undefined when calling addPublicationToCart.' );
    }

    if ( publicationId === null || publicationId === undefined ) {
      throw new Error( 'Required parameter publicationId was null or undefined when calling addPublicationToCart.' );
    }

    if ( quantity === null || quantity === undefined ) {
      throw new Error( 'Required parameter quantity was null or undefined when calling addPublicationToCart.' );
    }

    let headers = this.defaultHeaders;

    // authentication (OAuth2) required
    if ( this.configuration.accessToken ) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set( 'Authorization', 'Bearer ' + accessToken );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept( httpHeaderAccepts );
    if ( httpHeaderAcceptSelected != undefined ) {
      headers = headers.set( 'Accept', httpHeaderAcceptSelected );
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.post<PubCart>( `${ this.basePath }/orders/publications/pubCart/${ encodeURIComponent( String( userId ) ) }/addPub/${ encodeURIComponent( String( publicationId ) ) }/${ encodeURIComponent( String( quantity ) ) }`,
      null,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Empty user&#39;s cart
   * Empty user&#39;s cart
   * @param userId The user UUID for the cart that needs to be emptied.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public emptyPubCartForUser( userId: string, observe?: 'body', reportProgress?: boolean ): Observable<PubCart>;
  public emptyPubCartForUser( userId: string, observe?: 'response', reportProgress?: boolean ): Observable<HttpResponse<PubCart>>;
  public emptyPubCartForUser( userId: string, observe?: 'events', reportProgress?: boolean ): Observable<HttpEvent<PubCart>>;
  public emptyPubCartForUser( userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if ( userId === null || userId === undefined ) {
      throw new Error( 'Required parameter userId was null or undefined when calling emptyPubCartForUser.' );
    }

    let headers = this.defaultHeaders;

    // authentication (OAuth2) required
    if ( this.configuration.accessToken ) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set( 'Authorization', 'Bearer ' + accessToken );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept( httpHeaderAccepts );
    if ( httpHeaderAcceptSelected != undefined ) {
      headers = headers.set( 'Accept', httpHeaderAcceptSelected );
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete<any>( `${ this.basePath }/orders/publications/pubCart/${ encodeURIComponent( String( userId ) ) }`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get cart for userId
   * Get any cart contents for a specific user
   * @param userId The user UUID for the cart that needs to be fetched.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public fetchPubCartForUser( userId: string, observe?: 'body', reportProgress?: boolean ): Observable<PubCart>;
  public fetchPubCartForUser( userId: string, observe?: 'response', reportProgress?: boolean ): Observable<HttpResponse<PubCart>>;
  public fetchPubCartForUser( userId: string, observe?: 'events', reportProgress?: boolean ): Observable<HttpEvent<PubCart>>;
  public fetchPubCartForUser( userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if ( userId === null || userId === undefined ) {
      throw new Error( 'Required parameter userId was null or undefined when calling fetchPubCartForUser.' );
    }

    let headers = this.defaultHeaders;

    // authentication (OAuth2) required
    if ( this.configuration.accessToken ) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set( 'Authorization', 'Bearer ' + accessToken );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept( httpHeaderAccepts );
    if ( httpHeaderAcceptSelected != undefined ) {
      headers = headers.set( 'Accept', httpHeaderAcceptSelected );
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<PubCart>( `${ this.basePath }/orders/publications/pubCart/${ encodeURIComponent( String( userId ) ) }`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Remove an item from the cart
   *
   * @param userId User UUID for user&#39;s cart to remove item from
   * @param cartItemId Item UUID for Cart Item to remove from the Cart
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public removePublicationCartItemFromCart( userId: string, cartItemId: string, observe?: 'body', reportProgress?: boolean ): Observable<PubCart>;
  public removePublicationCartItemFromCart( userId: string, cartItemId: string, observe?: 'response', reportProgress?: boolean ): Observable<HttpResponse<PubCart>>;
  public removePublicationCartItemFromCart( userId: string, cartItemId: string, observe?: 'events', reportProgress?: boolean ): Observable<HttpEvent<PubCart>>;
  public removePublicationCartItemFromCart( userId: string, cartItemId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if ( userId === null || userId === undefined ) {
      throw new Error( 'Required parameter userId was null or undefined when calling removePublicationCartItemFromCart.' );
    }

    if ( cartItemId === null || cartItemId === undefined ) {
      throw new Error( 'Required parameter cartItemId was null or undefined when calling removePublicationCartItemFromCart.' );
    }

    let headers = this.defaultHeaders;

    // authentication (OAuth2) required
    if ( this.configuration.accessToken ) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set( 'Authorization', 'Bearer ' + accessToken );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept( httpHeaderAccepts );
    if ( httpHeaderAcceptSelected != undefined ) {
      headers = headers.set( 'Accept', httpHeaderAcceptSelected );
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete<PubCart>( `${ this.basePath }/orders/publications/pubCart/${ encodeURIComponent( String( userId ) ) }/removePub/${ encodeURIComponent( String( cartItemId ) ) }`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Update user&#39;s cart
   * Update user&#39;s cart
   * @param userId The user UUID for the cart that needs to be emptied.
   * @param body PubCart
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updatePubCartForUser( userId: string, body: PubCart, observe?: 'body', reportProgress?: boolean ): Observable<PubCart>;
  public updatePubCartForUser( userId: string, body: PubCart, observe?: 'response', reportProgress?: boolean ): Observable<HttpResponse<PubCart>>;
  public updatePubCartForUser( userId: string, body: PubCart, observe?: 'events', reportProgress?: boolean ): Observable<HttpEvent<PubCart>>;
  public updatePubCartForUser( userId: string, body: PubCart, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if ( userId === null || userId === undefined ) {
      throw new Error( 'Required parameter userId was null or undefined when calling updatePubCartForUser.' );
    }

    if ( body === null || body === undefined ) {
      throw new Error( 'Required parameter body was null or undefined when calling updatePubCartForUser.' );
    }

    let headers = this.defaultHeaders;

    // authentication (OAuth2) required
    if ( this.configuration.accessToken ) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set( 'Authorization', 'Bearer ' + accessToken );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept( httpHeaderAccepts );
    if ( httpHeaderAcceptSelected != undefined ) {
      headers = headers.set( 'Accept', httpHeaderAcceptSelected );
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType( consumes );
    if ( httpContentTypeSelected != undefined ) {
      headers = headers.set( 'Content-Type', httpContentTypeSelected );
    }

    return this.httpClient.put<PubCart>( `${ this.basePath }/orders/publications/pubCart/${ encodeURIComponent( String( userId ) ) }`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Update an item in the cart
   *
   * @param userId User UUID for user&#39;s cart to remove item from
   * @param cartItemId Item UUID for Cart Item to remove from the Cart
   * @param quantity Number of Publications inside the Cart Item
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updatePublicationCartItemInCart( userId: string, cartItemId: string, quantity: number, observe?: 'body', reportProgress?: boolean ): Observable<PubCart>;
  public updatePublicationCartItemInCart( userId: string, cartItemId: string, quantity: number, observe?: 'response', reportProgress?: boolean ): Observable<HttpResponse<PubCart>>;
  public updatePublicationCartItemInCart( userId: string, cartItemId: string, quantity: number, observe?: 'events', reportProgress?: boolean ): Observable<HttpEvent<PubCart>>;
  public updatePublicationCartItemInCart( userId: string, cartItemId: string, quantity: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if ( userId === null || userId === undefined ) {
      throw new Error( 'Required parameter userId was null or undefined when calling updatePublicationCartItemInCart.' );
    }

    if ( cartItemId === null || cartItemId === undefined ) {
      throw new Error( 'Required parameter cartItemId was null or undefined when calling updatePublicationCartItemInCart.' );
    }

    if ( quantity === null || quantity === undefined ) {
      throw new Error( 'Required parameter quantity was null or undefined when calling updatePublicationCartItemInCart.' );
    }

    let headers = this.defaultHeaders;

    // authentication (OAuth2) required
    if ( this.configuration.accessToken ) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set( 'Authorization', 'Bearer ' + accessToken );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept( httpHeaderAccepts );
    if ( httpHeaderAcceptSelected != undefined ) {
      headers = headers.set( 'Accept', httpHeaderAcceptSelected );
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.put<PubCart>( `${ this.basePath }/orders/publications/pubCart/${ encodeURIComponent( String( userId ) ) }/updatePub/${ encodeURIComponent( String( cartItemId ) ) }/${ encodeURIComponent( String( quantity ) ) }`,
      null,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
